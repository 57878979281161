import _ from "lodash";

export default _.mapKeys([
	"UPDATE_LAYOUT_PROPS",
	"LOGIN_USER",
	"LOGOUT_USER",
	"SET_ERROR",
	"CLEAR_ERROR",
	"SET_JOB_DATA",
	"CLEAR_JOB_DATA",
	"SET_EVENT_DATA",
	"SET_EVENT_BASIC_INFO",
	"SET_EVENT_EVENT_INFO",
	"SET_EVENT_SPEAKER_INFO",
	"SET_EVENT_RESOURCE_INFO",
	"CLEAR_EVENT_DATA"
]);
