import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { routesPath } from "app/components/Router";
import CreateEvent from "./Create";
import ListEvents from "./List";
import EventRegistrations from "./EventRegistrations";
import PostCompletionEdit from "./PostCompletionEdit";

const Events = () => {

	return (
		<Switch>
			<Route
				exact
				path={routesPath.eventList.route}
				component={ListEvents}
			/>
			<Route
				exact
				path={routesPath.eventCreate.route}
				component={CreateEvent}
			/>
			<Route
				exact
				path={routesPath.eventEdit.route}
				component={CreateEvent}
			/>
			<Route
				exact
				path={routesPath.eventCompletionEdit.route}
				component={PostCompletionEdit}
			/>
			<Route
				exact
				path={routesPath.eventRegistrations.route}
				component={EventRegistrations}
			/>
			<Redirect to={routesPath.eventList.route} />
		</Switch>
	);
};

export default Events;

