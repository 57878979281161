import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "app/actions/layout";
import Header from "shared/Header";
import SecondaryHeader from "shared/SecondaryHeader";
import styles from "./index.scss";
// import openNotification from "widgets/Notification";

const Layout = props => {
	const { actions } = props;

	// Work on error messages
	// openNotification('success', {
	// 	message: 'Success',
	// 	description: 'Make changes'
	// });

	// Layout ClassNames
	const _className = styles.rootContainer;
	const _headerClassName = styles.headerClassName;
	const _contentClassName = styles.contentClassName;

	return (
		<div className={_className}>
			<Header className={_headerClassName} actions={actions} />
			<SecondaryHeader active={!!props.secondaryHeader}>
				{props.secondaryHeader}
			</SecondaryHeader>
			<div className={_contentClassName}>{props.children}</div>
		</div>
	);
};

Layout.propTypes = {
	actions: PropTypes.object.isRequired,
	children: PropTypes.node,
	secondaryHeader: PropTypes.node
};

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(
	null,
	mapDispatchToProps
)(Layout);
