import React, { useEffect, useState } from "react";
import Tooltip from "widgets/Tooltip";
import { Link, NavLink } from "react-router-dom";
import cx from "classnames";
import Tag from "shared/Tag";
import Table from "widgets/Table";
import Select from "widgets/Select";
import Input from "widgets/Input";
import styles from "./index.scss";
import { getJobsForReview } from "apis";
import { constants } from "utils";
import Button from "widgets/Button";
import { routesPath } from "app/components/Router";

const STATUS_COLORS = {
	UNDER_REVIEW: "blue",
	REJECTED: "red"
};

const PAGE_SIZE = 20;

const JOB_STATUS = [
	{
		label: "Under review",
		value: "under_review"
	},
	{
		label: "Rejected",
		value: "rejected"
	}
];

const dateUtil = date => {
	const temp = new Date(date).toDateString().split(" ");
	const newDate = `${temp[2]}th ${temp[1]} , ${temp[3]}`;
	return newDate;
};

const withToolTip = string => {
	if (string.length > 16) {
		return (
			<Tooltip placement="topLeft" title={string}>
				{string}
			</Tooltip>
		);
	}
	return string;
};


const JobOpening = () => {
	const [dataSource, setDataSource] = useState([]);

	const [filters, setFilters] = useState({
		cohorts: [],
		jobStatus: "Under review",
		searchText: ""
	});
	const [tableProps, setTableProps] = useState({
		pagination: { total: 0, current: 1, pageSize: PAGE_SIZE },
		loading: true
	});

	useEffect(() => {
		let queryObject = constants.PARSE_QUERY_STRING();
		if (queryObject && queryObject.company) {
			setFilters({
				...filters,
				searchText: queryObject.company,
				jobStatus: queryObject.jobStatus
			});
			fetchJobs({
				pageNo: tableProps.pagination.current,
				searchText: queryObject.company,
				strictMatch: true,
				jobStatus: JOB_STATUS.find(
					o => o.label === queryObject.jobStatus
				).value
			});
			constants.cleanUrl();
		} else {
			fetchJobs({ pageNo: tableProps.pagination.current });
		}
	}, []);

	const fetchJobs = async ({
		pageNo,
		searchText,
		strictMatch,
		jobStatus
	}) => {
		if (document.getElementById("top")) {
			document
				.getElementById("top")
				.scrollIntoView({ behavior: "smooth" });
		}
		try {
			let cohortId = "";
			let currentJobStatus = JOB_STATUS.filter(option => option.label === filters["jobStatus"])[0].value

			const params = {
				pageNo,
				pageSize: tableProps.pagination.pageSize,
				jobStatus: jobStatus ? jobStatus : currentJobStatus,
				cohortId,
				searchText: searchText ? searchText : filters.searchText
			};
			if (strictMatch) params.strictMatch = true;

			let response = await getJobsForReview(params);

			if (response.status == 200) {
				let { jobs } = response.data.result;
				jobs = jobs.map((elem, i) => {
					const serialNum = {
						sno: i + 1 + (pageNo - 1) * PAGE_SIZE
					};
					const uniqueKey = {
						key: elem.id
					};
					return { ...serialNum, ...uniqueKey, ...elem };
				});

				setDataSource(jobs);
				let pagination = { ...tableProps.pagination };
				pagination.total = parseInt(response.headers.count);
				pagination.current = pageNo;
				setTableProps({
					pagination,
					loading: false
				});
			} else {
				// write error toast code for no response from API
			}
		} catch (e) {
			// error
		}
	};

	const handleTableChange = pagination => {
		const pager = { ...tableProps.pagination };
		pager.current = pagination.current;
		setTableProps({
			pagination: pager,
			loading: true
		});
		fetchJobs({ pageNo: pagination.current });
	};

	const handleChange = ({ name, value }) => {
		setFilters({ ...filters, [name]: value });
	};

	const handleApply = () => {
		setTableProps({
			...tableProps,
			loading: true
		});
		fetchJobs({ pageNo: 1 });
	};

	const columns = [
		{
			title: "Sr.No.",
			dataIndex: "sno",
			// eslint-disable-next-line
			render: sno => <span>{sno}</span>
		},
		{
			title: "Company Name",
			dataIndex: "companyName",
			// eslint-disable-next-line
			render: cname => <span>{withToolTip(cname)}</span>
		},
		{
			title: "Job Title",
			dataIndex: "title",
			// eslint-disable-next-line
			render: title => <span>{withToolTip(title)}</span>
		},
		{
			title: "Status",
			dataIndex: "state",
			// eslint-disable-next-line
			render: state => (
				<Tag color={STATUS_COLORS[state]}>{state.split("_").join(" ")}</Tag>
			)
		},
		{
			title: "Location",
			dataIndex: "location",
			className: cx(styles["w-160"]),
			// eslint-disable-next-line
			render: loc => <span>{withToolTip(loc)}</span>
		},
		{
			title: "Due Date",
			dataIndex: "dueDate",
			// eslint-disable-next-line
			render: date => <span>{dateUtil(date)}</span>
		},
		{
			title: "Posted By",
			dataIndex: "postedBy",
			className: cx(styles["w-160"]),
			// eslint-disable-next-line
			render: postedBy => <span>{withToolTip(postedBy)}</span>
		},
		{
			title: "Posting company",
			dataIndex: "clientName",
			// eslint-disable-next-line
			render: clientName => <span>{withToolTip(clientName)}</span>
		},
		{
			title: "Appl. Count",
			dataIndex: "noOfApplications",
			// eslint-disable-next-line
			render: no => <span>{no}</span>
		},
		{
			title: "Action",
			fixed: 'right',
			className: cx({[styles["fixed-column"]]: dataSource.length === 0}),
			// eslint-disable-next-line
			render: record => {
				let linkToRedirect = routesPath.jobReview.route.replace(":jobID", record.key);
					if (record.state === "REJECTED")
						linkToRedirect = routesPath.jobView.route.replace(":jobID", record.key);
					return (
						<span>
							<Link to={linkToRedirect}>
								<button className={styles.actionBtn}>
									<div className="btnText" />
								</button>
							</Link>
						</span>
					)
			}
		}
	];
	const renderJobOpenings = () => {
		return (
			<>
				<div className={styles.filtersRow}>
					<div className={styles.col25}>
						<Select
							mode="default"
							name="jobStatus"
							label="status"
							value={filters.jobStatus}
							onChange={handleChange}
							defaultValue={JOB_STATUS[1].label}
							options={JOB_STATUS}
						/>
					</div>
					<div className={styles.flexGrowDiv}>
						<Input
							type="text"
							name="searchText"
							label="Search by"
							placeholder="Company name, job title or location"
							labelClassName={styles.labelOnlyAdditionalProps}
							inputClassName={styles.searchInput}
							value={filters.searchText}
							onChange={handleChange}
						/>
					</div>
					<div className={styles.applyButtonDiv}>
						<Button
							className={styles.whiteButton}
							onClick={handleApply}
						>
							Apply filters
						</Button>
					</div>
				</div>
				<div className={styles.tableContainer}>
					<Table
						className={styles.jobOpeningsTable}
						columns={columns}
						dataSource={dataSource}
						pagination={tableProps.pagination}
						loading={tableProps.loading}
						onChange={handleTableChange}
						scroll={{ x: true }}
					/>
				</div>
			</>
		);
	};

	return (
		<>
			<div id="top" className={styles.secondaryHeaderOuter}>
				<div className={styles.secondaryHeaderInner}>
					<div className={styles.navList}>
						<NavLink
							exact
							to={routesPath.jobOpenings.route}
							className={styles.navItem}
							activeClassName={styles.active}
						>
							All jobs
						</NavLink>
						<NavLink
							exact
							to={routesPath.jobOpeningsReview.route}
							className={styles.navItem}
							activeClassName={styles.active}
						>
							Jobs for review
						</NavLink>
					</div>
				</div>
			</div>
			<div className={styles.bodyOuter}>
				<div className={styles.bodyInner}>{renderJobOpenings()}</div>
			</div>
		</>
	);
};

export default JobOpening;
