import React, { Component } from "react";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

export default class CRangePicker extends Component {
	render() {
		return(
			<>
				<RangePicker {...this.props} />
			</>
		)
	}
}

