import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "widgets/Modal";
import Alert from "widgets/Alert";
import { addNewCompany, updateCompanyManager } from "apis";
import Input from "widgets/Input";
import Select from "widgets/Select";
import styles from "./index.scss";
import { SALES_POC } from "../../constants";

const NewCompanyModal = ({
	visible,
	onOk,
	onCancel,
	modalData,
	setModalData
}) => {
	const [isOkDisabled, setIsOkDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState({ error: false, message: "" });

	const {companyName, accountManager, companyId, isCompanyExisting} = modalData

	useEffect(() => {
		const temp = companyName && accountManager;
		setIsOkDisabled(!temp);
	}, [companyName, accountManager]);

	useEffect(() => {
		let timeOut;
		if (error.error) {
			timeOut = setTimeout(() => {
				if (error.error) setError({ error: false, message: "" });
			}, 10000);
		}
		return () => {
			clearTimeout(timeOut);
		};
	}, [error]);

	const handleInputChange = e => {
		if (e.name === "companyName") {
			setModalData({ ...modalData, "companyName": e.value})
		} else if (e.name === "accountManager") {
			setModalData({ ...modalData, "accountManager": e.value})
		}
	};

	const handleOk = () => {
		setIsLoading(true);
		const requiredApi = isCompanyExisting ? updateCompanyManager : addNewCompany;
		(async () => {
			let response = await requiredApi({
				name: companyName,
				account_manager: accountManager,
				company_id: companyId
			});
			if ([200, 201].includes(response.status)) {
				onOk(isCompanyExisting);
			} else if (response.status === 409) {
				setError({
					error: true,
					message: response.data.message
				});
			} else {
				setError({
					error: true,
					message:
						"Sorry, some error has occured. Please try again or check with admin."
				});
			}
			setIsLoading(false);
		})();
	};

	const handleCancel = () => {
		setError({ error: false, message: "" });
		onCancel();
	};

	return (
		<div className={styles.modal}>
			<Modal
				title={
					isCompanyExisting?
						"Edit Company Manager"
						:"Add New Company"
				}
				visible={visible}
				closable={false}
				okText="CONFIRM"
				onOk={handleOk}
				okButtonProps={{ disabled: isOkDisabled, loading: isLoading }}
				onCancel={handleCancel}
				destroyOnClose={true}
			>
				<Input
					name="companyName"
					placeholder="Enter a name"
					labelClassName={styles.label}
					value={companyName}
					onChange={handleInputChange}
					readOnly ={isCompanyExisting?true:false}
				/>
				<Select
					mode="default"
					label="Sales POC"
					name="accountManager"
					placeholder="Select a POC"
					onChange={handleInputChange}
					error={error.accountManager || false}
					options={SALES_POC}
					defaultValue={accountManager}
				/>
				{error.error && (
					<Alert
						type="error"
						message={error.message}
						showIcon={true}
					/>
				)}
			</Modal>
		</div>
	);
};

NewCompanyModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	modalData: PropTypes.object.isRequired,
	setModalData: PropTypes.func.isRequired
};
export default NewCompanyModal;
