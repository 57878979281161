import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal as CModal} from "antd";

export default class Modal extends Component {
	static propTypes = {
		dataSource: PropTypes.array,
		columns: PropTypes.array,
		title: PropTypes.string,
		visible:PropTypes.bool,
		closable: PropTypes.bool,
		okText: PropTypes.string,
		onOk: PropTypes.func,
		okButtonProps: PropTypes.object,
		onCancel: PropTypes.func,
		destroyOnClose: PropTypes.bool
	};
	render() {
		return <CModal {...this.props} />;
	}
}
