import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Table from "widgets/Table";
import styles from "./index.scss";
import Clipboard from "clipboard";

const AttributesTable = props => {
	useEffect(() => {
		let clipboard = new Clipboard(".ctc");
		return () => {
			clipboard.destroy();
		};
	}, []);

	const columns = [
		{
			title: "ID",
			dataIndex: "key",
			key: "key",
			width: "5%",
			className: [styles.idColumn],
			render: key => <span style={{ textAlign: "center" }}>{key}</span> // eslint-disable-line
		},
		{
			title: "Attribute Name",
			dataIndex: "attributeName",
			key: "attributeName",
			width: "65%",
			render: attributeName => <span id={attributeName}>{attributeName}</span> // eslint-disable-line
		},
		{
			title: "Action",
			key: "action",
			width: "20%",
			render: record => ( // eslint-disable-line
				<span>
					<a
						className="ctc"
						href="javascript:;"
						data-clipboard-target={`#${record.attributeName}`}
					>
						Copy to clipboard
					</a>
				</span>
			)
		}
	];

	return (
		<div className={styles.tableContainer}>
			<Table dataSource={props.dataSource} columns={columns} />
		</div>
	);
};

AttributesTable.propTypes = {
	dataSource: PropTypes.array.isRequired
};
export default AttributesTable;
