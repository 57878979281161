/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileViewer from "react-file-viewer";
import styles from "./index.scss";
import PropTypes from "prop-types";
import {
	getJobDetails,
	getCandidateApplications,
	extractCandidateApplications,
	getCandidateResumeLink,
	getAllDomains,
	getDownloadReport,
} from "apis";

import {
	faChevronUp,
	faChevronDown,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import LocationSelect from "shared/LocationSelect";
import UpdateStatus from "shared/UpdateStatus";
import SpinnerWithText from "shared/SpinnerWithText";
import Tag from "shared/Tag";
import InputWithUnits from "shared/InputWithUnits";
import { SearchIcon } from "icons";
import CustomTabs from "widgets/CustomTabs";
import Select from "widgets/Select";
import Button from "widgets/Button";
import Table from "widgets/Table";
import Modal from "widgets/Modal";
import Tooltip from "widgets/Tooltip";
import Input from "widgets/Input";
import CustomDropdown from "widgets/CustomDropdown";
import CustomButton from "widgets/CustomButton";
import cx from "classnames";
import { APPLICATION_STATUSES, PAGE_SIZE } from "modules/constants.js";
import {
	withToolTip,
	populateDefaultDataForFetchCandidatesAPI,
	downloadUrl,
	capitalizeFirstLetter,
	capitalizeFirstLetterOfLocation,
	isEmpty,
} from "modules/helper.js";
import regexStrings from "constants/regexStrings";
import customDownload from "images/upgrad/customDownload.svg";
import quickView from "images/upgrad/quickView.svg";
import pdfDownload from "images/upgrad/pdfDownload.svg";
import downloadReport from "images/upgrad/downloadReport.svg";
const STATUS_TYPES = [
	{
		value: "0",
		label: "All Students",
	},
	...APPLICATION_STATUSES,
];

const DROPDOWN_MENU = [
	{
		key: "DOWNLOAD_REPORT",
		icon: customDownload,
		text: "Download Report",
	},
];

const LearnerApplications = (props) => {
	const { match } = props;
	const [showFilters, setShowFilters] = useState({
		location: false,
		totalExp: false,
		relevantExp: false,
		salary: false,
		domain: false,
		noticePeriod: false,
	});
	const [dataSource, setDataSource] = useState([]);
	const [filtersData, setFiltersData] = useState({});
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [tabActiveKey, setTabActiveKey] = useState();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [updateStatusDetails, setUpdateStatusDetails] = useState({});
	const [isQuickViewModalVisible, setIsQuickViewModalVisible] = useState(
		false
	);
	const [jobDetails, setJobDetails] = useState({});
	const [selectedName, setSelectedName] = useState();
	const [quickViewLink, setQuickViewLink] = useState();
	const [downloadLink, setDownloadLink] = useState();
	const [tableProps, setTableProps] = useState({
		pagination: {
			total: 0,
			current: 0,
			pageSize: PAGE_SIZE,
			totalPages: 0,
		},
		loading: true,
	});
	const [sorter, setSorter] = useState({});
	const [isLoadData, setIsLoadData] = useState(true);
	const [keywordSearch, setKeywordSearch] = useState("");
	const [isExtractLoading, setIsExtractLoading] = useState(false);
	const [isExtractDisabled, setIsExtractDisabled] = useState(false);
	const [jobStatus, setJobStatus] = useState("All Students");
	const [domainOptions, setDomainOptions] = useState([]);
	const [user, setUser] = useState({});
	const [isDownloadReportDisabled, setIsDownloadReportDisabled] = useState(
		false
	);

	useEffect(() => {
		let isMounted = true;
		let tempDomainData = [];
		const fetchJobDetails = async () => {
			try {
				let response = await getJobDetails({
					jobID: match.params.jobID,
				});
				if (isMounted)
					if (response.status === 200) {
						const {
							title,
							companyName,
							salaryFrom,
							salaryTo,
							location,
						} = response.data.attributes;
						if (isMounted)
							setJobDetails({
								title,
								companyName,
								salaryFrom,
								salaryTo,
								location: location.split(",").join(", "),
							});
					} else {
						setJobDetails({});
					}
			} catch (e) {
				//error
			}
		};

		const fetchDomains = async () => {
			let response = await getAllDomains();
			if (isMounted)
				if (response.status === 200) {
					const options = response.data.map((domain) => ({
						label: domain.name.trim(),
						value: domain.key,
					}));
					tempDomainData.push(options);
					setDomainOptions(options);
				} else {
					setDomainOptions([]);
				}
		};
		const fetchAll = async () => {
			await fetchJobDetails();
			await fetchDomains();
			await fetchCandidates({
				pageNo: tableProps.pagination.current,
				data: filtersData,
				tempDomainData: tempDomainData,
			});
			if (isMounted) {
				setIsLoadData(false);
			}
		};

		fetchAll();
		return () => {
			isMounted = false;
		};
	}, [fetchCandidates]);

	/**
	 * To handle status filter
	 */
	useEffect(() => {
		if (jobStatus) {
			let data = populateDefaultDataForFetchCandidatesAPI(
				0,
				sorter,
				PAGE_SIZE
			);
			setTableProps({
				...tableProps,
				loading: true,
			});
			(data["query"] = keywordSearch.query ? keywordSearch.query : ""),
				(data["filters"] = populateFilterDataForCandidatesAPI(
					filtersData,
					domainOptions
				));
			fetchCandidates({
				pageNo: 0,
				data: JSON.stringify(data),
			});
		}
	}, [jobStatus]);

	/*
	 * create filter Api response
	 */
	const populateFilterDataForCandidatesAPI = (filters, domainOptions) => {
		let data = {};
		if (Object.keys(filters).length !== 0 || jobStatus) {
			let domainKey;
			if (filters.domain) {
				domainKey = domainOptions
					.filter((domain) => filters.domain.includes(domain.label))
					.map((domain) => domain.value)
					.join(",");
			}
			data = {
				location: filters.location ? filters.location : [],
				noticePeriodMinMax:
					filters.minNoticePeriod || filters.maxNoticePeriod
						? {
								min: filters.minNoticePeriod
									? parseInt(filters.minNoticePeriod)
									: 0,
								max: filters.maxNoticePeriod
									? parseInt(filters.maxNoticePeriod)
									: 0,
						  }
						: { min: 0, max: 0 },
				domain: filters.domain ? domainKey : "",
				salary:
					filters.minSalary || filters.maxSalary
						? {
								min: filters.minSalary
									? parseInt(filters.minSalary)
									: 0,
								max: filters.maxSalary
									? parseInt(filters.maxSalary)
									: 0,
						  }
						: { min: 0, max: 0 },
				totalExp:
					filters.minTotalExperience || filters.maxTotalExperience
						? {
								min: filters.minTotalExperience
									? parseInt(filters.minTotalExperience)
									: 0,
								max: filters.maxTotalExperience
									? parseInt(filters.maxTotalExperience)
									: 0,
						  }
						: { min: 0, max: 0 },
				relevantExp:
					filters.minRelevantExperience ||
					filters.minRelevantExperience
						? {
								min: filters.minRelevantExperience
									? parseInt(filters.minRelevantExperience)
									: 0,
								max: filters.maxRelevantExperience
									? parseInt(filters.maxRelevantExperience)
									: 0,
						  }
						: { min: 0, max: 0 },
				jobApplicationStatus: jobStatus
					? jobStatus === "All Students"
						? ""
						: jobStatus
					: "",
			};
		} else {
			data = {};
		}
		return data;
	};

	/**
	 * Use to handle pagination and sorting
	 */
	const handleTableChange = (pagination, filters, tempSorter) => {
		const pager = { ...tableProps.pagination };
		//to apply scroll only on pagination not on sorter
		if (["name"].includes(tempSorter.field)) {
			tempSorter["field"] = tempSorter.field + ".keyword";
		} else if (tempSorter.field === "jobMappings") {
			tempSorter.field = "jobApplicationStatus";
		}
		setSorter(tempSorter);
		pager.current = pagination.current;
		setTableProps({
			pagination: pager,
			loading: true,
		});

		let data = {};
		data = populateDefaultDataForFetchCandidatesAPI(
			pagination.current - 1,
			tempSorter,
			PAGE_SIZE
		);
		data["query"] = keywordSearch.query ? keywordSearch.query : "";
		if (Object.keys(filtersData).length !== 0 || jobStatus) {
			data["filters"] = populateFilterDataForCandidatesAPI(
				filtersData,
				domainOptions
			);
		}
		fetchCandidates({ pageNo: pagination.current, data: data });
	};

	/*
	 * handle keyword search and return list on clear txt field
	 */
	const handleKeywordSearch = ({ name, value }) => {
		if (value !== "" && value.length >= 1) {
			setKeywordSearch({ ...keywordSearch, [name]: value });
		} else {
			setKeywordSearch({ [name]: "" });
			let data = {};
			setTableProps({
				...tableProps,
				loading: true,
			});
			data = populateDefaultDataForFetchCandidatesAPI(
				0,
				sorter,
				PAGE_SIZE
			);
			if (Object.keys(filtersData).length !== 0 || jobStatus) {
				(data["query"] = ""),
					(data["filters"] = populateFilterDataForCandidatesAPI(
						filtersData,
						domainOptions
					));
			}
			fetchCandidates({ pageNo: 0, data: JSON.stringify(data) });
		}
	};

	/*
	 * filter data on press enter inside keyword search
	 */
	const handleKeywordSearchOnEnter = (e) => {
		if (e.keyCode === 13) {
			let data = {};
			setTableProps({
				...tableProps,
				loading: true,
			});
			data = populateDefaultDataForFetchCandidatesAPI(
				0,
				sorter,
				PAGE_SIZE
			);
			data["query"] = keywordSearch.query ? keywordSearch.query : "";
			if (Object.keys(filtersData).length !== 0 || jobStatus) {
				data["filters"] = populateFilterDataForCandidatesAPI(
					filtersData,
					domainOptions
				);
			}
			fetchCandidates({ pageNo: 0, data: JSON.stringify(data) });
		}
	};

	/*
	 * search keyword data onclick search icon
	 */
	const handleQuerySearch = () => {
		let data = {};
		setTableProps({
			...tableProps,
			loading: true,
		});
		data = populateDefaultDataForFetchCandidatesAPI(0, sorter, PAGE_SIZE);
		data["query"] = keywordSearch.query ? keywordSearch.query : "";
		if (Object.keys(filtersData).length !== 0 || jobStatus) {
			data["filters"] = populateFilterDataForCandidatesAPI(
				filtersData,
				domainOptions
			);
		}
		fetchCandidates({ pageNo: 0, data: JSON.stringify(data) });
	};

	/**
	 * To handle update status modal
	 */
	const handleShowModal = (application_IDs, status) => {
		setIsModalVisible(true);
		setUpdateStatusDetails({ application_IDs, status });
	};

	/**
	 * To handle multiple row selection
	 */
	const handleRowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys) => {
			setSelectedRowKeys(selectedRowKeys);
		},
		hideDefaultSelections: true,
		// selections: [
		// 	{
		// 		key: "all-data",
		// 		text: "Select All Data",
		// 		onSelect: () => {
		// 			setSelectedRowKeys(
		// 				dataSource.map((application) => application.key)
		// 			);
		// 		},
		// 	},
		// 	{
		// 		key: "no-data",
		// 		text: "DeSelect All Data",
		// 		onSelect: () => {
		// 			setSelectedRowKeys([]);
		// 		},
		// 	},
		// ],
	};

	/**
	 * To handle filter chnage value
	 */
	const handleFilterChange = ({ name, value }) => {
		setFiltersData({ ...filtersData, [name]: value });
	};

	/**
	 * To handle collapsible clicks
	 */
	const handleFilterCollapsibleClick = (name) => {
		setShowFilters({ ...showFilters, [name]: !showFilters[name] });
	};

	/**
	 * To clear selected filters
	 */
	const handleClearFilters = () => {
		setFiltersData({});
		setShowFilters(false);
		setTableProps({
			...tableProps,
			loading: true,
		});
		let data = populateDefaultDataForFetchCandidatesAPI(
			0,
			sorter,
			PAGE_SIZE
		);
		(data["query"] = keywordSearch.query ? keywordSearch.query : ""),
			(data["filters"] = populateFilterDataForCandidatesAPI({}));
		fetchCandidates({ pageNo: 0, data: JSON.stringify(data) });
	};

	/**
	 * To handle apply filter method
	 */
	const handleApplyFilters = () => {
		let objectValues = Object.values(filtersData);
		let checkIsEmpty = false;
		objectValues.map((item) => {
			checkIsEmpty = isEmpty(item);
			if (checkIsEmpty) {
				return true;
			}
			return false;
		});
		if (
			(Object.keys(filtersData).length !== 0 || jobStatus) &&
			!checkIsEmpty
		) {
			let data = populateDefaultDataForFetchCandidatesAPI(
				0,
				sorter,
				PAGE_SIZE
			);
			setTableProps({
				...tableProps,
				loading: true,
			});
			(data["query"] = keywordSearch.query ? keywordSearch.query : ""),
				(data["filters"] = populateFilterDataForCandidatesAPI(
					filtersData,
					domainOptions
				));
			fetchCandidates({ pageNo: 0, data: JSON.stringify(data) });
		}
	};

	/**
	 * To handle apply event of update status
	 */
	const handleUpdateStatusOk = (status) => {
		const tempDataSource = dataSource.map((row) => {
			if (updateStatusDetails.application_IDs.includes(row.id)) {
				row.jobMappings = status;
				return {
					...row,
				};
			} else return row;
		});
		setDataSource(tempDataSource);
		if (jobStatus) {
			let data = {};
			data = populateDefaultDataForFetchCandidatesAPI(
				0,
				sorter,
				PAGE_SIZE
			);
			data["query"] = keywordSearch.query ? keywordSearch.query : "";
			if (jobStatus) {
				data["filters"] = populateFilterDataForCandidatesAPI(
					filtersData,
					domainOptions
				);
			}
			fetchCandidates({ pageNo: 0, data: data });
		}
		setIsModalVisible(false);
		setSelectedRowKeys([]);
	};

	/**
	 * To handle cancel event of modal
	 */
	const handleCancelModal = () => {
		setIsModalVisible(false);
	};

	/**
	 * To handle quick view
	 */
	const handleQuickView = async (name, url, responseData) => {
		setSelectedName(name);
		setQuickViewLink(url);
		setDownloadLink(responseData);
	};

	/**
	 * To hadle cancel event of quick view
	 */
	const handleCancelQuickView = () => {
		setUser({});
		setIsQuickViewModalVisible(false);
		setSelectedName("");
		setQuickViewLink("");
	};

	/**
	 * To handle on error method of file viewer
	 */
	const handleFileViewerError = () => {
		//error
	};

	/**
	 * To handle extract event
	 */
	const handleExtractClick = async () => {
		setIsExtractDisabled(true);
		setIsExtractLoading(true);
		let data = {
			candidateIds: selectedRowKeys,
		};
		let response = await extractCandidateApplications(data);
		if (response.status === 200) {
			let blob = await new Blob([response.data]);
			let objectUrl = window.URL.createObjectURL(blob);
			let link = document.createElement("a");
			document.getElementById("extract").appendChild(link);
			link.href = objectUrl;
			link.download = "resume.zip";
			link.click();
			document.getElementById("extract").removeChild(link);
		} else {
			// write error toast code for no response from API
		}
		setIsExtractDisabled(false);
		setIsExtractLoading(false);
	};

	/**
	 * To handle Status change data
	 */
	const handleCandidateStatusChange = (status) => {
		setJobStatus(status.value);
		if (selectedRowKeys.length >= 1) {
			setSelectedRowKeys([]);
		}
	};

	/**
	 * To fetch candidates
	 * @param : current page no , payload object
	 */
	const fetchCandidates = async ({ pageNo, data, tempDomainData = [] }) => {
		try {
			if (Object.keys(data).length === 0) {
				let tempData = populateDefaultDataForFetchCandidatesAPI(
					pageNo,
					sorter,
					PAGE_SIZE
				);
				data = JSON.stringify(tempData);
			}
			let jobID = match.params.jobID;
			let response = await getCandidateApplications(jobID, data);
			if (response.status == 200) {
				let candidates = response.data.content;
				candidates = candidates.map((elem) => {
					tempDomainData.length === 0
						? tempDomainData.push(domainOptions)
						: tempDomainData;
					tempDomainData.map((item) => {
						item.map((domainOption) => {
							if (domainOption.value === elem.domain) {
								elem.domain = domainOption.label;
							}
						});
					});
					let job = elem.jobMappings;
					job.map((element) => {
						if (element.jobId === parseInt(jobID)) {
							elem.jobMappings = element.jobApplicationStatus;
							elem.userId = element.applicationId;
						}
					});
					const uniqueKey = {
						key: elem.id,
					};
					return { ...uniqueKey, ...elem };
				});
				setDataSource(candidates);

				let pagination = { ...tableProps.pagination };
				pagination.total = parseInt(response.data.totalElements);
				pagination.current = pageNo;
				pagination.totalPages = response.data.totalPages;

				setTableProps({
					pagination,
					loading: false,
				});
			} else {
				setDataSource([]);
			}
		} catch (e) {
			//error
		}
	};

	const tabContentData = (tab) => {
		return (
			<>
				{tab === "Applications" ? (
					<div className={styles.rowMT}>
						<div className={styles.learnerStatus}>
							<Select
								mode="default"
								name="jobApplicationStatus"
								onChange={handleCandidateStatusChange}
								defaultValue={STATUS_TYPES[0].label}
								options={STATUS_TYPES}
							/>
						</div>
						<div>
							<div className={styles.selectButtons}>
								<p className={styles.selectedApplications}>
									<b>{selectedRowKeys.length}</b>
									{selectedRowKeys.length > 1
										? "applications selected"
										: "application selected"}
									&nbsp;
								</p>
								<Button
									className={cx(
										styles.whiteButton1,
										styles.updateButton
									)}
									onClick={() =>
										handleShowModal(selectedRowKeys, "")
									}
									disabled={!selectedRowKeys.length}
								>
									Update status
								</Button>
								<Button
									className={
										!selectedRowKeys.length
											? styles.upper
											: styles.upperActive
									}
									onClick={handleExtractClick}
									isLoading={isExtractLoading}
									disabled={
										isExtractDisabled ||
										!selectedRowKeys.length
									}
								>
									Extract Selected
								</Button>
								<div id="extract" />
							</div>
							<UpdateStatus
								details={updateStatusDetails}
								visible={isModalVisible}
								onOk={handleUpdateStatusOk}
								onCancel={handleCancelModal}
								jobID={match.params.jobID}
								dataSource={dataSource}
							/>
							<Modal
								width="60%"
								title={
									selectedName && selectedName !== ""
										? capitalizeFirstLetter(selectedName) +
										  " CV Preview"
										: "CV Preview"
								}
								visible={isQuickViewModalVisible}
								footer={null}
								onCancel={handleCancelQuickView}
								okButtonProps={{ style: { display: "none" } }}
								key={user.id}
							>
								<div className={styles.modalCards}>
									<Button
										className={styles.customHeaderDownload}
										onClick={() =>
											downloadUrl(
												selectedName,
												downloadLink
											)
										}
									>
										<img
											src={pdfDownload}
											className={cx(
												styles.downloadPdfIcon,
												styles.imageClass,
												"mr-8"
											)}
										/>
										&nbsp;
										<span> Download</span>
									</Button>
									{!quickViewLink ? (
										<div className={styles.loader}>
											Loading...
										</div>
									) : (
										<FileViewer
											fileType="pdf"
											filePath={quickViewLink}
											onError={handleFileViewerError}
											key={`${user.id}-${user.name}`}
										/>
									)}
								</div>
							</Modal>
						</div>
					</div>
				) : (
					""
				)}
				<div className={styles.bottom}>
					<div>
						<Table
							rowSelection={handleRowSelection}
							dataSource={dataSource}
							columns={columns}
							pagination={tableProps.pagination}
							loading={tableProps.loading}
							onChange={handleTableChange}
							scroll={{ x: "max-content" }}
						/>
						{tableProps.loading === false ? (
							<span className={styles.pageStyle}>
								{"Page " +
									(tableProps.pagination.current === 0
										? "1"
										: tableProps.pagination.current) +
									" of " +
									tableProps.pagination.totalPages}
							</span>
						) : (
							""
						)}
					</div>
				</div>
			</>
		);
	};

	const handleOnTabChange = (key) => {
		setTabActiveKey(key);
	};

	/*
	 * To fetch candidate resumeUrl using userId and s3ResumeLink
	 */
	const fetchResumeUrl = async (
		action,
		candidateId,
		userId,
		s3ResumeLink,
		name
	) => {
		try {
			let data = { id: candidateId, name: name };
			setUser(data);
			if (action === "quickView") {
				setIsQuickViewModalVisible(true);
			}
			let response = await getCandidateResumeLink(userId, s3ResumeLink);
			if (response.status === 200) {
				let responseData = response.data;
				if (action === "download") {
					downloadUrl(name, responseData);
				} else if (action === "quickView") {
					let blob = await new Blob([responseData], {
						type: "application/octet-stream",
					});
					let objectUrl = window.URL.createObjectURL(blob);
					handleQuickView(name, objectUrl, responseData);
				}
			}
		} catch (e) {
			// error
		}
	};

	const handleMenuClick = async (key) => {
		switch (key) {
			case "DOWNLOAD_REPORT":
				setIsDownloadReportDisabled(true);
				let response = await getDownloadReport(match.params.jobID);
				if (response.status === 200) {
					let blob = await new Blob([response.data], {
						type: "application/octet-stream",
					});
					let objectUrl = window.URL.createObjectURL(blob);
					let link = document.createElement("a");
					link.href = objectUrl;
					link.download =
						"upGrad_job_report_" + match.params.jobID + ".csv";
					link.click();
				} else {
					// write error toast code for no response from API
				}
				setIsDownloadReportDisabled(false);
				break;
		}
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			sorter: true,
			// eslint-disable-next-line
			render: (name) => (
				<span>{withToolTip(capitalizeFirstLetter(name))}</span>
			),
		},
		{
			title: "Domain",
			dataIndex: "domain",
			// eslint-disable-next-line
			render: (domain) => (
				<span>{domain === null ? "No Data" : domain}</span>
			),
		},
		{
			title: "Salary",
			dataIndex: "salary",
			sorter: true,
			// eslint-disable-next-line
			render: (salary) => (
				<span>{salary === null ? "No Data" : salary + " LPA "}</span>
			),
		},
		{
			title: "Status",
			dataIndex: "jobMappings",
			// eslint-disable-next-line
			render: (jobMappings) => {
				let color = "yellow";
				let temp = APPLICATION_STATUSES.filter(
					(applicationStatus) =>
						applicationStatus.label === jobMappings
				)[0];
				if (temp) color = temp.color;
				return <Tag color={color}>{jobMappings}</Tag>;
			},
		},
		{
			title: "Location",
			dataIndex: "location",
			// eslint-disable-next-line
			render: (location) => (
				<Tooltip
					placement="top"
					title={capitalizeFirstLetterOfLocation(location)}
				>
					{location && location !== null
						? location.split(",").length > 2
							? capitalizeFirstLetterOfLocation(location)
									.split(",")
									.slice(0, 2)
									.join(", ") + "..."
							: capitalizeFirstLetterOfLocation(location)
						: "No Data"}
				</Tooltip>
			),
		},
		{
			title: "Tot. Exp",
			dataIndex: "totalExp",
			sorter: true,
			// eslint-disable-next-line
			render: (totalExp) => (
				<span>
					{totalExp === null ? "No Data" : totalExp + " years"}
				</span>
			),
		},
		{
			title: "Rel. Exp",
			dataIndex: "relevantExp",
			sorter: true,
			// eslint-disable-next-line
			render: (relevantExp) => (
				<span>
					{relevantExp === null ? "No Data" : relevantExp + " years"}
				</span>
			),
		},

		{
			title: "Actions",
			fixed: "right",
			key: "action",
			// eslint-disable-next-line
			render: (record) => {
				let temp = [];
				temp.push(record.id);
				return (
					<div className={styles.actionsWrapper}>
						<div className={styles.quickView}>
							<div
								onClick={() =>
									fetchResumeUrl(
										"quickView",
										record.id,
										record.userIdFromCareersDB,
										record.s3ResumeLink,
										record.name
									)
								}
							>
								<Tooltip placement="top" title={"Preview CV"}>
									<img
										src={quickView}
										className={cx(
											styles.quickviewIcon,
											styles.imageClass
										)}
									/>
								</Tooltip>
							</div>
						</div>
						<div className={styles.quickView}>
							<div
								onClick={() =>
									fetchResumeUrl(
										"download",
										record.id,
										record.userIdFromCareersDB,
										record.s3ResumeLink,
										record.name
									)
								}
							>
								<Tooltip placement="top" title={"Download CV"}>
									<img
										src={customDownload}
										className={cx(
											styles.downloadIcon,
											styles.imageClass
										)}
									/>
								</Tooltip>
							</div>
						</div>
						<Tooltip placement="top" title={"Update Status"}>
							<button
								className={cx(styles.actionBtn, {
									[styles.actionButtonDisabled]: !!selectedRowKeys.length,
								})}
								onClick={() =>
									handleShowModal(temp, record.jobMappings)
								}
							></button>
						</Tooltip>
					</div>
				);
			},
		},
	];

	return (
		<>
			<div id="top" className={styles.secondaryHeaderOuter}>
				<div className={styles.secondaryHeaderInner}>
					<div className={styles.leftArea}>
						<Link to="/jobs" className={styles.title}>
							Job Openings&emsp;
						</Link>
						<>
							<FontAwesomeIcon icon={faChevronRight} />
							<span className={styles.breadCrumb}>
								&emsp;
								{jobDetails.companyName
									? jobDetails.companyName.length > 25
										? jobDetails.companyName.substring(
												0,
												25
										  ) + "..."
										: jobDetails.companyName
									: ""}
								&nbsp;-&nbsp;
								{jobDetails.title
									? jobDetails.title.length > 25
										? jobDetails.title.substring(0, 25) +
										  "..."
										: jobDetails.title
									: ""}
							</span>
						</>
					</div>

					<div className={styles.rightArea}>
						<div className={styles.searchInputBoxWrapper}>
							<Input
								className={styles.searchInputBox}
								placeholder="Search...."
								name="query"
								onChange={handleKeywordSearch}
								onKeyDown={(e) => handleKeywordSearchOnEnter(e)}
							></Input>
							<SearchIcon
								className={styles.searchInputBoxIcon}
								onClick={handleQuerySearch}
							/>
						</div>
						<div className={styles.optionMenuWrapper}>
							<CustomDropdown
								handleMenuClick={handleMenuClick}
								subMenuItemText={styles.subMenuItemText}
								disabledButton={isDownloadReportDisabled}
								menuItems={DROPDOWN_MENU}
								menuListClass={styles.downloadReport}
								submenuItemClass={styles.submenuItem}
								downloadReportIconClass={
									styles.downloadReportIcon
								}
							>
								<CustomButton className={styles.buttonStyle}>
									<img src={downloadReport} />
								</CustomButton>
							</CustomDropdown>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.bodyOuter}>
				<div className={styles.bodyInner}>
					{isLoadData ? (
						<SpinnerWithText
							text="Fetching details..."
							className={styles.spinnerContainer}
						/>
					) : (
						<div className={styles.container}>
							<div className={styles.left}>
								<div className={styles.header}>
									<div className={styles.text}>Filters</div>
									<div className={styles.row}>
										<div
											className={
												Object.keys(filtersData)
													.length === 0
													? styles.applyTextInactive
													: styles.applyText
											}
											onClick={handleApplyFilters}
											disabled={
												Object.keys(filtersData)
													.length === 0
											}
										>
											Apply
										</div>
										<div
											className={
												Object.keys(filtersData)
													.length === 0
													? styles.clearTextDisabled
													: styles.clearText
											}
											onClick={handleClearFilters}
											disabled={
												Object.keys(filtersData)
													.length === 0
											}
										>
											Clear
										</div>
									</div>
								</div>

								<div className={styles.filters}>
									<div
										className={styles.filterLabel}
										onClick={() =>
											handleFilterCollapsibleClick(
												"location"
											)
										}
									>
										<div>Location</div>
										<div>
											{showFilters.location ? (
												<FontAwesomeIcon
													icon={faChevronUp}
												/>
											) : (
												<FontAwesomeIcon
													icon={faChevronDown}
												/>
											)}
										</div>
									</div>
									<div
										style={{
											display: showFilters.location
												? "block"
												: "none",
										}}
									>
										<LocationSelect
											name="location"
											mode="places"
											badgeItems={
												filtersData.location || []
											}
											onChange={handleFilterChange}
										/>
									</div>
								</div>
								<div className={styles.filters}>
									<div
										className={styles.filterLabel}
										onClick={() =>
											handleFilterCollapsibleClick(
												"totalExp"
											)
										}
									>
										<div>Total Experience</div>
										<div>
											{showFilters.totalExp ? (
												<FontAwesomeIcon
													icon={faChevronUp}
												/>
											) : (
												<FontAwesomeIcon
													icon={faChevronDown}
												/>
											)}
										</div>
									</div>
									<div
										style={{
											display: showFilters.totalExp
												? "block"
												: "none",
										}}
									>
										<InputWithUnits
											inputLabel="Minimum"
											unitsLabel="Years"
											name="minTotalExperience"
											placeholder="00"
											type="number"
											value={
												filtersData.minTotalExperience ||
												""
											}
											inputClassName={cx(
												styles.smallInput55,
												styles.smallMinInput55
											)}
											inputLabelClassName={styles.label1}
											unitsLabelClassName={
												styles.unitsLabel
											}
											regex={
												regexStrings.TWO_DIGITS_TWO_DECIMALS
											}
											onChange={handleFilterChange}
										/>
										<InputWithUnits
											inputLabel="Maximum"
											unitsLabel="Years"
											name="maxTotalExperience"
											placeholder="00"
											type="number"
											value={
												filtersData.maxTotalExperience ||
												""
											}
											inputClassName={styles.smallInput55}
											inputLabelClassName={styles.label1}
											unitsLabelClassName={
												styles.unitsLabel
											}
											regex={
												regexStrings.TWO_DIGITS_TWO_DECIMALS
											}
											onChange={handleFilterChange}
										/>
									</div>
								</div>
								<div className={styles.filters}>
									<div
										className={styles.filterLabel}
										onClick={() =>
											handleFilterCollapsibleClick(
												"relevantExp"
											)
										}
									>
										<div>Relevant Experience</div>
										<div>
											{showFilters.relevantExp ? (
												<FontAwesomeIcon
													icon={faChevronUp}
												/>
											) : (
												<FontAwesomeIcon
													icon={faChevronDown}
												/>
											)}
										</div>
									</div>
									<div
										style={{
											display: showFilters.relevantExp
												? "block"
												: "none",
										}}
									>
										<InputWithUnits
											inputLabel="Minimum"
											unitsLabel="Years"
											name="minRelevantExperience"
											placeholder="00"
											type="number"
											value={
												filtersData.minRelevantExperience ||
												""
											}
											inputClassName={cx(
												styles.smallInput55,
												styles.smallMinInput55
											)}
											inputLabelClassName={styles.label1}
											unitsLabelClassName={
												styles.unitsLabel
											}
											regex={
												regexStrings.TWO_DIGITS_TWO_DECIMALS
											}
											onChange={handleFilterChange}
										/>
										<InputWithUnits
											inputLabel="Maximum"
											unitsLabel="Years"
											name="maxRelevantExperience"
											placeholder="00"
											type="number"
											value={
												filtersData.maxRelevantExperience ||
												""
											}
											inputClassName={styles.smallInput55}
											inputLabelClassName={styles.label1}
											unitsLabelClassName={
												styles.unitsLabel
											}
											regex={
												regexStrings.TWO_DIGITS_TWO_DECIMALS
											}
											onChange={handleFilterChange}
										/>
									</div>
								</div>
								<div className={styles.filters}>
									<div
										className={styles.filterLabel}
										onClick={() =>
											handleFilterCollapsibleClick(
												"salary"
											)
										}
									>
										<div>Salary</div>
										<div>
											{showFilters.salary ? (
												<FontAwesomeIcon
													icon={faChevronUp}
												/>
											) : (
												<FontAwesomeIcon
													icon={faChevronDown}
												/>
											)}
										</div>
									</div>
									<div
										style={{
											display: showFilters.salary
												? "block"
												: "none",
										}}
									>
										<InputWithUnits
											inputLabel="Minimum"
											unitsLabel="LPA"
											name="minSalary"
											placeholder="00"
											type="number"
											value={filtersData.minSalary || ""}
											inputClassName={cx(
												styles.smallInput55,
												styles.smallMinInput55
											)}
											inputLabelClassName={styles.label1}
											unitsLabelClassName={
												styles.unitsLabel
											}
											regex={
												regexStrings.TWO_DIGITS_TWO_DECIMALS
											}
											onChange={handleFilterChange}
										/>
										<InputWithUnits
											inputLabel="Maximum"
											unitsLabel="LPA"
											name="maxSalary"
											placeholder="00"
											type="number"
											value={filtersData.maxSalary || ""}
											inputClassName={styles.smallInput55}
											inputLabelClassName={styles.label1}
											unitsLabelClassName={
												styles.unitsLabel
											}
											regex={
												regexStrings.TWO_DIGITS_TWO_DECIMALS
											}
											onChange={handleFilterChange}
										/>
									</div>
								</div>
								<div className={styles.filters}>
									<div
										className={styles.filterLabel}
										onClick={() =>
											handleFilterCollapsibleClick(
												"noticePeriod"
											)
										}
									>
										<div>Notice Period</div>
										<div>
											{showFilters.noticePeriod ? (
												<FontAwesomeIcon
													icon={faChevronUp}
												/>
											) : (
												<FontAwesomeIcon
													icon={faChevronDown}
												/>
											)}
										</div>
									</div>
									<div
										style={{
											display: showFilters.noticePeriod
												? "block"
												: "none",
										}}
									>
										<InputWithUnits
											inputLabel="Minimum"
											unitsLabel="Days"
											name="minNoticePeriod"
											placeholder="00"
											type="number"
											value={
												filtersData.minNoticePeriod ||
												""
											}
											inputClassName={cx(
												styles.smallInput55,
												styles.smallMinInput55
											)}
											inputLabelClassName={styles.label1}
											unitsLabelClassName={
												styles.unitsLabel
											}
											regex={
												regexStrings.TWO_DIGITS_TWO_DECIMALS
											}
											onChange={handleFilterChange}
										/>
										<InputWithUnits
											inputLabel="Maximum"
											unitsLabel="Days"
											name="maxNoticePeriod"
											placeholder="00"
											type="number"
											value={
												filtersData.maxNoticePeriod ||
												""
											}
											inputClassName={styles.smallInput55}
											inputLabelClassName={styles.label1}
											unitsLabelClassName={
												styles.unitsLabel
											}
											regex={
												regexStrings.TWO_DIGITS_TWO_DECIMALS
											}
											onChange={handleFilterChange}
										/>
									</div>
								</div>
								<div className={styles.filters}>
									<div
										className={styles.filterLabel}
										onClick={() =>
											handleFilterCollapsibleClick(
												"domain"
											)
										}
									>
										<div>Domain</div>
										<div>
											{showFilters.domain ? (
												<FontAwesomeIcon
													icon={faChevronUp}
												/>
											) : (
												<FontAwesomeIcon
													icon={faChevronDown}
												/>
											)}
										</div>
									</div>
									<div
										style={{
											display: showFilters.domain
												? "block"
												: "none",
										}}
									>
										<Select
											allowClear={true}
											mode="default"
											name="domain"
											placeholder="Select Domain"
											options={domainOptions || {}}
											onChange={handleFilterChange}
										/>
									</div>
								</div>
							</div>
							<div className={styles.right}>
								<div className={styles.top}>
									<div className={styles.pageName}>
										Student Applications
									</div>
									<div className={styles.jobDescCardRow}>
										<div className={styles.jobInfoCard}>
											<div className={styles.name}>
												{jobDetails.companyName} -
												&nbsp;
												{jobDetails.title}
											</div>
											<div className={styles.details}>
												{jobDetails.salaryFrom ? (
													<span>
														{jobDetails.salaryFrom}
														&nbsp;LPA -&nbsp;
													</span>
												) : (
													<span>No Data - </span>
												)}
												{jobDetails.salaryTo ? (
													<span>
														{jobDetails.salaryTo}
														&nbsp;LPA
													</span>
												) : (
													<span>No Data</span>
												)}
												<div>{jobDetails.location}</div>
											</div>
										</div>
										<div className={styles.appCountCard}>
											<div className={styles.name}>
												No. of Applications
											</div>
											<div className={styles.number}>
												{tableProps.pagination.total}
											</div>
										</div>
									</div>

									<CustomTabs
										defaultActiveKey={tabActiveKey}
										onChange={handleOnTabChange}
									>
										<CustomTabs
											tab={
												"Applications" +
												" (" +
												tableProps.pagination.total +
												") "
											}
											key="1"
											contentData={tabContentData(
												"Applications"
											)}
										/>
										<CustomTabs
											tab=""
											key="2"
											contentData={""}
										/>
									</CustomTabs>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
LearnerApplications.propTypes = {
	match: PropTypes.object,
	userInfo: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		userInfo: state.userInfo,
	};
}

export default connect(mapStateToProps, null)(LearnerApplications);
