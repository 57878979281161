import React from "react";
import PropTypes from "prop-types";
import { Icon as AntdIcon } from "antd";

const Icon = ({type}) => {
	return <AntdIcon type = {type} />;
};

Icon.propTypes = {
	type: PropTypes.string.isRequired
};
export default Icon;
