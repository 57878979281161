import React, { Component } from "react";
import { Button as CButton } from "antd";

export default class CustomButton extends Component {
	render() {
		return (
			<>
				<CButton {...this.props} />
			</>
		);
	}
}
