import moment from "moment";

export const prepareLiveSessionDataToSubmit = (eventData, status) => {
	let data;
	if (status === "PUBLISHED") {
		data = prepareDataForPublish(eventData);
	} else {
		data= prepareDataForDraft(eventData);
	}
	return data;
}

const prepareDataForDraft = (eventData) => {
	let { basicInfo, eventInfo, speakerInfo, resourceInfo } = eventData;
	const temp = {
		status: "DRAFT",
		type: "LIVE_SESSION",
		title: basicInfo.title.value,
		category: basicInfo.category.value,
		date:{
			start: basicInfo.startDate.value || "",
			end: basicInfo.endDate.value || ""
		},
		joinURL: basicInfo.joinURL.value || "",
		assets:{
			bannerImage: basicInfo.bannerImage.value || "",
			thumbnailImage: basicInfo.thumbnailImage.value || ""
		},
		typeformIDs:{
			registration: basicInfo.registrationTypeformID.value || "",
			feedback: basicInfo.feedbackTypeformID.value || "",
		},
		tabEventDetails:{
			description: eventInfo.description.value || ""
		},
		tabResources: {
			resourcesList: []
		},
		tabSpeakers: {
			speakersList: []
		},
		eventCompletionData:{
			recordingURL: "",
			recordingDuration: 0
		}
	}

	let tempSpeakersList = [];
	speakerInfo.forEach( speaker => {
		let hasValueInAnyKey = false;
		for (let key in speaker) {
			if(speaker[key]["value"]) {
				hasValueInAnyKey = true;
				speaker[key] = speaker[key]["value"]
			} else {
				speaker[key] = "";
			}
		}
		if(hasValueInAnyKey) {
			tempSpeakersList.push(speaker);
		}
	})
	temp.tabSpeakers.speakersList = tempSpeakersList;

	let tempResourcesList = [];
	resourceInfo.forEach( resource => {
		let hasValueInAnyKey = false;
		for (let key in resource) {
			if(resource[key]["value"]) {
				hasValueInAnyKey = true;
				resource[key] = resource[key]["value"]
			} else {
				resource[key] = "";
			}
		}
		if(hasValueInAnyKey) {
			tempResourcesList.push(resource);
		}
	})
	temp.tabResources.resourcesList = tempResourcesList;

	return temp;
}

const prepareDataForPublish = (eventData) => {
	let { basicInfo, eventInfo, speakerInfo, resourceInfo } = eventData;
	const temp = {
		status: "PUBLISHED",
		type: "LIVE_SESSION",
		title: basicInfo.title.value,
		category: basicInfo.category.value,
		date:{
			start: basicInfo.startDate.value,
			end: basicInfo.endDate.value
		},
		joinURL: basicInfo.joinURL.value,
		assets:{
			bannerImage: basicInfo.bannerImage.value,
			thumbnailImage: basicInfo.thumbnailImage.value
		},
		typeformIDs:{
			registration: basicInfo.registrationTypeformID.value || "",
			feedback: basicInfo.feedbackTypeformID.value || "",
		},
		tabEventDetails:{
			description: eventInfo.description.value
		},
		tabResources: {
			resourcesList: []
		},
		tabSpeakers: {
			speakersList: []
		},
		eventCompletionData:{
			recordingURL: "",
			recordingDuration: 0
		}
	}

	temp.tabSpeakers.speakersList = speakerInfo.map(speaker => {
		for (let key in speaker) {
			speaker[key] = speaker[key]["value"];
		}
		return speaker;
	});

	if(resourceInfo[0].title.value) {
		temp.tabResources.resourcesList = resourceInfo.map(resource => {
			for (let key in resource) {
				resource[key] = resource[key]["value"];
			}
			return resource;
		});
	}
	return temp;
}

export const prepareLiveSessionDataToRender = (eventId, data) => {
	let temp = {
		id: eventId,
		status: data.status,
		type: "LIVE_SESSION",
		basicInfo: {
			title: { value : data.title },
			category: { value : data.category },
			startDate: { value : data.date.start ? moment(data.date.start) : "" },
			endDate: { value : data.date.end ? moment(data.date.end) : "" },
			joinURL: { value: data.joinURL },
			bannerImage: { value: data.assets.bannerImage },
			thumbnailImage: { value: data.assets.thumbnailImage },
			registrationTypeformID: { value: data.typeformIDs.registration },
			feedbackTypeformID: { value: data.typeformIDs.feedback }
		},
		eventInfo: {
			description: { value: data.tabEventDetails.description },
		},
	};

	if (data.tabSpeakers.speakersList.length) {
		temp.speakerInfo = data.tabSpeakers.speakersList.map(speaker => {
			for (let key in speaker) {
				speaker[key] = { value : speaker[key] }
			}
			return speaker;
		})
	} else {
		temp.speakerInfo = [{
			imageURL: { value: "" },
			name: { value: "" },
			linkedinURL: { value: "" },
			company: { value: "" },
			designation: { value: "" },
			topic: { value: "" },
			about: { value: "" }
		}]
	}

	if (data.tabResources.resourcesList.length) {
		temp.resourceInfo = data.tabResources.resourcesList.map(resource => {
			for(let key in resource) {
				resource[key] = {value: resource[key]};
			}
			return resource;
		})
	} else {
		temp.resourceInfo = [{
			title: { value: "" },
			url: { value: "" },
			description: { value: "" }
		}]
	}

	return temp;
}
