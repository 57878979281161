import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cx from "classnames";
import Table from "widgets/Table";
import Toast from "widgets/Toast";
import Input from "widgets/Input";
import PropTypes from "prop-types";
import AddNewCompany from "./AddNewCompany";
import { SearchIcon } from "icons";
import styles from "./index.scss";
import Fuse from "fuse.js";
import { getCompanies } from "apis";
// future use
// import { getCompanies, exportResource } from "apis";
import Button from "widgets/Button";

const PAGE_SIZE = 10;
const toast = new Toast();

const AllCompanies = props => {
	const [companiesCopy, setCompaniesCopy] = useState([]);
	const [dataSource, setDataSource] = useState([]);

	const [modalData, setModalData] = useState({
		companyName: "",
		accountManager: "",
		companyId: null,
		isCompanyExisting: false,
		isVisible: false
	});

	const [companySearcher, setCompanySearcher] = useState(null);

	const [tableProps, setTableProps] = useState({
		pagination: { total: 0, current: 1, pageSize: PAGE_SIZE },
		loading: true
	});

	useEffect(() => {
		fetchCompanies({ pageNo: tableProps.pagination.current });
	}, []);

	const showModal = ({ accountManager, name, id }) => {
		if (name.length) {
			setModalData({
				...modalData,
				companyName: name,
				accountManager: accountManager,
				companyId: id,
				isCompanyExisting: true,
				isVisible: true
			});
		} else {
			setModalData({
				...modalData,
				companyName: name,
				accountManager: accountManager,
				companyId: null,
				isCompanyExisting: false,
				isVisible: true
			});
		}
	};

	const handleCompanySearch = query => {
		let companies = dataSource.slice();
		if (query) companies = companySearcher.search(query);
		setCompaniesCopy(companies);
	};

	// future use
	// const handleExtract = () => {
	// 	(async () => {
	// 		try {
	// 			const params = {
	// 				resource: 'company'
	// 			}
	// 			let response = await exportResource(params);
	// 			if (response.status == 200){
	// 				csvCreator(response.data, 'ALL_COMPANIES');
	// 			}
	// 		} catch (e) {
	// 			// error
	// 		}
	// 	})()
	// };

	const handleOk = inEditMode => {
		setModalData({
			...modalData,
			companyName: "",
			accountManager: "",
			isVisible: false
		});
		fetchCompanies({ pageNo: 1 });
		if (inEditMode) toast.success(`Edited Company manager successfully`);
		else toast.success(`New Company Added`);
	};

	const handleCancel = () => {
		setModalData({
			...modalData,
			companyName: "",
			accountManager: "",
			isVisible: false
		});
	};

	const fetchCompanies = async ({ pageNo }) => {
		if (document.getElementById("top")) {
			document
				.getElementById("top")
				.scrollIntoView({ behavior: "smooth" });
		}
		try {
			let response = await getCompanies();

			if (response.status == 200) {
				let { data } = response.data;
				data = data.reverse().map((elem, i) => {
					const serialNum = {
						sno: i + 1 + (pageNo - 1) * PAGE_SIZE
					};
					const uniqueKey = {
						key: elem.id
					};
					return { ...serialNum, ...uniqueKey, ...elem };
				});
				setCompaniesCopy(data);
				setDataSource(data);
				let pagination = { ...tableProps.pagination };
				pagination.total = parseInt(response.headers.count);
				pagination.current = pageNo;
				setTableProps({
					pagination,
					loading: false
				});
				setCompanySearcher(
					new Fuse(data, {
						keys: [{ name: "name", weight: 1 }]
					})
				);
			} else {
				// write error toast code for no response from API
			}
		} catch (e) {
			// error
		}
	};

	const handleTableChange = pagination => {
		const pager = { ...tableProps.pagination };
		pager.current = pagination.current;
		setTableProps({
			pagination: pager
		});
	};

	const handleOnEditClick = rowData => {
		let companyName = rowData.currentTarget.dataset["examId"];
		const selectedCompany = companiesCopy.find(
			company => company.name === companyName
		);
		showModal(selectedCompany);
	};

	const columns = [
		{
			title: "Sr.No.",
			dataIndex: "sno",
			className: cx(styles.smallColumn, styles.column),
			// eslint-disable-next-line
			render: id => <span>{id}</span>
		},
		{
			title: "Company Name",
			dataIndex: "name",
			className: cx(styles.largeColumn, styles.column),
			// eslint-disable-next-line
			render: cname => <span>{cname}</span>
		},
		{
			title: "Sales POC",
			dataIndex: "accountManager",
			className: cx(styles.mediumColumn, styles.column),
			// eslint-disable-next-line
			render: name => <span>{name}</span>
		},
		{
			title: "Action",
			dataIndex: "name",
			key: "action",
			className: cx(styles.mediumColumn, styles.column),
			// eslint-disable-next-line
			render: key => (
				<span>
					<Link to={`/jobs?company=${key}&&jobStatus=All Jobs`}>
						<button
							className={cx(styles.redirectBtn, styles.actionBtn)}
						/>
					</Link>
					<button
						className={cx(styles.editBtn, styles.actionBtn)}
						data-exam-id={key}
						onClick={handleOnEditClick}
					/>
				</span>
			)
		}
	];

	return (
		<>
			<div id="top" className={styles.secondaryHeaderOuter}>
				<div className={styles.secondaryHeaderInner}>
					<div className={styles.title}>
						Companies&nbsp;({companiesCopy.length})
					</div>
					<div className={styles.searchBar}>
						<SearchIcon className={styles.searchIcon} />
						<Input
							className={styles.search}
							placeholder="Search Companies ..."
							onChange={({ value }) => handleCompanySearch(value)}
						></Input>
					</div>
					{props.userInfo.isAdmin && (
						<>
							<Button
								className={cx(styles.cBtn, "mr-24")}
								onClick={() =>
									showModal({
										accountManager: "",
										name: ""
									})
								}
							>
								Add New Company
							</Button>
							<AddNewCompany
								visible={modalData.isVisible}
								onOk={handleOk}
								onCancel={handleCancel}
								modalData={modalData}
								setModalData={setModalData}
							/>
						</>
					)}
					{/* <Button */}
					{/* 	className={cx(styles.cBtn, 'mr-24')} */}
					{/* 	onClick={handleExtract} */}
					{/* > */}
					{/* 	Extract */}
					{/* </Button> */}
				</div>
			</div>
			<div className={styles.bodyOuter}>
				<div className={styles.bodyInner}>
					<div className={styles.tableContainer}>
						<Table
							columns={columns}
							dataSource={companiesCopy}
							pagination={tableProps.pagination}
							loading={tableProps.loading}
							onChange={handleTableChange}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

function mapStateToProps(state) {
	return {
		userInfo: state.userInfo
	};
}
AllCompanies.propTypes = {
	userInfo: PropTypes.object
};
export default connect(mapStateToProps, null)(AllCompanies);
