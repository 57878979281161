import React, { useState, useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";

import constants from "constants";
import SpinnerWithText from "shared/SpinnerWithText";
import styles from "./index.scss";
import parentStyles from "../index.scss";
import { createEvent, editEvent, getEventById } from "apis";
import * as event_actions from "actions/event";
import { validateLiveSessionData } from "./LiveSession/validateData";
import { routesPath } from "app/components/Router";

import Button from "widgets/Button";
import Toast from "widgets/Toast";
const toast = new Toast();
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronRight
} from "@fortawesome/free-solid-svg-icons";

import BasicInfoEvents from "./LiveSession/BasicInfo";
import EventInfo from "./LiveSession/EventInfo";
import Resources from "./LiveSession/Resources";
import SpeakerInfo from "./LiveSession/SpeakerInfo";
import { prepareLiveSessionDataToSubmit, prepareLiveSessionDataToRender } from "./LiveSession/prepareData";

const CreateEvent = ({ match, history, event, eventActions }) => {
	const [showLoader, setShowLoader] = useState(true);
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [previewTabIndex, setPreviewTabIndex] = useState(null);
	const [previewButtonText, setPreviewButtonText] = useState("Generate Preview");
	const [currentlyAwaited, setCurrentlyAwaited] = useState("");

	useEffect(() => {
		if(match.path == routesPath.eventEdit.route){
			fetchEventData();
		} else {
			if(event.id) {
				eventActions.clearEventData();
			}
			else {
				if(event.basicInfo.startDate.value) {
					eventActions.setEventData({
						startDate: {
							value: moment(event.basicInfo.startDate.value),
							hasError: event.basicInfo.startDate.hasError
						},
						endDate: {
							value: moment(event.basicInfo.endDate.value),
							hasError: event.basicInfo.endDate.hasError
						}
					}, "BasicInfo");
				}
			}
			setShowLoader(false);
		}
	}, []);

	useEffect(() => {
		if(previewTabIndex == null) {
			setTimeout(() => {
				refreshIframe();
			}, 5000);
		} else {
			refreshIframe();
		}
	}, [previewTabIndex])

	const setTab = (value) => {
		setActiveTabIndex(value);
	};
	const handlePreviewTabClick = (value) => {
		setPreviewTabIndex(value);
	};

	const submitEventData = async (status) => {
		setCurrentlyAwaited(status);
		let eventDataClone = _.cloneDeep(event);
		let obj = validateLiveSessionData(eventDataClone, status);
		eventDataClone = _.cloneDeep(obj.eventData)

		if (!obj.hasError) {
			let temp, response;
			temp = prepareLiveSessionDataToSubmit(eventDataClone, status);

			if(eventDataClone.id) {
				response = await editEvent(temp, eventDataClone.id);
			} else {
				response = await createEvent(temp);
			}

			if(response.status === 200 || response.status === 201) {
				if(status === "PUBLISHED") {
					eventActions.clearEventData();
					history.push(routesPath.eventList.route);
					toast.success("Event has been published successfully!");
				} else {
					eventActions.setEventData({
						id: response.data.id
					})
					toast.success("Event has been saved as Draft successfully!");
				}
			} else {
				toast.error("Something went wrong! Please try again.");
			}
		} else {
			toast.error("Please check all tabs for missing fields");
			eventActions.setEventData(eventDataClone);
		}
		setCurrentlyAwaited("");
	}

	const fetchEventData = async () => {
		const response = await getEventById(match.params.eventId);
		const { data } = response;
		const temp = prepareLiveSessionDataToRender(match.params.eventId, data);
		eventActions.setEventData(temp);
		setShowLoader(false);
	}

	const tab = (index, text) => {
		return (
			<div className={cx(activeTabIndex == index ? styles.active: styles.inactive)} onClick={() => setTab(index)}>{text}</div>
		)
	}
	function refreshIframe() {

		let iframeWin;
		if(previewTabIndex === 0 || previewTabIndex === null) {
			iframeWin = document.getElementById("short-card-preview").contentWindow;
		} else if(previewTabIndex === 1) {
			iframeWin = document.getElementById("long-card-preview").contentWindow;
		} else {
			iframeWin = document.getElementById("hero-preview").contentWindow;
		}
		let previewData = {}, speakerArray = [];
		event.speakerInfo.forEach((speaker) => {
			if(previewTabIndex <= 1) {
				speakerArray.push(speaker.name.value || 'Enter Speaker name');
				previewData = {
					"type": "LIVE SESSION",
					"title": event.basicInfo.title.value || 'Enter event title',
					"date": {"start": moment(event.basicInfo.startDate.value).toString(), "end": moment(event.basicInfo.endDate.value).toString()},
					"assets": {"bannerImage": event.basicInfo.bannerImage.value || '',
					"thumbnailImage": event.basicInfo.thumbnailImage.value || ''},
					"eventCompletionData":{
					   "recordingURL":"",
					   "recordingDuration":0
					},
					"speakers": speakerArray,
					"hasRegistered": true,
					"ratingSubmitted": false,
					"hasBookmarked": false,
					"status": "REGISTERED"
				}
			} else {
				speakerArray.push({
					name: speaker.name.value || 'Enter Speaker name',
					topic: speaker.topic.value || 'Sample topic',
					imageURL: speaker.imageURL.value || '',
					designation: speaker.designation.value || 'Designation',
					company: speaker.company.value || 'Company'
				});
				previewData = {
					"type":"LIVE SESSION",
					"title":event.basicInfo.title.value,
					"date": {"start": moment(event.basicInfo.startDate.value).toString(), "end": moment(event.basicInfo.endDate.value).toString()},
					"assets":{
					   "bannerImage":event.basicInfo.bannerImage.value || '',
					   "thumbnailImage": event.basicInfo.thumbnailImage.value || ''
					},
					"tabSpeakers":{
						"speakersList": speakerArray
					},
					"eventCompletionData":{
					   "recordingURL":"",
					   "recordingDuration":0
					},
					"status":"REGISTERED",
					"hasRegistered":true,
					"ratingSubmitted":false,
					"hasBookmarked":true
				 }
			}
		});
		iframeWin.postMessage(previewData, "*");
		setPreviewButtonText("Refresh");
	}

	const renderTabs = () => {
		return (
			<>
				{ tab(0, "Basic info") }
				{ tab(1, "Details") }
				{ tab(2, "Speaker info") }
				{ tab(3, "Resources") }
			</>
		);
	}
	return (
		<>
			<div className={parentStyles.secondaryHeaderOuter}>
				<div className={parentStyles.secondaryHeaderInner}>
					<div className={parentStyles.title}>
						{ showLoader
							? <span>&emsp;Webinars&emsp;</span>
							: <span>&emsp;Webinars&emsp;
							<FontAwesomeIcon icon={faChevronRight} size="xs" /> &emsp;{match.path == routesPath.eventEdit.route? "Edit" : "Create"}&emsp;
								<FontAwesomeIcon icon={faChevronRight} size="xs" />
								&emsp;Live Session&emsp;</span>
						}
					</div>
					{ !showLoader &&
						<div className={parentStyles.buttons}>
							{ event.status === "DRAFT" && (
								<Button
									className={parentStyles.whiteButton}
									onClick={() => eventActions.clearEventData()}
								>
									Clear all data
								</Button>
							)}
							<Button
								className={cx(parentStyles.whiteButton, parentStyles.greenOnHover)}
								isLoading={currentlyAwaited === "PUBLISHED"}
								disabled={currentlyAwaited === "DRAFT"}
								onClick={() => submitEventData("PUBLISHED")}
							>
								Publish
							</Button>
							{ event.status === "DRAFT" && (
								<Button
									className={parentStyles.blueButton}
									isLoading={currentlyAwaited === "DRAFT"}
									disabled={currentlyAwaited === "PUBLISHED"}
									onClick={() => submitEventData("DRAFT")}
								>
									Save as draft
								</Button>
							)}
						</div>
					}
				</div>
			</div>
			<div className={parentStyles.bodyOuter}>
				<div className={parentStyles.bodyInner}>
					{ showLoader ? <SpinnerWithText text={"Fetching event details..."} /> :
						<>
							<div className={styles.tabsContainer}>
								{ renderTabs() }
							</div>
							<div className={styles.form}>
								<div className={styles.top}>
									<div style={{display: activeTabIndex == 0 ? "block" : "none"}}>
										<BasicInfoEvents />
									</div>
									<div style={{display: activeTabIndex == 1 ? "block" : "none"}}>
										<EventInfo />
									</div>
									<div style={{display: activeTabIndex == 2 ? "block" : "none"}}>
										<SpeakerInfo />
									</div>
									<div style={{display: activeTabIndex == 3 ? "block" : "none"}}>
										<Resources />
									</div>
								</div>
								<div className={styles.bottom}>
									<div className={styles.previewContainer}>
										<div className={styles.header}>
											<span className={styles.title}>Preview</span>
											<div className={styles.refreshButton} onClick={refreshIframe}>{previewButtonText}</div>
										</div>
										<div className={styles.iFrameWrapper}>
											<iframe style={{display: (previewTabIndex < 1) ? "block" : "none"}} src={`${constants.careerCentreURL}/webinar-previews/card`} sandbox="allow-scripts allow-same-origin allow-forms allow-popups" id="short-card-preview"></iframe>
											<iframe style={{display: previewTabIndex == 1 ? "block" : "none"}} src={`${constants.careerCentreURL}/webinar-previews/long-card`} sandbox="allow-scripts allow-same-origin allow-forms allow-popups" id="long-card-preview"></iframe>
											<iframe style={{display: previewTabIndex == 2 ? "block" : "none"}} src={`${constants.careerCentreURL}/webinar-previews/hero`} sandbox="allow-scripts allow-same-origin allow-forms allow-popups" id="hero-preview"></iframe>
										</div>
										<div className={styles.footer}>
											<div className={cx(previewTabIndex < 1 ? styles.active: styles.inactive)} onClick={() => handlePreviewTabClick(0)}>Short Card</div>
											<div className={cx(previewTabIndex == 1 ? styles.active: styles.inactive)} onClick={() => handlePreviewTabClick(1)}>Long Card</div>
											<div className={cx(previewTabIndex == 2 ? styles.active: styles.inactive)} onClick={() => handlePreviewTabClick(2)}>Webinar Details Hero</div>
										</div>
									</div>
								</div>
							</div>
						</>
					}
				</div>
			</div>
		</>
	);
};



CreateEvent.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	event: PropTypes.object.isRequired,
	eventActions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		event: state.event,
	};
}


function mapDispatchToProps(dispatch) {
	return {
		eventActions: bindActionCreators(event_actions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);

