import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./index.scss";

const input = function(props, ref) {
	const {
		name,
		className,
		labelClassName,
		inputLabelClassName,
		unitsLabelClassName,
		inputClassName,
		label,
		inputLabel,
		unitsLabel,
		readOnly,
		error,
		helperText
	} = props;

	const handleOnBlur = event => {
		let { value } = event.target;
		const { onChange, onBlur, removeTrailingDecimal } = props;
		if (removeTrailingDecimal) {
			if (value.charAt(value.length - 1) === ".") {
				value = value.substring(0, value.length - 1);
				onChange && onChange({ value, name });
			}
		}
		onBlur &&
			onBlur({
				name,
				value
			});
	};

	const handleOnChange = event => {
		const { value } = event.target;
		const { onChange, regex } = props;
		if (value === "") onChange && onChange({ value, name });
		else {
			if (regex) {
				let regEx = new RegExp(regex);
				if (regEx.test(value)) {
					onChange && onChange({ value, name });
				}
			} else {
				onChange && onChange({ value, name });
			}
		}
	};

	const handleFocus = event => {
		const { onFocus, value } = props;

		// To fix the issue with cursor at beginning
		if (value) {
			event.target.value = "";
			event.target.value = value;
		}

		onFocus &&
			onFocus({
				name,
				value
			});
	};

	const handleKeyDown = event => {
		const { key, keyCode } = event;
		const { onKeyDown } = props;
		onKeyDown &&
			onKeyDown({
				name,
				key,
				keyCode
			});
	};

	const _className = cx(styles.container, className);

	const _inputClassName = cx(inputClassName, styles.input, {
		[styles.readonly]: readOnly,
		[styles.hasError]: error
	});

	const _labelClassName = cx(styles.label, labelClassName);
	const _unitsLabelClassName = cx(
		styles.label,
		styles.unitsLabel,
		unitsLabelClassName
	);
	const _inputLabelClassName = cx(
		styles.label,
		styles.inputLabel,
		inputLabelClassName
	);

	const _helperTextClassName = styles.helperText;

	let _props = {
		name,
		readOnly,
		className: _inputClassName,
		onChange: handleOnChange,
		onFocus: handleFocus,
		onBlur: handleOnBlur,
		onKeyDown: handleKeyDown,
		type: props.type,
		value: props.value,
		autoFocus: props.autoFocus,
		placeholder: props.placeholder,
		disabled: props.disabled
	};

	return (
		<div className={_className}>
			{label ? <label className={_labelClassName}>{label}</label> : null}
			<div className={styles.flexContainer}>
				{inputLabel ? (
					<label className={_inputLabelClassName}>{inputLabel}</label>
				) : null}
				<input {..._props} ref={ref} />
				{unitsLabel ? (
					<label className={_unitsLabelClassName}>{unitsLabel}</label>
				) : null}
			</div>
			{helperText && helperText.length ? (
				<label className={_helperTextClassName}>{helperText}</label>
			) : null}
		</div>
	);
};

input.propTypes = {
	name: PropTypes.string,
	type: PropTypes.oneOf(["text", "password", "date"]),
	label: PropTypes.string,
	inputLabel: PropTypes.string,
	unitsLabel: PropTypes.string,
	placeholder: PropTypes.string,
	readOnly: PropTypes.bool,
	autoFocus: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onKeyDown: PropTypes.func,
	/* Will be applied to container */
	className: PropTypes.string,
	/* Will be applied to underlying input/textarea tag */
	inputClassName: PropTypes.string,
	/* Will be applied to label */
	labelClassName: PropTypes.string,
	inputLabelClassName: PropTypes.string,
	unitsLabelClassName: PropTypes.string,
	/* Value */
	value: PropTypes.string,
	error: PropTypes.bool,
	helperText: PropTypes.string,
	disabled: PropTypes.bool,
	regex: PropTypes.string,
	removeTrailingDecimal: PropTypes.bool
};

input.defaultProps = {
	className: "",
	inputClassName: "",
	labelClassName: "",
	type: "text",
	label: "",
	placeholder: "",
	readOnly: false
};

const inputWrapper = React.forwardRef((props, ref) => input(props, ref));
inputWrapper.displayName = "InputWithUnits";
export default inputWrapper;
