import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./index.scss";

const Tag = ({ color, className, children }) => {
	return (
		<div className={cx(styles.box, styles[color], className)}>
			{children}
		</div>
	);
};

Tag.propTypes = {
	color: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.node.isRequired
};

export default Tag;
