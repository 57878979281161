/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tooltip from "widgets/Tooltip";
import { Link, NavLink } from "react-router-dom";
import cx from "classnames";
import Tag from "shared/Tag";
import Table from "widgets/Table";
import Select from "widgets/Select";
import Input from "widgets/Input";
import styles from "./index.scss";
import { getJobs, getAllCohorts } from "apis";
import { constants } from "utils";
import { routesPath } from "app/components/Router";
// future use
// import { getJobs, getAllCohorts, exportResource } from "apis";
// import { constants, csvCreator } from "utils";
import Button from "widgets/Button";

const STATUS_COLORS = {
	CLOSED: "black",
	DRAFT: "yellow",
	PUBLISHED: "green",
	UNDER_REVIEW: "blue",
	REJECTED: "red",
};

const PAGE_SIZE = 20;

const JOB_STATUS_SELECT_OPTIONS_FOR_INTERNAL = [
	{
		label: "All Jobs",
		value: "all",
	},
	{
		label: "Published",
		value: "published",
	},
	{
		label: "Draft",
		value: "draft",
	},
	{
		label: "Closed",
		value: "closed",
	},
];

const JOB_STATUS_SELECT_OPTIONS_FOR_EXTERNAL = [
	{
		label: "All Jobs",
		value: "all",
	},
	{
		label: "Under review",
		value: "under_review",
	},
	{
		label: "Rejected",
		value: "rejected",
	},
	{
		label: "Published",
		value: "published",
	},
	{
		label: "Draft",
		value: "draft",
	},
	{
		label: "Closed",
		value: "closed",
	},
];

const CREATED_BY_SELECT_OPTIONS_FOR_INTERNAL = [
	{
		label: "All",
		value: "all",
	},
	{
		label: "Internal",
		value: "upgrad",
	},
	{
		label: "External",
		value: "recruiters",
	},
];

const CREATED_BY_SELECT_OPTIONS_FOR_EXTERNAL = [
	{
		label: "All",
		value: "all",
	},
	{
		label: "Me",
		value: "me",
	},
];

const dateUtil = (date) => {
	const temp = new Date(date).toDateString().split(" ");
	const newDate = `${temp[2]}th ${temp[1]} , ${temp[3]}`;
	return newDate;
};

const withToolTip = (string) => {
	if (string && string.length > 16) {
		return (
			<Tooltip placement="topLeft" title={string}>
				{string}
			</Tooltip>
		);
	}
	return string;
};

const cohortToolTip = (arr) => {
	const title = arr.map((cohort, i) => (
		<li key={i}>
			{cohort.cohortId}&nbsp;-&nbsp;{cohort.cohortName}
		</li>
	));
	if (arr.length === 0) {
		return "-";
	}
	return (
		<Tooltip placement="topLeft" title={title}>
			{arr.map((cohort) => cohort.cohortId).join(", ")}
		</Tooltip>
	);
};

const commonColumns = [
	{
		title: "Sr.No.",
		dataIndex: "sno",
		// eslint-disable-next-line
		render: (sno) => <span>{sno}</span>,
	},
	{
		title: "Company Name",
		dataIndex: "companyName",
		// eslint-disable-next-line
		render: (cname) => <span>{withToolTip(cname)}</span>,
	},
	{
		title: "Job Title",
		dataIndex: "title",
		className: cx(styles["fit-width"]),
		// eslint-disable-next-line
		render: (title) => <span>{withToolTip(title)}</span>,
	},
	{
		title: "Status",
		dataIndex: "state",
		// eslint-disable-next-line
		render: (state) => (
			<Tag color={STATUS_COLORS[state]}>{state.split("_").join(" ")}</Tag>
		),
	},
	{
		title: "Location",
		dataIndex: "location",
		className: cx(styles["fit-width"]),
		// eslint-disable-next-line
		render: (loc) => <span>{withToolTip(loc)}</span>,
	},
	{
		title: "Due Date",
		dataIndex: "dueDate",
		// eslint-disable-next-line
		render: (date) => <span>{dateUtil(date)}</span>,
	},
];

const label1 = styles.labelOnlyAdditionalProps;

const JobOpening = (props) => {
	const [dataSource, setDataSource] = useState([]);

	const [filters, setFilters] = useState({
		cohorts: [],
		jobStatusForInternal: "Published",
		jobStatusForExternal: "Under review",
		createdByForInternal: "Internal",
		createdByForExternal: "Me",
		searchText: "",
	});
	const [cohortOptions, setCohortOptions] = useState([]);
	const [tableProps, setTableProps] = useState({
		pagination: { total: 0, current: 1, pageSize: PAGE_SIZE },
		loading: true,
	});

	useEffect(() => {
		let queryObject = constants.PARSE_QUERY_STRING();
		if (queryObject && queryObject.company) {
			setFilters({
				...filters,
				searchText: queryObject.company,
				jobStatusForInternal: queryObject.jobStatus,
			});
			fetchJobs({
				pageNo: tableProps.pagination.current,
				searchText: queryObject.company,
				strictMatch: true,
				jobStatus: JOB_STATUS_SELECT_OPTIONS_FOR_INTERNAL.find(
					(o) => o.label === queryObject.jobStatus
				).value,
			});
			constants.cleanUrl();
		} else {
			fetchJobs({ pageNo: tableProps.pagination.current });
		}
		if (!isExternal()) {
			fetchCohorts();
		}
	}, []);

	const columnsForInternalUser = [
		...commonColumns,
		{
			title: "Cohort",
			dataIndex: "cohorts",
			className: cx(styles["w-160"]),
			// eslint-disable-next-line
			render: (cohorts) => <span>{cohortToolTip(cohorts)}</span>,
		},
		{
			title: "Posted By",
			dataIndex: "postedBy",
			className: cx(styles["w-160"]),
			// eslint-disable-next-line
			render: (postedBy) => <span>{withToolTip(postedBy)}</span>,
		},
		{
			title: "Posting company",
			dataIndex: "clientName",
			// eslint-disable-next-line
			render: (clientName) => <span>{clientName}</span>,
		},
		{
			title: "Appl. Count",
			dataIndex: "noOfApplications",
			// eslint-disable-next-line
			render: (no) => <span>{no}</span>,
		},
		{
			title: "Action",
			fixed: "right",
			className: cx({
				[styles["fixed-column"]]: dataSource.length === 0,
			}),
			// eslint-disable-next-line
			render: (record) => {
				let linkToRedirect = routesPath.jobEdit.route.replace(
					":jobID",
					record.key
				);
				if (
					!props.userInfo.roles.includes("ROLE_RECRUITER") &&
					record.clientName !== "UpGrad"
				) {
					if (
						["PUBLISHED", "DRAFT", "REJECTED"].includes(
							record.state
						)
					)
						linkToRedirect = routesPath.jobView.route.replace(
							":jobID",
							record.key
						);
					else if (record.state === "UNDER_REVIEW")
						linkToRedirect = routesPath.jobReview.route.replace(
							":jobID",
							record.key
						);
				}
				return (
					<span>
						<Link to={linkToRedirect}>
							<Tooltip placement="top" title={"Edit Job"}>
								<button className={styles.actionBtn}>
									<div className="btnText" />
								</button>
							</Tooltip>
						</Link>
						<Link
							to={routesPath.jobApplications.route.replace(
								":jobID",
								record.key
							)}
						>
							<Tooltip
								placement="top"
								title={"View Applications"}
							>
								<button className={styles.actionBtnSecond}>
									<div className="btnText" />
								</button>
							</Tooltip>
						</Link>
					</span>
				);
			},
		},
	];

	const columnsForExternalUser = [
		...commonColumns,
		{
			title: "Posted By",
			dataIndex: "postedBy",
			className: cx(styles["w-160"]),
			// eslint-disable-next-line
			render: (postedBy) => <span>{withToolTip(postedBy)}</span>,
		},
		{
			title: "Appl. Count",
			dataIndex: "noOfApplications",
			// eslint-disable-next-line
			render: (no) => <span>{no}</span>,
		},
		{
			title: "Action",
			fixed: "right",
			className: cx({
				[styles["fixed-column"]]: dataSource.length === 0,
			}),
			// eslint-disable-next-line
			render: (record) => (
				<span>
					<Link
						to={
							record.postedBy === props.userInfo.email
								? routesPath.jobEdit.route.replace(
										":jobID",
										record.key
								  )
								: ""
						}
						className={cx({
							[styles.disabledActionBtn]:
								record.postedBy !== props.userInfo.email,
						})}
					>
						<button className={cx(styles.actionBtn)}></button>
					</Link>
					<Link
						to={routesPath.jobApplications.route.replace(
							":jobID",
							record.key
						)}
						className={cx({
							[styles.disabledActionBtn]:
								record.postedBy !== props.userInfo.email,
						})}
					>
						<button className={styles.actionBtnSecond}></button>
					</Link>
				</span>
			),
		},
	];

	const fetchJobs = async ({
		pageNo,
		searchText,
		strictMatch,
		jobStatus,
	}) => {
		if (document.getElementById("top")) {
			document
				.getElementById("top")
				.scrollIntoView({ behavior: "smooth" });
		}
		try {
			let cohortId = "";
			if (filters.cohorts.length) {
				cohortId = cohortOptions
					.filter((cohort) => filters.cohorts.includes(cohort.label))
					.map((cohort) => cohort.value)
					.join(",");
			}
			let currentJobStatus = isExternal()
				? JOB_STATUS_SELECT_OPTIONS_FOR_EXTERNAL.filter(
						(option) =>
							option.label === filters["jobStatusForExternal"]
				  )[0].value
				: JOB_STATUS_SELECT_OPTIONS_FOR_INTERNAL.filter(
						(option) =>
							option.label === filters["jobStatusForInternal"]
				  )[0].value;

			let createdByFilter = isExternal()
				? CREATED_BY_SELECT_OPTIONS_FOR_EXTERNAL.filter(
						(option) =>
							option.label === filters["createdByForExternal"]
				  )[0].value
				: CREATED_BY_SELECT_OPTIONS_FOR_INTERNAL.filter(
						(option) =>
							option.label === filters["createdByForInternal"]
				  )[0].value;

			const params = {
				pageNo,
				pageSize: tableProps.pagination.pageSize,
				jobStatus: jobStatus ? jobStatus : currentJobStatus,
				cohortId,
				searchText: searchText ? searchText : filters.searchText,
				createdBy:
					createdByFilter === "me"
						? props.userInfo.id
						: createdByFilter,
			};
			if (strictMatch) params.strictMatch = true;

			let response = await getJobs(params);

			if (response.status == 200) {
				let { jobs } = response.data.result;
				jobs = jobs.map((elem, i) => {
					const serialNum = {
						sno: i + 1 + (pageNo - 1) * PAGE_SIZE,
					};
					const uniqueKey = {
						key: elem.id,
					};
					return { ...serialNum, ...uniqueKey, ...elem };
				});

				setDataSource(jobs);
				let pagination = { ...tableProps.pagination };
				pagination.total = parseInt(response.headers.count);
				pagination.current = pageNo;
				setTableProps({
					pagination,
					loading: false,
				});
			} else {
				// write error toast code for no response from API
			}
		} catch (e) {
			// error
		}
	};

	const fetchCohorts = async () => {
		try {
			let response = await getAllCohorts();

			if (response) {
				const { cohorts } = response.data;
				const cohortOptions = cohorts.map((cohort) => ({
					label: cohort.name.trim(),
					value: cohort.id,
				}));
				setCohortOptions(cohortOptions);
			} else {
				// write error toast code for no response from API
			}
		} catch (e) {
			// error
		}
	};

	const handleTableChange = (pagination) => {
		const pager = { ...tableProps.pagination };
		pager.current = pagination.current;
		setTableProps({
			pagination: pager,
			loading: true,
		});
		fetchJobs({ pageNo: pagination.current });
	};

	const handleChange = ({ name, value }) => {
		setFilters({ ...filters, [name]: value });
	};

	// future use
	// const handleExtract = () => {
	// 	(async () => {
	// 		try {
	// 			const params = {
	// 				resource: "job"
	// 			};
	// 			let response = await exportResource(params);
	// 			if (response.status == 200) {
	// 				csvCreator(response.data, "ALL_JOBS");
	// 			}
	// 		} catch (e) {
	// 			// error
	// 		}
	// 	})();
	// };

	const handleApply = () => {
		setTableProps({
			...tableProps,
			loading: true,
		});
		fetchJobs({ pageNo: 1 });
	};

	const isExternal = () => {
		return props.userInfo.roles.includes("ROLE_RECRUITER");
	};

	const renderFiltersForExternalUser = () => {
		return (
			<>
				<div className={styles.filtersRow}>
					<div className={styles.col25}>
						<Select
							mode="default"
							name="jobStatusForExternal"
							label="status"
							value={filters.jobStatusForExternal}
							onChange={handleChange}
							defaultValue={
								JOB_STATUS_SELECT_OPTIONS_FOR_EXTERNAL[1].label
							}
							options={JOB_STATUS_SELECT_OPTIONS_FOR_EXTERNAL}
						/>
					</div>
					<div className={styles.col25}>
						<Select
							mode="default"
							name="createdByForExternal"
							label="created by"
							value={filters.createdByForExternal}
							onChange={handleChange}
							defaultValue={
								CREATED_BY_SELECT_OPTIONS_FOR_EXTERNAL[1].label
							}
							options={CREATED_BY_SELECT_OPTIONS_FOR_EXTERNAL}
						/>
					</div>
					<div className={styles.flexGrowDiv}>
						<Input
							type="text"
							name="searchText"
							label="search by"
							placeholder="Company name, job title or location"
							labelClassName={label1}
							inputClassName={styles.searchInput}
							value={filters.searchText}
							onChange={handleChange}
						/>
					</div>
					<div className={styles.applyButtonDiv}>
						<Button
							className={styles.whiteButton}
							onClick={handleApply}
						>
							Apply filters
						</Button>
					</div>
				</div>
			</>
		);
	};

	const renderFiltersForInternalUser = () => {
		return (
			<>
				<div className={styles.filtersRow}>
					<div className={styles.col25}>
						<Select
							mode="default"
							name="jobStatusForInternal"
							label="status"
							value={filters.jobStatusForInternal}
							onChange={handleChange}
							defaultValue={
								JOB_STATUS_SELECT_OPTIONS_FOR_INTERNAL[1].label
							}
							options={JOB_STATUS_SELECT_OPTIONS_FOR_INTERNAL}
						/>
					</div>
					<div className={styles.col25}>
						<Select
							mode="default"
							name="createdByForInternal"
							label="created by"
							value={filters.createdByForInternal}
							onChange={handleChange}
							defaultValue={
								CREATED_BY_SELECT_OPTIONS_FOR_INTERNAL[1].label
							}
							options={CREATED_BY_SELECT_OPTIONS_FOR_INTERNAL}
						/>
					</div>
					<div className={styles.col50}>
						<Input
							type="text"
							label="search by"
							name="searchText"
							placeholder="Company name, job title or location"
							labelClassName={label1}
							inputClassName={styles.searchInput}
							value={filters.searchText}
							onChange={handleChange}
						/>
					</div>
				</div>
				<div className={styles.filtersRow}>
					<div className={styles.cohortFilterDiv}>
						<Select
							mode="multiple"
							label="Cohorts"
							placeholder="Select cohorts to filter data"
							name="cohorts"
							allowClear={true}
							autoClearSearchValue={false}
							value={filters.cohorts}
							onChange={handleChange}
							options={cohortOptions || {}}
						/>
					</div>
					<div className={styles.applyButtonDiv}>
						<Button
							className={styles.whiteButton}
							onClick={handleApply}
						>
							Apply filters
						</Button>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<div id="top" className={styles.secondaryHeaderOuter}>
				<div className={styles.secondaryHeaderInner}>
					<div className={styles.navList}>
						<NavLink
							exact
							to={routesPath.jobOpenings.route}
							className={styles.navItem}
							activeClassName={styles.active}
						>
							All jobs
						</NavLink>
						{!isExternal() && (
							<NavLink
								exact
								to={routesPath.jobOpeningsReview.route}
								className={styles.navItem}
								activeClassName={styles.active}
							>
								Jobs for review
							</NavLink>
						)}
						<Link
							to={routesPath.jobCreate.route}
							className={styles.createJobButton}
						>
							<button className={styles.cbtn}>
								Create Job Opening
							</button>
						</Link>
					</div>
				</div>
			</div>
			<div className={styles.bodyOuter}>
				<div className={styles.bodyInner}>
					{isExternal()
						? renderFiltersForExternalUser()
						: renderFiltersForInternalUser()}
					<div className={styles.tableContainer}>
						<Table
							className={styles.jobOpeningsTable}
							columns={
								isExternal()
									? columnsForExternalUser
									: columnsForInternalUser
							}
							dataSource={dataSource}
							pagination={tableProps.pagination}
							loading={tableProps.loading}
							onChange={handleTableChange}
							scroll={{ x: true }}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

JobOpening.propTypes = {
	userInfo: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		userInfo: state.userInfo,
	};
}

export default connect(mapStateToProps, null)(JobOpening);
