import React, { useState, useEffect } from "react";
import styles from "./index.scss";
import PropTypes from "prop-types";
import cx from "classnames";
import AutoComplete from "./AutoComplete";
import ReactDependentScript from "react-dependent-script";
import constants from "constants";
import Badge from "shared/Badge";
const GOOGLE_SCRIPT_LINK = constants.appPaths.googleScriptLink;

const LocationSelect = props => {
	const [badgeItems, setBadgeItems] = useState(props.badgeItems || []);

	useEffect(() => {
		if (props.badgeItems) setBadgeItems(props.badgeItems);
	}, [props.badgeItems]);

	const callOnChange = items => {
		const { name, onChange } = props;
		onChange && onChange({ name, value: items });
	};

	const handleAutoComplete = (name, place) => {
		if (place.address_components) {
			const city = place.address_components[0].long_name;
			const temp = Array.from(new Set([...badgeItems, city]));
			setBadgeItems(temp);
			callOnChange(temp);
		}
	};

	const onRemove = i => {
		const [...badgeItemsTemp] = badgeItems;
		badgeItemsTemp.splice(i, 1);
		setBadgeItems(badgeItemsTemp);
		callOnChange(badgeItemsTemp);
	};

	const { label, labelClassName, className, name, error, disabled,badgeClass} = props;
	const _labelClassName = cx(styles.label, labelClassName);
	const _className = cx(styles.container, className);
	const _classNameBage = cx(styles.badgeCustom, badgeClass);

	return (
		<div className={_className}>
			{label ? <label className={_labelClassName}>{label}</label> : null}
			<ReactDependentScript
				loadingComponent={
					<span>
						Google API is loading, until then you can eat 🍕
					</span>
				}
				scripts={[GOOGLE_SCRIPT_LINK]}
			>
				<AutoComplete
					name={name}
					onChange={handleAutoComplete}
					error={error}
					disabled={disabled}
				/>
			</ReactDependentScript>
			<Badge
				badgeArr={badgeItems}
				onDismiss={onRemove}
				showDismiss={!disabled}
				customClass={badgeClass ? _classNameBage : ''}
			/>
		</div>
	);
};

LocationSelect.propTypes = {
	name: PropTypes.string,
	badgeItems: PropTypes.array,
	label: PropTypes.string,
	onChange: PropTypes.func,
	labelClassName: PropTypes.string,
	className: PropTypes.string,
	error: PropTypes.bool,
	disabled: PropTypes.bool,
	badgeClass:PropTypes.string
};

export default LocationSelect;
