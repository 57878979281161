import constants from "constants";
import createInstanceWithSessionDetails from "./helper";

const eventsApiInstance = createInstanceWithSessionDetails(
	constants.eventsApiServerUrl
);

const URL = {
	createEvent: "/event/",
	editEvent: "/event/:eventId/",
	getAllEvents: "/event/ava/",
	getEventById: "/event/ava/:eventId/",
	eventRegistrations: "/event/ava/:eventId/learners/"
};

export const createEvent = async function (data) {
	try {
		const url = URL.createEvent;
		const response = await eventsApiInstance.post(url, data);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const editEvent = async function (data, eventId) {
	try {
		const url = URL.editEvent.replace(":eventId", eventId);
		const response = await eventsApiInstance.put(url, data);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const getAllEvents = async function ({
	pageNo,
	pageSize,
	searchText,
	timingStatus,
	publishedStatus
}) {
	try {
		const url = URL.getAllEvents;
		timingStatus = timingStatus.toUpperCase();
		publishedStatus = publishedStatus.toUpperCase();
		let params = {};
		let headers = {
			page_no: pageNo,
			page_size: pageSize,
		};

		if (timingStatus != "ALL") {
			let currentTime = new Date().toJSON();
			switch (timingStatus) {
				case "PAST":
					params.end_time__lt = currentTime;
					break;
				case "ONGOING":
					params.start_time__lt = currentTime;
					params.end_time__gte = currentTime;
					break;
				case "UPCOMING":
					params.start_time__gt = currentTime;
					params.order = "ASC"
					break;
			}
		}
		if (publishedStatus != "ALL") {
			params.is_published = publishedStatus === "PUBLISHED" ? "true" : "false"
		}
		if (searchText.length) params["search_text"] = searchText;

		const response = await eventsApiInstance.get(url, {
			params,
			headers
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getEventById = async function (eventId) {
	try {
		const url = URL.getEventById.replace(":eventId", eventId);
		const response = await eventsApiInstance.get(
			url
		);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const getEventRegistrations = async function (paginationData, eventId) {
	try {
		let headers = {
			page_no: paginationData.pageNo,
			page_size: paginationData.pageSize,
		};
		const url = URL.eventRegistrations.replace(":eventId", eventId);
		const response = await eventsApiInstance.get(
			url,
			{ headers }
		);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const uploadAttendeesCSV = async function (csvFile, eventId) {
	try {
		let bodyFormData = new FormData();
		bodyFormData.append("file", csvFile);
		const url = URL.eventRegistrations.replace(":eventId", eventId);
		const response = await eventsApiInstance.post(url, bodyFormData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
		return response;
	} catch (error) {
		return error.response;
	}
}

export const extractRegistrations = async function (eventId) {
	try {
		const url = URL.eventRegistrations.replace(":eventId", eventId);

		const response = await eventsApiInstance.get(url, {
			headers: {
				"Accept": "text/csv"
			},
			responseType: "blob",
		});
		return response;
	} catch (error) {
		return error.response;
	}
};
