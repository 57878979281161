class constants {
	PARSE_QUERY_STRING() {
		var i, obj, pair, query, vars;
		query = window.location.search.substring(1);
		if (query) {
			vars = query.split('&');
			i = 0;
			obj = {};
			while (i < vars.length) {
				pair = vars[i].split('=');
				obj[pair[0]] = unescape(pair[1]);
				i++;
			}
			return obj;
		}
	}
	setUrlVar(key, val) {
		var obj, queryString;
		queryString = window.location.search || "?";
		if (typeof val === 'undefined' || val === '' || val.length === 0) {
			queryString = queryString.replace(new RegExp(key + "=([^&]*)", "i"), "");
		}else if (queryString.indexOf(key) !== -1) {
			queryString = queryString.replace(new RegExp(key + "=([^&]*)", "i"), key + "=" + val);
		}else {
			queryString = queryString === '?' ? "?" + key + "=" + val : queryString.concat("&" + key + "=" + val);
		}
		obj = {};
		obj[key] = val;
		history.replaceState(obj, "", queryString);
	}
	cleanUrl() {
		var clean_uri = location.protocol + "//" + location.host + location.pathname;
		window.history.replaceState({}, document.title, clean_uri);
	}
}

export default new constants()
