import actionTypes from "actionTypes";

export const updateLayoutProps = function(payload) {
	return {
		type: actionTypes.UPDATE_LAYOUT_PROPS,
		payload
	};
};

export default {
	updateLayoutProps
};
