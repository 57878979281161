import actionTypes from "actionTypes";

const initialState = {
	id: null,
	status: "DRAFT",
	type: "LIVE_SESSION",
	basicInfo: {
		bannerImage: { value: "" },
		thumbnailImage: { value: "" },
		title: { value: "" },
		startDate: { value: "" },
		endDate: { value: "" },
		category: { value: "" },
		joinURL: { value: "" },
		registrationTypeformID: { value: "" },
		feedbackTypeformID: { value: "" }
	},
	eventInfo: {
		description: { value: "" }
	},
	speakerInfo: [{
		imageURL: { value: "" },
		name: { value: "" },
		linkedinURL: { value: "" },
		company: { value: "" },
		designation: { value: "" },
		topic: { value: "" },
		about: { value: "" }
	}],
	resourceInfo: [{
		title: { value: "" },
		url: { value: "" },
		description: { value: "" }
	}],
};

export default function event(state, action) {
	switch (action.type) {
		case actionTypes.SET_EVENT_BASIC_INFO:
			return {
				...state,
				basicInfo: {
					...state.basicInfo,
					...action.payload
				}
			};
		case actionTypes.SET_EVENT_EVENT_INFO:
				return {
					...state,
					eventInfo: {
						...state.eventInfo,
						...action.payload
					}
				};
		case actionTypes.SET_EVENT_SPEAKER_INFO:
				return {
					...state,
					speakerInfo: action.payload
				};
		case actionTypes.SET_EVENT_RESOURCE_INFO:
				return {
					...state,
					resourceInfo: action.payload
				};
		case actionTypes.SET_EVENT_DATA:
				return {
					...state,
					...action.payload
				};
		case actionTypes.CLEAR_EVENT_DATA:
			return {...JSON.parse(JSON.stringify(initialState))};
	}
	return state ? state : JSON.parse(JSON.stringify(initialState));
}
