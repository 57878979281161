import React, { useState, useEffect } from "react";
import Modal from "widgets/Modal";
import { APPLICATION_STATUSES } from "modules/constants.js";
import PropTypes from "prop-types";
import ModalCard from "./ModalCard";
import styles from "./index.scss";
import {
	updateApplicationStatus,
	updateCandidateApplicationStatus,
} from "apis";

const UpdateStatus = (props) => {
	const [status, setStatus] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (
			props.details.status !== undefined &&
			status !== props.details.status
		)
			setStatus(props.details.status);
	}, [props.details.status]);

	const handleClick = (data) => {
		setStatus(data);
	};

	const handleOk = () => {
		setIsLoading(true);
		const { application_IDs } = props.details;
		let tempSelectedKeys = [];
		props.dataSource.map((application) => {
			if (application_IDs.includes(application.id)) {
				tempSelectedKeys.push(application.userId);
			}
		});
		const fetchData = async () => {
			let response = await updateApplicationStatus({
				applications: tempSelectedKeys.join(","),
				status,
			});
			if (response.status === 204) {
				updateCandidateStatus(status);
			} else {
				// write error toast code for no response from API
			}
			setIsLoading(false);
		};
		fetchData();
	};

	/*
	 * To update candidate status
	 */
	const updateCandidateStatus = async (status) => {
		try {
			const { application_IDs } = props.details;
			let data = {
				candidateIds: application_IDs,
				jobApplicationStatus: status,
			};
			let response = await updateCandidateApplicationStatus(
				parseInt(props.jobID),
				data
			);
			if (response.status === 200) {
				if (response.data) {
					const updatedIds = response.data;
					if (
						JSON.stringify(updatedIds) ===
						JSON.stringify(application_IDs)
					) {
						setStatus(props.details.status);
						props.onOk(status);
					}
				}
			}
		} catch (e) {
			//error
		}
	};

	const handleCancel = () => {
		setIsLoading(false);
		setStatus(props.details.status);
		props.onCancel();
	};

	const applicationStatusCards = () => {
		return APPLICATION_STATUSES.map((applicationStatus) => (
			<ModalCard
				key={applicationStatus.value}
				title={applicationStatus.label}
				color="blue"
				status={status}
				onClick={handleClick}
			/>
		));
	};
	return (
		<Modal
			width="60%"
			title="Choose learner application status"
			visible={props.visible}
			closable={false}
			className={styles.rootModal}
			onOk={handleOk}
			onCancel={handleCancel}
			okButtonProps={{ loading: isLoading, disabled: status === "" }}
			maskClosable={false}
		>
			<div className={styles.modalCards}>{applicationStatusCards()}</div>
		</Modal>
	);
};

UpdateStatus.propTypes = {
	details: PropTypes.object.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	visible: PropTypes.bool.isRequired,
	jobID: PropTypes.string.isRequired,
	dataSource: PropTypes.array.isRequired,
};
export default UpdateStatus;
