import actionTypes from "actionTypes";

const initialState = {};

export default function job(state, action) {
	switch (action.type) {
		case actionTypes.SET_JOB_DATA:
			return {
				...state,
				...action.payload
			};
		case actionTypes.CLEAR_JOB_DATA:
			return initialState;
	}
	return state ? state : initialState;
}
