import React from "react";
import PropTypes from "prop-types";
import styles from "./ModalCard.scss";
import classNames from "classnames/bind";
let cx = classNames.bind(styles);
import { Radio } from "antd";

const ModalCard = props => {
	const { title, color, status, onClick } = props;
	const handleClick = () => {
		onClick(title);
	};

	return (
		<div>
			<div className={styles.cardContainer}>
				<div
					className={cx(
						{
							[`${color}Card`]: true
						},
						styles.card
					)}
					onClick={handleClick}
				>
					<Radio
						checked={title === status}
						styles={{ background: "#f59948" }}
					/>

					<span className={styles.item3}>{title}</span>
				</div>
			</div>
		</div>
	);
};

ModalCard.propTypes = {
	title: PropTypes.string.isRequired,
	status: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	color: PropTypes.string.isRequired
};

export default ModalCard;
