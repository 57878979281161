const MINMAXERROR = "Minimum value can not be greater than maximum value";

const validateInputs = (tempFormDetails, tempError, tempHelperText) => {
	checkMinMax(
		"vTotalExpMin",
		"vTotalExpMax",
		"vTotalExp",
		tempFormDetails,
		tempError,
		tempHelperText
	);
	checkMinMax(
		"vRelevantExpMin",
		"vRelevantExpMax",
		"vRelevantExp",
		tempFormDetails,
		tempError,
		tempHelperText
	);
	checkMinMax(
		"vSalaryFrom",
		"vSalaryTo",
		"vSalary",
		tempFormDetails,
		tempError,
		tempHelperText
	);
};

const checkMinMax = (
	minName,
	maxName,
	name,
	tempFormDetails,
	tempError,
	tempHelperText
) => {
	let min = tempFormDetails[minName];
	let max = tempFormDetails[maxName];

	if (min && max && min > max) {
		tempError[minName] = true;
		tempError[maxName] = true;
		tempHelperText[name] = MINMAXERROR;
	}
};

export default validateInputs;
