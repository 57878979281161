import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import PrimaryLogo from 'shared/PrimaryLogo';
import PrimaryNav from 'shared/PrimaryNav';
import ProfileMenu from 'shared/ProfileMenu';
import styles from './index.scss';

const Header = function({ sticky = false }) {
	return (
		<header className={cx(styles.root, { [styles.sticky]: sticky })}>
			<div className={styles.container}>
				<span className={styles.item1}>
					<PrimaryLogo />
				</span>
				<span className={styles.item2}>
					<PrimaryNav />
				</span>
				<span className={styles.item3}>
					<ProfileMenu />
				</span>
			</div>
		</header>
	);
};

Header.propTypes = {
	sticky: PropTypes.bool
};

export default Header;
