import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";
import CKEditor from "ckeditor4-react";

import * as jobActions from "actions/job";
import * as navigationActions from "actions/navigation";
import * as errorActions from "actions/error";
import { routesPath } from "app/components/Router";
import {
	editJob,
	deleteJobAttributes,
	getCompanies,
	reviewJob,
	createJob
} from "apis";
import { ALL_INTERNAL_ROLES, EXTERNAL_ROLES } from "constants/roleMapping";
import { RBAC } from "hoc";

import regexStrings from "constants/regexStrings";
import AddNewCompany from "./AddNewCompany";
import LocationSelect from "shared/LocationSelect";
import InputWithUnits from "shared/InputWithUnits";
import Button from "widgets/Button";
import Icon from "widgets/Icon";
import Input from "widgets/Input";
import Select from "widgets/Select";
import Toast from "widgets/Toast";
import Upload from "widgets/Upload";

import RejectionReasonModal from "./components/RejectionReasonModal/index";

import styles from "../index.scss";
import validateInputs from "./validateInputs";
import { JOB_TYPES, CKEDITOR_CONFIG } from "./constants";
import { INTERNAL_POC } from "../../constants";
const toast = new Toast();

const BasicInfo = (props) => {
	const [formDetails, setFormDetails] = useState(
		props.jobInfo.basicInfo || {}
	);
	const [charCount, setCharCount] = useState(
		props.jobInfo.basicInfo ? props.jobInfo.basicInfo.description.length : 0
	);
	const [error, setError] = useState({});
	const [helperText, setHelperText] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isFetchingCompanies, setIsFetchingCompanies] = useState(false);
	const [isLogoLoading, setIsLogoLoading] = useState(false);
	const [companies, setCompanies] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [isCompaniesSearchEmpty, setIsCompaniesSearchEmpty] = useState(false);
	const [rejectionModalVisible, setRejectionModalVisible] = useState(false);
	const [isPageReadOnly, setIsPageReadOnly] = useState(props.jobInfo.editInfo? ["review", "view"].includes(props.jobInfo.editInfo.mode) : false);

	useEffect(() => {
		fetchCompanies();
	}, []);

	useEffect(() => {
		if (companies.length) {
			const temp = companies.find(
				(company) => company.label === formDetails.companyName
			);
			setFormDetails({
				...formDetails,
				salesPoc: temp.salesPoc,
				url: temp.logoUrl,
			});
		}
	}, [formDetails.companyName]);

	useEffect(() => {
		setIsPageReadOnly(props.jobInfo.editInfo? ["review", "view"].includes(props.jobInfo.editInfo.mode) : false);
	}, [props.jobInfo.editInfo])

	const fetchCompanies = async () => {
		setIsFetchingCompanies(true);
		let response = await getCompanies();
		if (response.status == 200) {
			let { data } = response.data;
			setCompanies(
				data.map((company, index) => ({
					label: company.name,
					value: index,
					salesPoc: company.accountManager,
					logoUrl: company.companyLogo
				}))
			);
			if (formDetails.companyName) {
				const temp = data.find(
					company => company.name === formDetails.companyName
				);
				if (formDetails.url !== temp.companyLogo) {
					setFormDetails({
						...formDetails,
						salesPoc: temp.accountManager,
						url: temp.companyLogo
					});
				} else {
					setFormDetails({
						...formDetails,
						salesPoc: temp.accountManager
					});
				}
			}
		}
		setIsFetchingCompanies(false);
	}

	const handleInputChange = ({ name, value }) => {
		if (props.jobInfo.visibilityInfo) {
			//eslint-disable-next-line
			const { visibility, ...data } = props.jobInfo.basicInfo;
			props.jobActions.setJobData({
				basicInfo: data,
				visibilityInfo: {},
			});
			{
				//eslint-disable-next-line
				const { visibility, ...otherFormDetails } = formDetails;
				setFormDetails({ ...otherFormDetails, [name]: value });
			}
		} else {
			setFormDetails({ ...formDetails, [name]: value });
		}
		if(name === "companyName") {
			setIsCompaniesSearchEmpty(false);
		}
		if(name === "programs") {
			let temp = props.relevantForOptions.filter(option => value.includes(option.label))
			.map(option => option.key);
			setFormDetails({ ...formDetails, programs: value, relevantFor: temp.join(",") });
		}
		if(name === "relevantFor") {
			let temp = props.relevantForOptions.filter(option => value.includes(option.label))
			.map(option => option.key);
			setFormDetails({ ...formDetails, relevantFor: temp.join(",") });
		}
	};

	const saveAndNext = (tempFormDetails) => {
		props.nextIndex({
			index: parseInt(props.index) + 1,
			cohorts: tempFormDetails.cohorts,
			isExternal: props.userInfo.roles.includes("ROLE_RECRUITER")? true : false,
			programs: tempFormDetails.programs
		});
		props.jobActions.setJobData({ basicInfo: tempFormDetails });
	};

	const handleSaveAndNext = async (jobState = "") => {
		let tempFormDetails = { ...formDetails };
		let tempError = {};
		let tempHelperText = {};
		let toBeDeletedOptionalFields;
		let { mode, jobID } = props.jobInfo.editInfo;
		let { basicInfo } = props.jobInfo;
		const selectedCompany = companies.find(
			(company) => company.label === tempFormDetails.companyName
		);

		if(props.userInfo.roles.includes("ROLE_RECRUITER")) {
			tempFormDetails["type"] = "by External Recruiter";
			toBeDeletedOptionalFields = validateInputs(
				"external",
				charCount,
				tempFormDetails,
				tempError,
				tempHelperText
			);
		} else {
			toBeDeletedOptionalFields = validateInputs(
				"internal",
				charCount,
				tempFormDetails,
				tempError,
				tempHelperText
			);
		}



		if (mode === "edit") {
			// if edit mode is true, call DELETE api to delete any attrs that are now empty
			try {
				let tempDelete = [];
				toBeDeletedOptionalFields.forEach((field) => {
					if (basicInfo[field]) {
						tempDelete.push(field);
					}
				});
				if (tempDelete.length) {
					setIsLoading(true);
					await deleteJobAttributes(jobID, tempDelete.join(","));
					setIsLoading(false);
				}
			} catch (e) {
				// error
			}
		}

		toBeDeletedOptionalFields.forEach((field) => {
			delete tempFormDetails[field];
		});

		if (Object.keys(tempError).length) {
			setError(tempError);
			setHelperText(tempHelperText);
			setFormDetails(tempFormDetails);
		} else if (mode === "edit") {
			// if edit mode is true, call PUT api to push changes if any

			let tempEdit = {};
			for (let key in tempFormDetails) {
				if (
					key === "url" &&
					tempFormDetails.url !== selectedCompany.logoUrl
				) {
					tempEdit[key] = tempFormDetails[key];
				} else if (tempFormDetails[key] !== basicInfo[key]) {
					if (key === "cohorts") {
						tempEdit["cohorts"] = props.cohortOptions
							.filter((cohort) =>
								tempFormDetails["cohorts"].includes(
									cohort.label
								)
							)
							.map((cohort) => cohort.value)
							.join(",");
					} else if ( key === "programs") {
						let temp = props.relevantForOptions
							.filter(option =>
								tempFormDetails["programs"].includes(
									option.label
								)
							)
						tempEdit["programs"] = temp.map(option => option.value)
							.join(",");
						tempEdit["relevantFor"] = temp.map(option => option.key).join(",");
					} else {
						tempEdit[key] = tempFormDetails[key];
					}
				}
			}

			if (Object.keys(tempEdit).length) {
				try {
					setIsLoading(true);
					let response = await editJob(tempEdit, jobID, jobState);
					if (response) {
						if(jobState) {
							props.navigationActions.redirect(routesPath.jobOpenings.route);
						} else {
							saveAndNext(tempFormDetails);
						}
					}
				} catch (e) {
					setIsLoading(false);
				}
			} else {
				saveAndNext(tempFormDetails);
			}
		} else {
			// Check if logo url is same as the one received in company API
			// for the selected company,
			// if yes, remove it
			// if no, send it in the job data
			if (tempFormDetails.url === selectedCompany.logoUrl)
				delete tempFormDetails["url"];
			if(jobState) {
				tempFormDetails.programs = props.relevantForOptions
				.filter(option => tempFormDetails.programs.includes(option.label))
				.map(option => option.value)
				.join(",");
				tempFormDetails.cohorts = "";
				let response = await createJob(jobState.toUpperCase(), { basicInfo: tempFormDetails });
				if (response) {
					setIsLoading(false);
					if (response.status === 201 || response.status === 204) {
						props.navigationActions.redirect(routesPath.jobOpenings.route);
					} else if (response.status === 409) {
						props.errorActions.setError(response.data.message);
						setTimeout(() => {
							props.errorActions.clearError();
						}, 10000);
					} else {
						props.errorActions.setError(
							"Sorry some error has occured, please try again."
						);
						setTimeout(() => {
							props.errorActions.clearError();
						}, 10000);
					}
				} else {
					// write error toast code for no response from API
				}
			}
			saveAndNext(tempFormDetails);
		}
	};

	const handleRejectClick = () => {
		setRejectionModalVisible(true);
	};

	const handlePublishClick = async () => {
		let response = await reviewJob({
			state: "PUBLISHED",
			id: props.jobInfo.editInfo.jobID
		});
		if (response.status === 201) {
			props.navigationActions.redirect(routesPath.jobOpeningsReview.route);
		} else if (response.status === 409) {
			setError({
				error: true,
				message: response.data.message
			});
		} else {
			setError({
				error: true,
				message:
					"Sorry, some error has occured. Please try again or check with admin."
			});
		}
	};

	const handleEditorChange = (event) => {
		setFormDetails({
			...formDetails,
			description: event.editor.getData(),
		});
		setCharCount(event.editor.document.getBody().getText().length);
	};

	const label1 = styles.labelOnlyAdditionalProps;
	const label2 = cx(label1, styles.labelBasicProps, styles.labelMB);
	const label3 = cx(
		label1,
		styles.labelBasicProps,
		styles.labelLight,
		styles.labelFlex30
	);

	const _inputWithUnitsProps = {
		placeholder: "00",
		inputClassName: styles.smallInput55,
		inputLabelClassName: label3,
		unitsLabelClassName: styles.unitsLabel,
		onChange: handleInputChange,
		regex: regexStrings.TWO_DIGITS_TWO_DECIMALS,
		removeTrailingDecimal: true,
		readOnly: isPageReadOnly
	};

	const uploadButton = (
		<div>
			<Icon type={isLogoLoading ? "loading" : "plus"} />
			<div className="ant-upload-text">Company logo</div>
		</div>
	);

	const handleOk = async (companyName) => {
		setModalVisible(false);
		await fetchCompanies();
		handleInputChange({ name: "companyName", value: companyName });
		toast.success("New company added!");
	};

	const handleCancel = () => {
		setModalVisible(false);
	};

	const handleRejectionOk = () => {
		setRejectionModalVisible(false);
		props.navigationActions.redirect(routesPath.jobOpeningsReview.route);
	}

	const handleRejectionCancel = () => {
		setRejectionModalVisible(false);
	}

	const showModal = () => {
		setModalVisible(true);
	}

	const handleCompaniesSearch = (searchText) => {
		if (searchText)
			setIsCompaniesSearchEmpty(!companies.some(company => company.label.toLowerCase().includes(searchText.toLowerCase())));
	}

	const renderActionButtons = () => {
		if(props.jobInfo.editInfo && isPageReadOnly) {
			if(props.jobInfo.editInfo.mode === "review") {
				return (
					<RBAC allowedRoles={ALL_INTERNAL_ROLES}>
						<Button
							className={styles.rejectButton}
							onClick={handleRejectClick}
							disabled={isLoading}
							isLoading={isLoading}
						>
							Send back to recruiter
						</Button>
						<Button
							className={styles.saveButton}
							onClick={handlePublishClick}
							disabled={isLoading}
							isLoading={isLoading}
						>
							Publish
						</Button>
						<RejectionReasonModal
							visible={rejectionModalVisible}
							onOk={handleRejectionOk}
							onCancel={handleRejectionCancel}
							currentComment={props.jobInfo.basicInfo && props.jobInfo.basicInfo.comments && props.jobInfo.basicInfo.comments.length && props.jobInfo.basicInfo.comments[0].comment || ""}
							id={props.jobInfo.editInfo.jobID}
						/>
					</RBAC>
				)
			} else {
				return (
					<Link to={routesPath.jobOpenings.route}>
						<Button
							className={styles.saveButton}
						>
							Back to job openings
						</Button>
					</Link>
				)
			}
		} else {
			return (
				<>
					<RBAC allowedRoles={EXTERNAL_ROLES}>
						<Button
							className={styles.draftButton}
							onClick={() => handleSaveAndNext("draft")}
							disabled={isLoading}
							isLoading={isLoading}
						>
							Save as draft
						</Button>
						<Button
							className={styles.saveButton}
							onClick={() => handleSaveAndNext("under_review")}
							disabled={isLoading}
							isLoading={isLoading}
						>
							Send for review
						</Button>
					</RBAC>
					<RBAC allowedRoles={ALL_INTERNAL_ROLES}>
						<Button
							className={styles.saveButton}
							onClick={() => handleSaveAndNext()}
							disabled={isLoading}
							isLoading={isLoading}
						>
							Save and next
						</Button>
					</RBAC>
				</>
			)
		}
	}

	return (
		<div className={styles.pageContainer}>
			<div className={styles.headerDiv}>
				<label className={styles.pageLabel}>
					Basic information&nbsp;
				</label>
			</div>
			<div className={styles.row}>
				<Upload
					onUpload={(url) =>
						handleInputChange({ name: "url", value: url })
					}
					setLoading={setIsLogoLoading}
					options={{
						accept: ".jpg,.jpeg,.png,.svg,.gif",
						showUploadList: false,
						listType: "picture-card",
					}}
					disabled={isPageReadOnly}
				>
					{formDetails.url ? (
						<img
							src={formDetails.url}
							alt="logo"
							style={{ width: "100%" }}
						/>
					) : (
						uploadButton
					)}
				</Upload>
			</div>
			<div className={styles.row}>
				<div className={styles.col50} style={{position: 'relative'}}>
					<Select
						mode="default"
						showSearch={true}
						label="Company name"
						name="companyName"
						placeholder="Select a Company"
						onChange={handleInputChange}
						error={error.companyName || false}
						options={companies}
						defaultValue={formDetails.companyName}
						value={formDetails.companyName}
						loading={isFetchingCompanies}
						onSearch={handleCompaniesSearch}
						disabled={isPageReadOnly}
					/>
					{ isCompaniesSearchEmpty &&
						<RBAC allowedRoles={EXTERNAL_ROLES}>
							<span onClick={showModal} className={cx(label3, styles.addNewCompany)}>+ Add new company</span>
						</RBAC>
					}
					<AddNewCompany
						visible={modalVisible}
						onOk={handleOk}
						onCancel={handleCancel}
					/>
				</div>
				<div className={styles.col50}>
					<Input
						label="Job title"
						name="title"
						value={formDetails.title || ""}
						labelClassName={label1}
						onChange={handleInputChange}
						error={error.title || false}
						readOnly={isPageReadOnly}
					/>
				</div>
			</div>
			{ (!props.userInfo.roles.includes("ROLE_RECRUITER") && !isPageReadOnly) &&
				<>
					<div className={styles.row}>
						<div className={styles.col50}>
							<Input
								label="Sales POC"
								value={formDetails.salesPoc || ""}
								readOnly={true}
							/>
						</div>
						<div className={styles.col50}>
							<Select
								mode="default"
								label="Internal POC"
								name="internalPoc"
								placeholder="Select Internal POC"
								onChange={handleInputChange}
								error={error.internalPoc || false}
								options={INTERNAL_POC}
								defaultValue={formDetails.internalPoc}
							/>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.col50}>
							<LocationSelect
								label="Job locations"
								name="location"
								placeholder="Mumbai"
								labelClassName={label1}
								onChange={handleInputChange}
								error={error.location || false}
								badgeItems={formDetails.location}
							/>
						</div>
						<div className={styles.col50}>
							<Select
								mode="default"
								label="Process Type"
								name="type"
								placeholder="Select a type"
								onChange={handleInputChange}
								error={error.type || false}
								options={JOB_TYPES}
								defaultValue={formDetails.type}
							/>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.col50}>
							<Select
								mode="multiple"
								label="Select cohorts"
								name="cohorts"
								autoClearSearchValue={false}
								onChange={handleInputChange}
								error={error.cohorts || false}
								options={props.cohortOptions || []}
								defaultValue={formDetails.cohorts}
							/>
						</div>
						<div className={styles.col50}>
							<Select
								mode="tags"
								label="Relevant for"
								name="relevantFor"
								onChange={handleInputChange}
								error={error.relevantFor || false}
								options={props.relevantForOptions || []}
								defaultValue={formDetails.relevantFor}
							/>
						</div>
					</div>
				</>
			}
			{ (props.userInfo.roles.includes("ROLE_RECRUITER") || isPageReadOnly) &&
				<div className={styles.row}>
					<div className={styles.col50}>
						<LocationSelect
							label="Job locations"
							name="location"
							placeholder="Mumbai"
							labelClassName={label1}
							onChange={handleInputChange}
							error={error.location || false}
							badgeItems={formDetails.location}
							disabled={isPageReadOnly}
						/>
					</div>
					<div className={styles.col50}>
						<Select
							mode="multiple"
							label="Program"
							name="programs"
							placeholder="Select programs"
							onChange={handleInputChange}
							error={error.programs || false}
							options={props.relevantForOptions || []}
							defaultValue={formDetails.programs}
							disabled={isPageReadOnly}
						/>
					</div>
				</div>
			}
			<div className={styles.row}>
				<div className={styles.col50}>
					<Input
						label="Set due date"
						name="dueDate"
						type="date"
						value={formDetails.dueDate || ""}
						labelClassName={label1}
						onChange={handleInputChange}
						error={error.dueDate || false}
						readOnly={isPageReadOnly}
					/>
				</div>
				<div className={styles.col50}>
					<Input
						label="Number of openings (OP)"
						name="openingsCount"
						value={formDetails.openingsCount || ""}
						labelClassName={label1}
						onChange={handleInputChange}
						regex={regexStrings.THREE_DIGITS}
						readOnly={isPageReadOnly}
					/>
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.col50}>
					<label className={label2}>Relevant experience (OP)</label>
					<InputWithUnits
						inputLabel="Minimum"
						unitsLabel="Years"
						name="relevantExpMin"
						value={formDetails.relevantExpMin || ""}
						error={error.relevantExpMin || false}
						{..._inputWithUnitsProps}
					/>
					<InputWithUnits
						inputLabel="Maximum"
						unitsLabel="Years"
						name="relevantExpMax"
						value={formDetails.relevantExpMax || ""}
						error={error.relevantExpMax || false}
						helperText={helperText.relevantExp}
						{..._inputWithUnitsProps}
					/>
				</div>
				<div className={styles.col50}>
					<label className={label2}>Salary range</label>
					<InputWithUnits
						inputLabel="Minimum"
						unitsLabel="LPA"
						name="salaryFrom"
						value={formDetails.salaryFrom || ""}
						error={error.salaryFrom || false}
						{..._inputWithUnitsProps}
					/>
					<InputWithUnits
						inputLabel="Maximum"
						unitsLabel="LPA"
						name="salaryTo"
						value={formDetails.salaryTo || ""}
						error={error.salaryTo || false}
						helperText={helperText.salary}
						{..._inputWithUnitsProps}
					/>
				</div>
			</div>

			<div className={styles.row}>
				<div className={styles.col50}>
					<label className={label2}>Total experience</label>
					<InputWithUnits
						inputLabel="Minimum"
						unitsLabel="Years"
						name="totalExpMin"
						value={formDetails.totalExpMin || ""}
						error={error.totalExpMin || false}
						{..._inputWithUnitsProps}
					/>
					<InputWithUnits
						inputLabel="Maximum"
						unitsLabel="Years"
						name="totalExpMax"
						value={formDetails.totalExpMax || ""}
						error={error.totalExpMax || false}
						helperText={helperText.totalExp}
						{..._inputWithUnitsProps}
					/>
				</div>
			</div>
			<div>
				<label className={label2}>
					Job description (minimum 300 characters required)
				</label>
				<div>
					<CKEditor
						data={formDetails.description || ""}
						config={isPageReadOnly ? {...CKEDITOR_CONFIG, readOnly: true} : CKEDITOR_CONFIG }
						onChange={handleEditorChange}
						onBeforeLoad={(CKEDITOR) =>
							(CKEDITOR.disableAutoInline = true)
						}
					/>
				</div>
				<label
					className={cx(styles.charCount, {
						[styles.hasError]: error.charCount,
					})}
				>
					Character count: {charCount}/300
				</label>
			</div>
			<div className={styles.rowFlexEnd}>
				{ renderActionButtons() }
			</div>
			{Object.keys(error).length !== 0 && (
				<div className={styles.rowFlexEnd}>
					<div className={styles.errorText}>
						Please fill all the mandatory fields to proceed
					</div>
				</div>
			)}
		</div>
	);
};

BasicInfo.propTypes = {
	userInfo: PropTypes.object.isRequired,
	jobInfo: PropTypes.object.isRequired,
	jobActions: PropTypes.object.isRequired,
	navigationActions: PropTypes.object.isRequired,
	errorActions: PropTypes.object.isRequired,
	nextIndex: PropTypes.func.isRequired,
	index: PropTypes.string.isRequired,
	cohortOptions: PropTypes.array,
	relevantForOptions: PropTypes.array
};

function mapStateToProps(state) {
	return {
		jobInfo: state.jobInfo,
		userInfo: state.userInfo
	};
}

function mapDispatchToProps(dispatch) {
	return {
		jobActions: bindActionCreators(jobActions, dispatch),
		navigationActions: bindActionCreators(navigationActions, dispatch),
		errorActions: bindActionCreators(errorActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
