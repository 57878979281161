const EVENT_CATEGORIES = [{
	value: 0,
    label: "Digital Marketing",
}, {
	value: 1,
    label: "Product Management",
}, {
	value: 2,
    label: "Data Science",
}, {
	value: 3,
    label: "Management",
}, {
	value: 4,
    label: "Big Data",
}, {
	value: 5,
    label: "Software engineering",
}, {
	value: 6,
    label: "Digital Strategies",
}, {
	value: 7,
    label: "Machine Learning",
}, {
	value: 8,
    label: "Blockchain",
}]

export { EVENT_CATEGORIES };
