/*
TODO:  Remove the antd toast and build the internal toast implace of it.
*/
import { message } from "antd";

class Toast {
	constructor() {
		['success', 'error', 'info', 'warning', 'loading'].forEach(type => this[type] = this._createUtilities(type));
	}
	_createUtilities = type => (content = "I'm a toast", duration, onClose) => message[type](content, duration, onClose)
	config = (configPayload) => message.config(configPayload)
	open = (openPayload) => message.open(openPayload)
}

export default function () {
	return new Toast();
}
