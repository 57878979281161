import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import Intercom from 'react-intercom';

import AttributeListing from "modules/AttributeListing";
import CandidateListing from "modules/CandidateListing";
import Companies from "modules/Companies";
import CreateJobOpening from "modules/CreateJobOpening";
import JobOpenings from "modules/JobOpenings";
import JobOpeningsReview from "modules/JobOpeningsReview";
import LearnerApplications from "modules/LearnerApplications";
import Events from "modules/Events"
import LoginPage from "modules/LoginPage";
import Logout from "modules/Logout";
import UploadData from "modules/UploadData";
// import LearnerProfile from "modules/LearnerProfile";

import Layout from "./layout";
import routePaths from "./routePaths";
import { checkAccess } from "utils";
import { RBAC } from "hoc";
import { EXTERNAL_ROLES } from "constants/roleMapping";
import constants from "constants";
export const routesPath = routePaths;

const RouteWrapper = ({ Component, ...props }) => {
	const isNotAuthenticated = !props.userInfo.isLoggedIn;
	const user = {
		email: props.userInfo.email,
		name: props.userInfo.name
	  };
	let access = checkAccess(props.userInfo.roles, props.roles);

	if (isNotAuthenticated || (!isNotAuthenticated && !access)) {
		return <Redirect to={routesPath.logout.route} />;
	} else {
		return (
			<Layout>
				<RBAC allowedRoles={EXTERNAL_ROLES}>
					<Intercom appID={constants.intercomID} { ...user } />
				</RBAC>
				<Route
					{...props}
					render={routeProps => <Component {...routeProps} />}
				/>
			</Layout>
		);
	}
};

RouteWrapper.propTypes = {
	Component: PropTypes.func,
	userInfo: PropTypes.object,
	roles: PropTypes.array
};

class RouterComponent extends React.Component {
	componentDidMount() {
		setTimeout(function() {
			document.getElementById("pageLoaderRoot").classList.add("hide");
		}, 1000);
	}

	render() {
		return (
			<ConnectedRouter history={this.props.history}>
				<Switch>
					<RouteWrapper
						exact
						path={routesPath.attributes.route}
						roles={routesPath.attributes.roles}
						Component={AttributeListing}
						userInfo={this.props.userInfo}
					/>
					<RouteWrapper
						exact
						path={routesPath.uploadData.route}
						roles={routesPath.uploadData.roles}
						Component={UploadData}
						userInfo={this.props.userInfo}
					/>
					<RouteWrapper
						exact
						path={routesPath.jobEdit.route}
						roles={routesPath.jobEdit.roles}
						Component={CreateJobOpening}
						userInfo={this.props.userInfo}
					/>
					<RouteWrapper
						exact
						path={routesPath.jobCreate.route}
						roles={routesPath.jobCreate.roles}
						Component={CreateJobOpening}
						userInfo={this.props.userInfo}
					/>
					<RouteWrapper
						exact
						path={routesPath.jobReview.route}
						roles={routesPath.jobReview.roles}
						Component={CreateJobOpening}
						userInfo={this.props.userInfo}
					/>
					<RouteWrapper
						exact
						path={routesPath.jobView.route}
						roles={routesPath.jobView.roles}
						Component={CreateJobOpening}
						userInfo={this.props.userInfo}
					/>
					<RouteWrapper
						exact
						path={routesPath.candidates.route}
						roles={routesPath.candidates.roles}
						Component={CandidateListing}
						userInfo={this.props.userInfo}
					/>
					<RouteWrapper
						exact
						path={routesPath.companies.route}
						roles={routesPath.companies.roles}
						Component={Companies}
						userInfo={this.props.userInfo}
					/>
					<RouteWrapper
						exact
						path={routesPath.jobApplications.route}
						roles={routesPath.jobApplications.roles}
						Component={LearnerApplications}
						userInfo={this.props.userInfo}
					/>
					<RouteWrapper
						exact
						path={routesPath.jobOpeningsReview.route}
						roles={routesPath.jobOpeningsReview.roles}
						Component={JobOpeningsReview}
						userInfo={this.props.userInfo}
					/>
					<RouteWrapper
						path={routesPath.events.route}
						roles={routesPath.events.roles}
						Component={Events}
						userInfo={this.props.userInfo}
					/>
					{/* <Route
						exact
						path={routesPath.learnerProfile.route}
						roles={routesPath.learnerProfile.roles}
						Component={LearnerProfile}
						userInfo={this.props.userInfo}
					/> */}
					<Route
						exact
						path={routesPath.login.route}
						component={LoginPage}
					/>
					<Route
						exact
						path={routesPath.logout.route}
						component={Logout}
					/>
					<Redirect to={routesPath.logout.route} />
				</Switch>
			</ConnectedRouter>
		);
	}
}

RouterComponent.propTypes = {
	router: PropTypes.object,
	history: PropTypes.object,
	userInfo: PropTypes.object
};

function mapStateToProps(state, props) {
	return {
		router: state.router,
		history: props.history,
		userInfo: state.userInfo
	};
}

export default connect(mapStateToProps)(RouterComponent);
