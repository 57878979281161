import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./index.scss";

export default class TabComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIndex: parseInt(this.props.selectedIdx),
			className: this.props.className
		};
	}
	static getDerivedStateFromProps(props, state) {
		if (
			props.selectedIdx !== state.currentIndex &&
			props.selectedIdx <= props.tabList.length - 1
		) {
			return {
				currentIndex: parseInt(props.selectedIdx)
			};
		} else {
			return null;
		}
	}
	static propTypes = {
		tabList: PropTypes.arrayOf(PropTypes.object).isRequired,
		selectedIdx: function(props, propName) {
			if (Number(props.selectedIdx) > props.tabList.length) {
				return new Error(
					`Value of prop <${propName}> is higher than the length of the prop <tabList>`
				);
			}
		},
		changeTab: PropTypes.func.isRequired,
		className: PropTypes.string
	};

	renderTab = (tabList, currentIndex) => {
		return tabList.map((elem, i) => {
			return (
				<div
					key={i}
					className={cx(styles.tab, {
						[styles.selected]: currentIndex === i
					})}
					onClick={() => this.props.changeTab(i)}
				>
					{elem.title}
				</div>
			);
		});
	};

	renderContent = (tabList, currentIndex) => {
		const data = tabList[currentIndex];
		return <Fragment>{data.Component}</Fragment>;
	};

	render() {
		const { tabList } = this.props;
		const { currentIndex, className } = this.state;
		const _className = cx(className, styles.container);
		return (
			<div className={_className}>
				<div className={styles.left}>
					{this.renderTab(tabList, currentIndex)}
				</div>
				<div className={styles.right}>
					<div className={styles.rightInner}>
						{this.renderContent(tabList, currentIndex)}
					</div>
				</div>
			</div>
		);
	}
}

TabComponent.defaultProps = {
	selectedIdx: 0
};
