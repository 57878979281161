import constants from "constants";
import createInstanceWithSessionDetails from "./helper";

const authApiInstance = createInstanceWithSessionDetails(
	constants.authApiServerUrl
);

const URLS = {
	login: `/apis/v2/login`,
	logout: `/apis/logout`,
	// upload: `/apis/file/upload`
};

export const loginUser = async function({ email, password }) {
	try {
		const url = URLS.login;
		const response = await authApiInstance.post(url, {
			username: email,
			password
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const logoutUser = async function () {
	try {
		const url = URLS.logout;
		return await authApiInstance.post(url);
	} catch(error) {
		return error.response;
	}
};

//for future use - API has been moved to learn service

// export const uploadFileToCentral = async function(
// 	file
// ) {
// 	try {
// 		let formData = new FormData();
// 		formData.append("file", file);
// 		const url = URLS.upload;
// 		const response = await authApiInstance.post(url, formData, {
// 			headers: { "Content-Type": "multipart/form-data" }
// 		});
// 		return response;
// 	} catch (error) {
// 		return error.response;
// 	}
// };

export default { loginUser, logoutUser }
