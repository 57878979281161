import React from "react";
import axios from "axios";
import { store } from "../store";
import { logoutUser } from "actions/user";
import { setError } from "actions/error";
import constants from "constants";

const loginStatuses = [
	"PARALLEL-LOGIN",
	"EXPIRED",
	"EMAIL-OR-PASSWORD-CHANGE",
	"ACCOUNT-LOCKED"
];

function faqURL(loginStatus) {
	switch (loginStatus) {
		case "PARALLEL-LOGIN":
			return constants.PARALLEL_LOGIN;
		case "EXPIRED":
			return constants.EXPIRED;
		case "EMAIL-OR-PASSWORD-CHANGE":
			return constants.EMAIL_OR_PASSWORD_CHANGE;
		case "ACCOUNT-LOCKED":
			return constants.ACCOUNT_LOCKED;
	}
}

const createInstanceWithSessionDetails = url => {
	let apiInstance = axios.create({
		baseURL: url
	});

	apiInstance.interceptors.request.use(config => {
		let userInfo = store.getState().userInfo;

		config.headers[constants.authTokenBackendKey] = userInfo.authToken;
		config.headers["sessionId"] = userInfo.sessionId;
		return config;
	});

	apiInstance.interceptors.response.use(
		response => response,
		error => {
			const { response } = error;
			if (response.status === 401) {
				const loginStatus = response.headers["login-status"];
				let errorMsg =
					"Your session has expired. Please login again to access the platform.";
				const { errorList } = response.data;
				if (errorList && errorList.length) {
					let faqLink = null;
					if (loginStatuses.indexOf(loginStatus) !== -1) {
						faqLink = <a href={faqURL(loginStatus)}>Learn more</a>;
					}
					errorMsg = (
						<span>
							{errorList[0]["errorMessage"]}
							&nbsp;
							{faqLink}
						</span>
					);
				}
				const { userInfo } = store.getState();
				store.dispatch(logoutUser(userInfo.authToken));
				store.dispatch(setError(errorMsg));
			}
			return error.response;
		}
	);

	return apiInstance;
};

export default createInstanceWithSessionDetails;
