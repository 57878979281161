const JOB_TYPES = [
	{
		value: 0,
		label: "Telephonic"
	},
	{
		value: 1,
		label: "Hiring drive( Accelerate )"
	},
	{
		value: 2,
		label: "Hybrid ( Telephonic + In person )"
	},
	{
		value: 3,
		label: "In person"
	}
];


const MANDATORY_FIELDS_EXTERNAL = [
	"companyName",
	"title",
	"location",
	"type",
	"programs",
	"relevantFor",
	"dueDate",
	"totalExpMin",
	"totalExpMax",
	"salaryFrom",
	"salaryTo"
];

const MANDATORY_FIELDS_INTERNAL = [
	"companyName",
	"title",
	"location",
	"type",
	"cohorts",
	"relevantFor",
	"dueDate",
	"totalExpMin",
	"totalExpMax",
	"salaryFrom",
	"salaryTo"
];

const OPTIONAL_FIELDS = [
	"openingsCount",
	"relevantExpMin",
	"relevantExpMax"
];

const CKEDITOR_CONFIG = {
	toolbarGroups: [
		{ name: "styles", groups: ["styles"] },
		{ name: "colors", groups: ["colors"] },
		{
			name: "basicStyles",
			groups: ["basicstyles", "cleanup"]
		},
		"/",
		{
			name: "paragraph",
			groups: ["align", "list"]
		},
		{ name: "links", groups: ["links"] },
		{ name: "insert", groups: ["insert"] },
		{ name: "tools", groups: ["tools"] },
		{ name: "others", groups: ["others"] },
		{
			name: "clipBoard",
			groups: ["undo"]
		}
	],
	removeButtons:
		"CreateDiv,Anchor,Image,Flash,PageBreak,Iframe,ShowBlocks,Styles",
	extraPlugins: [
		"copyformatting",
		"font",
		"tableresize",
		"colorbutton",
		"font",
		"justify"
	],
	removePlugins: "elementspath",
	fillEmptyBlocks: false,
	tabSpaces: 4
};

const MINMAXERROR = "Minimum value can not be greater than maximum value";

export {
	JOB_TYPES,
	MANDATORY_FIELDS_INTERNAL,
	MANDATORY_FIELDS_EXTERNAL,
	OPTIONAL_FIELDS,
	CKEDITOR_CONFIG,
	MINMAXERROR
};
