import actionTypes from "actionTypes";

const initialState = {
	message: ""
};

export default function error(state, action) {
	switch (action.type) {
		case actionTypes.SET_ERROR:
			return {
				...state,
				...action.payload
			};
		case actionTypes.CLEAR_ERROR:
			return initialState;
	}
	return state ? state : initialState;
}
