import React from 'react'
import { Upload, message } from "antd";
import constants from "constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const fileUpload = ({
	action = constants.growthApiServerUrl + "/apis/file/upload",
	onUpload,
	options,
	setLoading,
	children,
	disabled,
	...props
}) => {
	const {userInfo} = props

	const uploadProps = {
		...options,
		action: action,
		beforeUpload: () => {
			onUpload("")
			setLoading(true)
		},
		onChange: info => {
			if (info.file.status === "done") {
				setLoading(false)
				let filedata = info.file.response.data;
				onUpload(filedata.url);
			} else if (info.file.status === "error") {
				setLoading(false)
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		headers: {
			"auth-token": userInfo.authToken,
			sessionID: userInfo.sessionId,
			"X-Requested-With": null
		}
	};

	return (
		<Upload {...uploadProps} disabled={disabled}>
			{children}
		</Upload>
	);
};

fileUpload.propTypes = {
	userInfo: PropTypes.object.isRequired,
	action: PropTypes.string,
	onUpload: PropTypes.func.isRequired,
	options: PropTypes.object,
	setLoading: PropTypes.func.isRequired,
	children: PropTypes.node,
	disabled: PropTypes.bool
};

function mapStateToProps(state) {
	return {
		userInfo: state.userInfo
	};
}

export default connect(
	mapStateToProps,
	null
)(fileUpload);
