const INTERNAL_POC = [
	{
		value: 0,
		label: "Aakansha Saxena"
	},
	{
		value: 1,
		label: "Adrish Banerjee"
	},
	{
		value: 2,
		label: "Akshay Sunil"
	},
	{
		value: 3,
		label: "Amita Choudhary"
	},
	{
		value: 4,
		label: "Ashutosh"
	},
	{
		value: 5,
		label: "Avani"
	},
	{
		value: 6,
		label: "Avinash Paranandi"
	},
	{
		value: 7,
		label: "Bhavesh Waghmare"
	},
	{
		value: 8,
		label: "Bhushan"
	},
	{
		value: 9,
		label: "Chandrika Majumder"
	},
	{
		value: 10,
		label: "Devesh Gupta"
	},
	{
		value: 11,
		label: "Gurpreet Jheeta"
	},
	{
		value: 12,
		label: "Lakshya Bhatia"
	},
	{
		value: 13,
		label: "Lokesh Mathur"
	},
	{
		value: 14,
		label: "Manasa Kulkarni"
	},
	{
		value: 15,
		label: "Mihir"
	},
	{
		value: 16,
		label: "Mukesh"
	},
	{
		value: 17,
		label: "Nainu Wadhwa"
	},
	{
		value: 18,
		label: "Nivedita Rathore"
	},
	{
		value: 19,
		label: "Pooja Sharma"
	},
	{
		value: 20,
		label: "Raaj Sharma"
	},
	{
		value: 21,
		label: "Renuka Bhan"
	},
	{
		value: 22,
		label: "Roshni"
	},
	{
		value: 23,
		label: "Ruben"
	},
	{
		value: 24,
		label: "Sakina Tinwala"
	},
	{
		value: 25,
		label: "Sakshi"
	},
	{
		value: 26,
		label: "Sakshita Babel"
	},
	{
		value: 27,
		label: "Salim"
	},
	{
		value: 28,
		label: "Samiksha Mishra"
	},
	{
		value: 29,
		label: "Satya Reddy"
	},
	{
		value: 30,
		label: "Shubham"
	},
	{
		value: 31,
		label: "Shubham Lokre"
	},
	{
		value: 32,
		label: "Tanaya"
	},
	{
		value: 33,
		label: "Vrinda Nair"
	},
];

const SALES_POC = [
	{
		value: 0,
		label: "Ajay Shah"
	},
	{
		value: 1,
		label: "Ankur"
	},
	{
		value: 2,
		label: "Hemal Thakker"
	},
	{
		value: 3,
		label: "Neenu Jose"
	},
	{
		value: 4,
		label: "Pragun Chopra"
	},
	{
		value: 5,
		label: "Preetha Bhatt"
	},
	{
		value: 6,
		label: "Rahul Chopra"
	},
	{
		value: 7,
		label: "Rahul NC"
	},
	{
		value: 8,
		label: "Ruben Sharma"
	},
	{
		value: 9,
		label: "Sagar Nichani"
	},
	{
		value: 10,
		label: "Viraj Thacker"
	}
];

const APPLICATION_STATUSES = [
	{
		label: "Applied",
		value: "1",
		color: "blue"
	},
	{
		label: "Misfit",
		value: "2",
		color: "red"
	},
	{
		label: "CV - Feedback awaited",
		value: "3",
		color: "yellow"
	},
	{
		label: "CV - Shortlisted",
		value: "4",
		color: "yellow"
	},
	{
		label: "CV - Reject",
		value: "5",
		color: "yellow"
	},
	{
		label: "Test - To be scheduled",
		value: "6",
		color: "orange"
	},
	{
		label: "Test - Scheduled",
		value: "7",
		color: "orange"
	},
	{
		label: "Test - Backout",
		value: "8",
		color: "orange"
	},
	{
		label: "Test - Feedback awaited",
		value: "9",
		color: "orange"
	},
	{
		label: "Test - Reject",
		value: "10",
		color: "orange"
	},
	{
		label: "Interview - To be scheduled",
		value: "11",
		color: "indigo"
	},
	{
		label: "Interview - Scheduled",
		value: "12",
		color: "indigo"
	},
	{
		label: "Interview - Backout",
		value: "13",
		color: "indigo"
	},
	{
		label: "Interview - Feedback awaited",
		value: "14",
		color: "indigo"
	},
	{
		label: "Interview - Reject",
		value: "15",
		color: "indigo"
	},

	{
		label: "Offer - Awaited",
		value: "16",
		color: "green"
	},
	{
		label: "Offer - Acceptance Awaited",
		value: "17",
		color: "green"
	},
	{
		label: "Offer - Accepted",
		value: "18",
		color: "green"
	},
	{
		label: "Offer - Rejected",
		value: "19",
		color: "green"
	},
	{
		label: "Joining - Done",
		value: "20",
		color: "black"
	},
	{
		label: "Joining - Backout",
		value: "21",
		color: "black"
	},
	{
		label: "Closed - Not selected",
		value: "22",
		color: "red"
	},
	{
		label: "Position on hold",
		value: "23",
		color: "black"
	}
  ];

  const PAGE_SIZE = 20;
export { INTERNAL_POC, SALES_POC, APPLICATION_STATUSES ,PAGE_SIZE};
