import React from "react";
import { Input } from 'antd';
import PropTypes from "prop-types";


export default class CustomInput extends React.Component {
	static propTypes = {
		onChange: PropTypes.func,
	};

	render() {
		return(
			<>
                <Input {...this.props}
                 onChange ={this.props.onChange}
                />
			</>
		)
	}
}
