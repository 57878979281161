import React,  { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "../../index.scss";
import Input from "widgets/Input";
import Upload from "widgets/Upload";
import Icon from "widgets/Icon";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as event_actions from "actions/event";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const uploadOptions = {
	accept: ".jpg,.jpeg,.png,.svg,.gif",
	showUploadList: false,
	listType: "picture-card"
}

const SpeakerInfo = ({ event, eventActions }) => {
	const [isLogoLoading, setIsLogoLoading] = useState([false]);
	const { speakerInfo } = event;
	const label1 = styles.labelOnlyAdditionalProps;

	const handleInputChange = ({ name, value, index }) => {
		let temp = [...speakerInfo];
		temp[index][name]["value"] = value;
		temp[index][name]["hasError"] = false;
		eventActions.setEventData(temp, "SpeakerInfo");
	};

	const addAnotherSpeaker = () => {
		eventActions.setEventData([
			...speakerInfo,
			{
				imageURL: {},
				name: {},
				linkedinURL: {},
				company: {},
				designation: {},
				topic: {},
				about: {}
			}
		], "SpeakerInfo");
	}

	const deleteSpeaker = (index) => {
		let temp = [...speakerInfo]
		temp.splice(index, 1);
		eventActions.setEventData(temp, "SpeakerInfo");
	}

	const handleLogoLoading = (value, index) => {
		let tempLogoLoading = [...isLogoLoading];
		tempLogoLoading[index] = value;
		setIsLogoLoading(tempLogoLoading);
	}

	return (
		<div className={styles.speakersContainer}>
			{ speakerInfo.map((speaker, index) => (
				<div key={index} className={styles.speakerInfo}>
					<div className={styles.left}>
						<div className={styles.top}>
							<span className={styles.rowNumber}>{index + 1}.</span>
							<div>
								<Upload
									onUpload={url =>
										handleInputChange({ name: "imageURL", value: url, index })
									}
									setLoading={(value)=>handleLogoLoading(value, index)}
									options={uploadOptions}
								>
									{speaker.imageURL.value ? (
										<img
											src={speaker.imageURL.value}
											alt="logo"
											style={{ width: "100%" }}
										/>
									) : (
										<div>
											<Icon type={isLogoLoading[index] ? "loading" : "plus"} />
											<div className="ant-upload-text">Speaker image</div>
										</div>
									)}
								</Upload>
								<span className={styles.recommendedSize}>Required:<br></br> 280px X 280px</span>
								{ speaker.imageURL.hasError && (
									<div
										className={cx(styles.hasError, styles.errorText)}
									>
										<span>Please upload<br/>image</span>
									</div>
								)}
							</div>
						</div>
						{ speakerInfo.length > 1 && (
							<div
								className={cx(styles.labelBasicProps, styles.labelOnlyAdditionalProps, styles.deleteButton)}
								onClick={() => deleteSpeaker(index)}
							>
								<FontAwesomeIcon icon={faMinusCircle} />
								<span>Delete speaker</span>
							</div>
						)}
					</div>
					<div className={styles.right}>
						<div className={styles.row}>
							<div className={styles.col50}>
								<Input
									label="speaker name"
									name="name"
									value={speaker.name.value || ""}
									labelClassName={label1}
									onChange={({ name, value }) => handleInputChange({ name, value, index })}
									error={speaker.name.hasError || false}
								/>
							</div>
							<div className={styles.col50}>
								<Input
									label="linkedin url"
									name="linkedinURL"
									value={speaker.linkedinURL.value || ""}
									labelClassName={label1}
									onChange={({ name, value }) => handleInputChange({ name, value, index })}
									error={speaker.linkedinURL.hasError || false}
								/>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.col50}>
								<Input
									label="company"
									name="company"
									value={speaker.company.value || ""}
									labelClassName={label1}
									onChange={({ name, value }) => handleInputChange({ name, value, index })}
									error={speaker.company.hasError || false}
								/>
							</div>
							<div className={styles.col50}>
								<Input
									label="designation"
									name="designation"
									value={speaker.designation.value || ""}
									labelClassName={label1}
									onChange={({ name, value }) => handleInputChange({ name, value, index })}
									error={speaker.designation.hasError || false}
								/>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.col100}>
								<Input
									label="topic"
									name="topic"
									value={speaker.topic.value || ""}
									labelClassName={label1}
									onChange={({ name, value }) => handleInputChange({ name, value, index })}
									error={speaker.topic.hasError || false}
								/>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.col100}>
								<Input
									label="About the speaker"
									name="about"
									multi={true}
									value={speaker.about.value || ""}
									labelClassName={label1}
									onChange={({ name, value }) => handleInputChange({ name, value, index })}
									error={speaker.about.hasError || false}
								/>
							</div>
						</div>
					</div>
				</div>
			))}
				<div className={styles.addButtonContainer}>
					<div
						className={cx(styles.labelBasicProps, styles.labelOnlyAdditionalProps, styles.addButton)}
						onClick={addAnotherSpeaker}
					>
						<FontAwesomeIcon icon={faPlusCircle} />
						<span>
							Add another speaker
						</span>
					</div>
				</div>
			</div>
	)
}

SpeakerInfo.propTypes = {
	event: PropTypes.object.isRequired,
	eventActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		event: state.event,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		eventActions: bindActionCreators(event_actions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerInfo);

