// Set up your root reducer here...
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "./user";
import error from "./error";
import job from "./job";
import event from "./event";

export default history =>
	combineReducers({
		router: connectRouter(history),
		userInfo: user,
		errorInfo: error,
		jobInfo: job,
		event: event
	});
