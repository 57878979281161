import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "widgets/Select";
import styles from "./index.scss";
import { getAttributes } from "apis";

const CategorySelector = props => {
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [_value, setValue] = useState({});
	const { label, addNew, cohortId, dataUpload } = props;

	const fetchData = async () => {
		let response = await getAttributes({
			course_id: cohortId,
			type: "all",
			dataUpload: dataUpload === true
		});
		let options = [];
		if (response) {
			if (addNew) {
				options.push({ label: "Add a new category", value: 0 });
			}
			options = [
				...options,
				...response.data.types.map((type, index) => ({
					label: type,
					value: index + 1
				}))
			];
			setIsLoading(false);
			setOptions(options);
		} else {
			// write error toast code for no response from API
		}
	};

	useEffect(() => {
		if (props.cohortId && props.cohortId !== "") {
			if (_value.value) {
				setValue({ value: "" });
				props.onChange("");
			}
			setIsLoading(true);
			fetchData();
		}
	}, [props.cohortId]);

	const handleChange = ({ value }) => {
		setValue({ value: value });
		if (value !== undefined) {
			props.onChange(value);
		}
	};

	return (
		<Fragment>
			{label ? <label className={styles.label}>{label}</label> : null}
			<Select
				showSearch
				mode="default"
				name="CategorySelector"
				placeholder="Select Category"
				onChange={handleChange}
				{..._value}
				options={options || {}}
				loading={isLoading}
			/>
		</Fragment>
	);
};

CategorySelector.propTypes = {
	cohortId: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	label: PropTypes.string,
	addNew: PropTypes.bool,
	dataUpload: PropTypes.bool
};

export default CategorySelector;
