import React from "react";
import PropTypes from "prop-types";
import styles from "./index.scss";
import cx from "classnames";

const CircularSpinner = props => {
	const { className, circleClassName } = props;
	const _svgClassName = cx(styles.spinner, className);
	const _circleClassName = cx(styles.path, circleClassName);
	return (
		<svg className={_svgClassName} viewBox="0 0 50 50">
			<circle
				className={_circleClassName}
				cx="25"
				cy="25"
				r="20"
				fill="none"
				strokeWidth="5"
			/>
		</svg>
	);
};

CircularSpinner.propTypes = {
	className: PropTypes.string,
	circleClassName: PropTypes.string
};

export default CircularSpinner;
