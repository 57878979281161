import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as jobActions from "actions/job";
import PropTypes from "prop-types";
import cx from "classnames";
import { editJob, deleteJobAttributes } from "apis";
import LocationSelect from "shared/LocationSelect";
import InputWithUnits from "shared/InputWithUnits";
import Button from "widgets/Button";
import RadioGroup from "widgets/RadioGroup";
import regexStrings from "constants/regexStrings";
import styles from "../index.scss";
import PreviewCountCard from "./components/PreviewCountCard";
import validateInputs from "./validateInputs";

const visibilityOptions = [
	{
		value: "0",
		label: "Visible to all in selected cohorts"
	},
	{
		value: "1",
		label: "Customize job opening visibility"
	}
];

const VisibilityInfo = ({
	jobInfo,
	jobActions,
	selectedCohorts,
	nextIndex,
	index
}) => {
	const { basicInfo, visibilityInfo, editInfo } = jobInfo;

	const [error, setError] = useState({});
	const [helperText, setHelperText] = useState({});
	const [formDetails, setFormDetails] = useState(
		basicInfo.visibility == "custom"
			? {
					...visibilityInfo,
					previewCount:
						visibilityInfo && visibilityInfo.previewCount
							? visibilityInfo.previewCount
							: {
									count: {
										custom: {},
										all: {}
									},
									emails: {}
							  }
			  }
			: {
					vLocation: basicInfo.location,
					vTotalExpMin: basicInfo.totalExpMin,
					vTotalExpMax: basicInfo.totalExpMax,
					vRelevantExpMin: basicInfo.relevantExpMin
						? basicInfo.relevantExpMin
						: "",
					vRelevantExpMax: basicInfo.relevantExpMax
						? basicInfo.relevantExpMax
						: "",
					vSalaryFrom: basicInfo.salaryFrom
						? basicInfo.salaryFrom
						: "",
					vSalaryTo: basicInfo.salaryTo ? basicInfo.salaryTo : "",
					previewCount:
						visibilityInfo && visibilityInfo.previewCount
							? visibilityInfo.previewCount
							: {
									count: {
										custom: {},
										all: {}
									},
									emails: {}
							  }
			  }
	);

	const [radioValue, setRadioValue] = useState(
		basicInfo.visibility == "custom" ? 1 : 0
	);

	const [showLoaderOnButton, setShowLoaderOnButton] = useState(false);

	const [isVisibilityCountReset, setIsVisibilityCountReset] = useState(false);
	const [isCalculatingCount, setIsCalculatingCount] = useState(false);

	const handleRadioChange = ({ value }) => {
		if (!isCalculatingCount) setRadioValue(parseInt(value));
	};

	const handleInputChange = ({ name, value }) => {
		let tempFormDetails = { ...formDetails, [name]: value };
		let tempError = {};
		let tempHelperText = {};
		validateInputs(tempFormDetails, tempError, tempHelperText);
		if (!Object.keys(tempError).length) {
			if (!isVisibilityCountReset) {
				setIsVisibilityCountReset(true);
			}
			setError({});
			setHelperText({});
		} else {
			setError(tempError);
			setHelperText(tempHelperText);
		}
		setFormDetails(tempFormDetails);
	};

	const handleCountRefresh = countData => {
		if (!!radioValue && isVisibilityCountReset) {
			setIsVisibilityCountReset(false);
		}
		setFormDetails({ ...formDetails, previewCount: countData });
	};

	const handleCountCalculating = isCalculating => {
		setIsCalculatingCount(isCalculating);
	};

	const saveAndNext = ({ visibility, ...data }) => {
		data["previewCount"] = { ...formDetails.previewCount };
		jobActions.setJobData({
			visibilityInfo: data,
			basicInfo: {
				...basicInfo,
				visibility
			}
		});
		nextIndex({ index: parseInt(index) + 1 });
	};

	const callEditJob = async data => {
		let { jobID } = editInfo;
		try {
			let response = await editJob(data, jobID);
			if (response) {
				saveAndNext(data);
			} else {
				// write error toast code for no response from API
			}
		} catch (e) {
			// error
		}
	};

	const handleSubmitClick = async () => {
		let tempFormDetails = { ...formDetails };
		let tempError = {};
		let tempHelperText = {};
		let { jobID, mode } = editInfo;
		const visibility = parseInt(radioValue) ? "custom" : "all";

		validateInputs(tempFormDetails, tempError, tempHelperText);
		if (!Object.keys(tempError).length) {
			// no error
			if (mode === "edit") {
				let visibilityOld = basicInfo.visibility;
				if (visibility == "custom") {
					handleCustomVisibility(jobID, visibilityOld);
				} else if (visibility == "all" && visibilityOld == "custom") {
					// visibility has changed from "custom" to "all", so call
					// delete API for the existing keys in visibility info
					setShowLoaderOnButton(true);
					let deleteKeys = Object.keys(formDetails)
						.filter(key => key != "previewCount")
						.join(",");
					try {
						await deleteJobAttributes(jobID, deleteKeys);
					} catch (e) {
						// error
					}
					callEditJob({ visibility: "all" });
				} else {
					// visibility is "all" and visibilityOld is "all"
					saveAndNext({ visibility: "all" });
				}
			} else {
				// create mode
				if (visibility == "all") {
					saveAndNext({ visibility: "all" });
				} else {
					let temp = {};
					for (let key in formDetails) {
						if (formDetails[key].length && key != "previewCount") {
							temp[key] = formDetails[key];
						}
					}
					if (Object.keys(temp).length) {
						temp["visibility"] = "custom";
					} else {
						temp["visibility"] = "all";
					}
					saveAndNext(temp);
				}
			}
		} else {
			setError(tempError);
			setHelperText(tempHelperText);
		}
	};

	const handleCustomVisibility = async (jobID, visibilityOld) => {
		setShowLoaderOnButton(true);
		let temp = {};
		let filterExists = false;
		let deleteKeys = [];
		/* check all fields in form whether a (1)field has some value or (2)no value
		(1)If field has some value, check if it has changed from what it was earlier,
		if yes add it in "temp" obj
		(2)If the field has no value and if old visibility was "custom",
		then add the field to array of keys to be deleted */
		for (let key in formDetails) {
			if (key != "previewCount") {
				if (
					typeof formDetails[key] == "number" ||
					formDetails[key].length
				) {
					filterExists = true;
					if (formDetails[key] !== visibilityInfo[key]) {
						temp[key] = formDetails[key];
					}
				} else if (
					!formDetails[key].length &&
					visibilityOld == "custom" &&
					visibilityInfo[key]
				) {
					deleteKeys.push(key);
				}
			}
		}
		if (deleteKeys.length) {
			try {
				await deleteJobAttributes(jobID, deleteKeys.join(","));
			} catch (e) {
				// error
			}
		}
		if (Object.keys(temp).length) {
			// if visibility was 'all' earlier then change it
			if (visibilityOld == "all") temp["visibility"] = "custom";
			callEditJob(temp);
		} else {
			if (visibilityOld == "custom" && !filterExists) {
				callEditJob({ visibility: "all" });
			} else {
				saveAndNext({
					visibility: "custom",
					...formDetails
				});
			}
		}
	};

	const label1 = styles.labelOnlyAdditionalProps;
	const label2 = cx(label1, styles.labelBasicProps, styles.labelMB);
	const label3 = cx(
		label1,
		styles.labelBasicProps,
		styles.labelLight,
		styles.labelFlex30
	);

	const _inputWithUnitsProps = {
		placeholder: "00",
		inputClassName: styles.smallInput55,
		inputLabelClassName: label3,
		unitsLabelClassName: styles.unitsLabel,
		onChange: handleInputChange,
		disabled: !radioValue || isCalculatingCount ? true : false,
		regex: regexStrings.TWO_DIGITS_TWO_DECIMALS,
		removeTrailingDecimal: true
	};

	return (
		<div className={styles.pageContainer}>
			<div className={styles.row}>
				<div className={styles.headerDiv}>
					<label className={styles.pageLabel}>Visibility&nbsp;</label>
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.col50}>
					<RadioGroup
						options={visibilityOptions}
						disabled={isCalculatingCount}
						name="visibility"
						value={"" + radioValue}
						customLabelClassName={styles.customLabel}
						labelClassName={styles.radioLabel}
						onChange={handleRadioChange}
					/>
				</div>
				<PreviewCountCard
					dataObj={{
						...formDetails,
						cohorts: selectedCohorts
					}}
					countReset={isVisibilityCountReset}
					isCustomVisibility={!!radioValue}
					disableRefresh={!!radioValue && !!Object.keys(error).length}
					onRefresh={handleCountRefresh}
					onCalculating={handleCountCalculating}
				/>
			</div>
			<hr />
			<div
				className={
					radioValue && !isCalculatingCount ? {} : styles.inactive
				}
			>
				{!!radioValue && (
					<div className={styles.row}>
						<label className={styles.pageLabelLight}>
							( All fields on this page are optional. If all
							fields are left blank, visibility will be set to
							all. )
						</label>
					</div>
				)}
				<div className={styles.row}>
					<div className={styles.col50}>
						<LocationSelect
							label="Job locations"
							name="vLocation"
							placeholder="Mumbai"
							labelClassName={label1}
							onChange={handleInputChange}
							badgeItems={formDetails.vLocation}
							disabled={
								!radioValue || isCalculatingCount ? true : false
							}
						/>
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.col50}>
						<label className={label2}>Total experience</label>
						<InputWithUnits
							inputLabel="Minimum"
							unitsLabel="Years"
							name="vTotalExpMin"
							value={formDetails.vTotalExpMin || ""}
							error={error.vTotalExpMin || false}
							{..._inputWithUnitsProps}
						/>
						<InputWithUnits
							inputLabel="Maximum"
							unitsLabel="Years"
							name="vTotalExpMax"
							value={formDetails.vTotalExpMax || ""}
							error={error.vTotalExpMax || false}
							helperText={helperText.vTotalExp}
							{..._inputWithUnitsProps}
						/>
					</div>
					<div className={styles.col50}>
						<label className={label2}>Relevant experience</label>
						<InputWithUnits
							inputLabel="Minimum"
							unitsLabel="Years"
							name="vRelevantExpMin"
							value={formDetails.vRelevantExpMin || ""}
							error={error.vRelevantExpMin || false}
							{..._inputWithUnitsProps}
						/>
						<InputWithUnits
							inputLabel="Maximum"
							unitsLabel="Years"
							name="vRelevantExpMax"
							value={formDetails.vRelevantExpMax || ""}
							error={error.vRelevantExpMax || false}
							helperText={helperText.vRelevantExp}
							{..._inputWithUnitsProps}
						/>
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.col50}>
						<label className={label2}>
							Salary range (lakhs per annum)
						</label>
						<InputWithUnits
							inputLabel="Minimum"
							unitsLabel="LPA"
							name="vSalaryFrom"
							value={formDetails.vSalaryFrom || ""}
							error={error.vSalaryFrom || false}
							{..._inputWithUnitsProps}
						/>
						<InputWithUnits
							inputLabel="Maximum"
							unitsLabel="LPA"
							name="vSalaryTo"
							value={formDetails.vSalaryTo || ""}
							error={error.vSalaryTo || false}
							helperText={helperText.vSalary}
							{..._inputWithUnitsProps}
						/>
					</div>
					<div className={styles.col50}>
						<label className={label2}>Notice period</label>
						<InputWithUnits
							inputLabel="Maximum"
							unitsLabel="Days"
							name="vNotice"
							value={formDetails.vNotice || ""}
							{..._inputWithUnitsProps}
							regex={regexStrings.THREE_DIGITS}
						/>
					</div>
				</div>
			</div>
			<div className={styles.rowFlexEnd}>
				<Button
					className={styles.saveButton}
					onClick={handleSubmitClick}
					disabled={showLoaderOnButton}
					isLoading={showLoaderOnButton}
				>
					Save and next
				</Button>
			</div>
		</div>
	);
};

VisibilityInfo.propTypes = {
	jobInfo: PropTypes.object.isRequired,
	jobActions: PropTypes.object.isRequired,
	nextIndex: PropTypes.func.isRequired,
	index: PropTypes.string.isRequired,
	selectedCohorts: PropTypes.string.isRequired
};

function mapStateToProps(state) {
	return {
		jobInfo: state.jobInfo
	};
}

function mapDispatchToProps(dispatch) {
	return {
		jobActions: bindActionCreators(jobActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(VisibilityInfo);
