import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import CircularSpinner from "shared/CircularSpinner";
import styles from "./index.scss";

export default class Button extends Component {
	static propTypes = {
		onClick: PropTypes.func,
		children: PropTypes.node,
		variant: PropTypes.string,
		className: PropTypes.string,
		label: PropTypes.string,
		width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		disabledClassName: PropTypes.string,
		disableRipple: PropTypes.bool,
		disabled: PropTypes.bool,
		noShadow: PropTypes.bool,
		isLoading: PropTypes.bool
	};

	static defaultProps = {
		className: "",
		label: "",
		variant: "primary",
		disabled: false,
		noShadow: false,
		disabledClassName: "",
		isLoading: false
	};

	handleButtonClick = event => {
		const { onClick, disabled } = this.props;

		if (disabled) return;
		/*
            Do something before click event is passed to business logic
            like tracking for analytics
        */
		onClick &&
			onClick({
				event
			});
	};

	renderChildren = () => {
		const { label, children } = this.props;

		if (label) return label;
		return children;
	};

	render() {
		const {
			className,
			variant,
			noShadow,
			disabled,
			disableRipple,
			disabledClassName,
			isLoading
		} = this.props;

		const _className = cx(
			className,
			styles.button,
			{ [styles.ripple]: !disableRipple },
			{ [styles.shadow]: !noShadow },
			styles[variant],
			{
				[styles.disabled]: disabled || isLoading,
				[disabledClassName]: disabled
			}
		);

		return (
			<div onClick={this.handleButtonClick} className={_className}>
				{isLoading && (
					<div className={styles.loading}>
						<CircularSpinner
							className={styles.spinnerDiv}
							circleClassName={styles.circle}
						/>
					</div>
				)}
				<div>{this.renderChildren()}</div>
			</div>
		);
	}
}
