import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getVisibility } from "apis";
import cx from "classnames";
import styles from "../../../index.scss";
import SpinnerWithText from "shared/SpinnerWithText";
import greenRefresh from "images/upgrad/greenRefresh.svg";
import blueDownArrow from "images/upgrad/blueDownArrow.svg";
import EmailExportModal from "shared/EmailExportModal";

const PreviewCountCard = ({
	dataObj,
	countReset,
	isCustomVisibility,
	disableRefresh,
	onRefresh,
	onCalculating,
	jobInfo
}) => {
	const { visibilityInfo } = jobInfo;
	const [showMore, setShowMore] = useState(false);
	const [emailModalProps, setEmailModalProps] = useState({
		visible: false,
		title: "",
		emails: []
	});
	const [isCalculating, setIsCalculating] = useState({
		all: false,
		custom: false
	});
	const [emails, setEmails] = useState(
		visibilityInfo && visibilityInfo.previewCount
			? visibilityInfo.previewCount.emails
			: {}
	);
	const [previewCount, setPreviewCount] = useState(
		visibilityInfo &&
			visibilityInfo.previewCount &&
			visibilityInfo.previewCount.count
			? visibilityInfo.previewCount.count
			: {
					custom: {},
					all: {}
			  }
	);

	useEffect(() => {
		if (countReset) {
			setPreviewCount({
				...previewCount,
				custom: {
					visibleTo: "-",
					meetCriterion: "-",
					defaultVisibility: "-",
					signedUp: "-",
					enrolled: "-",
					ineligible: "-"
				}
			});
		}
	}, [countReset]);

	useEffect(() => {
		if (isCalculating.all || isCalculating.custom) onCalculating(true);
		else onCalculating(false);
	}, [isCalculating]);

	const checkAccessibleUsers = (users, emailsTemp, visibility) => {
		//check each accessible user for appropriate count
		let meetCriterionCount = 0;
		users.forEach(user => {
			let categories = ["enrolled", "signedUp", "visibleTo"];
			const { missedParameters } = user.response;
			if (missedParameters && !missedParameters.length) {
				meetCriterionCount++;
				categories.push("meetCriterion");
			} else {
				categories.push("defaultVisibility");
			}
			emailsTemp[user.email] = {
				...emailsTemp[user.email],
				[visibility]: categories
			};
		});
		return { meetCriterionCount, emailsTemp };
	};

	const checkUnaccessibleUsers = (users, emailsTemp, visibility) => {
		// check each inaccessible user for appropriate
		// count of not signed up and ineligible users
		let ineligibleCount = 0;
		let notSignedUpCount = 0;
		users.forEach(user => {
			let categories = ["enrolled"];

			if (user.response.isIneligible) {
				ineligibleCount++;
				categories.push("ineligible");
			}

			if (user.response.isSignedUp) {
				categories.push("signedUp");
			} else {
				notSignedUpCount++;
			}
			emailsTemp[user.email] = {
				...emailsTemp[user.email],
				[visibility]: categories
			};
		});
		return { ineligibleCount, notSignedUpCount, emailsTemp };
	};

	const extractVisibilityData = (data, visibility) => {
		const {
			accessibleCount,
			accessibleUsers,
			unaccessibleUsers,
			totalCount
		} = data;
		let emailsTemp = { ...emails };
		let obj = checkAccessibleUsers(accessibleUsers, emailsTemp, visibility);
		let { meetCriterionCount } = obj;
		emailsTemp = obj.emailsTemp;

		obj = checkUnaccessibleUsers(unaccessibleUsers, emailsTemp, visibility);
		emailsTemp = obj.emailsTemp;
		let { notSignedUpCount, ineligibleCount } = obj;

		let previewCountTemp = {
			...previewCount,
			[visibility]: {
				visibleTo: accessibleCount,
				meetCriterion: meetCriterionCount,
				defaultVisibility: accessibleCount - meetCriterionCount,
				signedUp: totalCount - notSignedUpCount,
				enrolled: totalCount,
				ineligible: ineligibleCount
			}
		};
		setPreviewCount(previewCountTemp);
		setEmails(emailsTemp);
		onRefresh({
			count: previewCountTemp,
			emails: emailsTemp
		});
	};

	const refreshPreviewCount = async (visibility = "custom") => {
		if (!disableRefresh) {
			if (visibility === "all") {
				// call API with cohorts filter only
				setIsCalculating({
					all: true,
					custom: false
				});
				try {
					let response = await getVisibility({
						cohorts: dataObj.cohorts
					});
					if (response.status == 200) {
						extractVisibilityData(response.data, "all");
					} else {
						// write error toast code for no response from API
					}
				} catch (e) {
					// error
				}
			} else {
				setIsCalculating({
					all: false,
					custom: true
				});
				let filterParams = {};
				for (let key in dataObj) {
					if (
						dataObj.hasOwnProperty(key) &&
						dataObj[key].length !== 0 &&
						key !== "previewCount"
					) {
						filterParams[key] = dataObj[key];
					}
				}

				/* check if there are any input values in visibility fields,
				if yes only then call API with those filters */
				if (Object.keys(filterParams).length) {
					if (Object.keys(filterParams).includes("vLocation"))
						filterParams.vLocation = filterParams.vLocation.join(
							","
						);
					let response = await getVisibility(filterParams);
					if (response.status == 200) {
						extractVisibilityData(response.data, "custom");
					} else {
						// write error toast code for no response from API
					}
				}
			}
			setIsCalculating({
				all: false,
				custom: false
			});
		}
	};

	const handleShowMoreClick = () => {
		setShowMore(!showMore);
	};

	const handlePreviewCountClick = type => {
		showEmails(
			type,
			isCustomVisibility
				? "custom-" + previewCount.custom[type]
				: "all-" + previewCount.all[type]
		);
	};

	const showEmails = (type, visibilityWithCount) => {
		let modalTitle = camelToSpaceSeperated(type) + " - Emails";
		let tempEmails = [];
		const temp = visibilityWithCount.split("-");
		const visibility = temp[0];
		const count = parseInt(temp[1]);

		// open modal only if count is not zero and currently not calculating
		if (count && !isCalculating[visibility]) {
			for (let email in emails) {
				if (emails[email][visibility].includes(type))
					tempEmails.push(email);
			}
			setEmailModalProps({
				title: modalTitle,
				visible: true,
				emails: tempEmails
			});
		}
	};

	const camelToSpaceSeperated = camelCase => {
		// change helloWorld to Hello World
		let spaceSeperated = camelCase.replace(/([a-z])([A-Z])/g, "$1 $2");
		spaceSeperated =
			spaceSeperated[0].toUpperCase() + spaceSeperated.substring(1);
		return spaceSeperated;
	};

	const closeEM = () => {
		setEmailModalProps({
			title: "",
			visible: false,
			emails: []
		});
	};

	const renderIndividualTypeCount = type => {
		return isCustomVisibility
			? isCalculating.custom || previewCount.custom[type] === undefined
				? "-"
				: previewCount.custom[type]
			: isCalculating.all || previewCount.all[type] === undefined
			? "-"
			: previewCount.all[type];
	};

	const renderCount = visibility => {
		return (
			<div className={styles.data}>
				<label className={styles.heavyText}>
					{previewCount[visibility].visibleTo}
					&nbsp;
				</label>
				<label>
					out of&nbsp;
					{previewCount[visibility].enrolled}
				</label>
			</div>
		);
	};

	const renderInlineSpinner = () => {
		return (
			<div className={styles.spinnerDiv}>
				<SpinnerWithText
					text="Calculating"
					textClassName={styles.spinnerText}
					isInline={true}
				/>
			</div>
		);
	};

	const renderMessage = type => {
		let message = 'Click "Refresh" to get updated count';
		if (type === "Calculate")
			message = 'Click "Calculate" to get visibility count';
		return (
			<div className={styles.data}>
				<div className={styles.message}>
					<span>{message}</span>
				</div>
			</div>
		);
	};

	const renderRelevantCount = () => {
		let isCountAllNotCalc =
			!isCustomVisibility &&
			!Object.keys(previewCount.all).length &&
			!isCalculating.all;
		let isCountCustomNotCalc =
			isCustomVisibility &&
			!Object.keys(previewCount.custom).length &&
			!isCalculating.custom;
		let tempRender;

		if (isCountAllNotCalc || isCountCustomNotCalc) {
			tempRender = renderMessage("Calculate");
		} else if (isCustomVisibility && !isCalculating.custom) {
			if (countReset){
				tempRender = renderMessage("Refresh");
			} else {
				tempRender = renderCount("custom");
			}
		} else if (!isCustomVisibility && !isCalculating.all) {
			tempRender = renderCount("all");
		} else {
			tempRender = renderInlineSpinner();
		}
		return tempRender;
	};

	const renderTypeRow = type => {
		return (
			<div className={styles.cardRow}>
				<span className={styles.text}>
					{camelToSpaceSeperated(type)}
				</span>
				<span
					className={styles.number}
					onClick={() => handlePreviewCountClick(type)}
				>
					{renderIndividualTypeCount(type)}
				</span>
			</div>
		);
	};

	const renderInitialTypeRows = () => {
		return (
			<>
				{renderTypeRow("visibleTo")}
				{renderTypeRow("meetCriterion")}
				{renderTypeRow("defaultVisibility")}
			</>
		);
	};

	const renderAdditionalTypeRows = () => {
		return (
			<>
				{renderTypeRow("signedUp")}
				{renderTypeRow("enrolled")}
				{renderTypeRow("ineligible")}
			</>
		);
	};

	const renderRefreshButton = () => {
		const isVisibilityCustomCountEmpty =
			isCustomVisibility &&
			!isCalculating.custom &&
			!Object.keys(previewCount.custom).length;

		const isVisibilityAllCountEmpty =
			!isCustomVisibility &&
			!isCalculating.all &&
			!Object.keys(previewCount.all).length;

		let showTextAsCalculate =
			isVisibilityCustomCountEmpty || isVisibilityAllCountEmpty;

		if (
			isCalculating.all ||
			isCalculating.custom ||
			(!isCustomVisibility &&
				!isCalculating.all &&
				Object.keys(previewCount.all).length)
		) {
			return;
		} else {
			return (
				<div
					className={cx(styles.bottom, {
						[styles.disabled]: disableRefresh
					})}
				>
					<div
						className={styles.refreshDiv}
						onClick={() =>
							refreshPreviewCount(
								isCustomVisibility ? "custom" : "all"
							)
						}
					>
						<img src={greenRefresh} alt="Refresh" />
						<span className={styles.refresh}>
							{showTextAsCalculate ? "Calculate" : "Refresh"}
						</span>
					</div>
				</div>
			);
		}
	};

	return (
		<div className={styles.previewCard}>
			<div className={styles.left}>
				<div className={styles.top}>
					<div className={styles.previewHeader}>
						<label>No. of learners visible to:</label>
					</div>
					{renderRelevantCount()}
				</div>
				{renderRefreshButton()}
			</div>
			<div className={styles.right}>
				<div className={styles.top}>
					{renderInitialTypeRows()}
					{showMore && <>{renderAdditionalTypeRows()}</>}
					<EmailExportModal {...emailModalProps} onClose={closeEM} />
				</div>
				<div className={styles.bottom}>
					<div
						className={styles.showMoreDiv}
						onClick={handleShowMoreClick}
					>
						<span className={styles.showMore}>
							Show {showMore ? "less" : "more"}
						</span>
						<img
							className={cx(
								showMore ? styles.upArrow : styles.downArrow
							)}
							src={blueDownArrow}
							alt="Home"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PreviewCountCard.propTypes = {
	jobInfo: PropTypes.object.isRequired,
	countReset: PropTypes.bool.isRequired,
	dataObj: PropTypes.object.isRequired,
	isCustomVisibility: PropTypes.bool.isRequired,
	disableRefresh: PropTypes.bool.isRequired,
	onRefresh: PropTypes.func.isRequired,
	onCalculating: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		jobInfo: state.jobInfo
	};
}

export default connect(mapStateToProps, null)(PreviewCountCard);
