import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import * as userActions from "actions/user";
import * as navigationActions from "actions/navigation";
import * as errorActions from "actions/error";
import { routesPath } from "app/components/Router";
import Input from "widgets/Input";
import Button from "widgets/Button";
import Alert from "widgets/Alert";
import styles from "./index.scss";
import { EXTERNAL_ROLES } from "constants/roleMapping";

const LoginPage = props => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState({ error: false, message: "" });

	useEffect(() => {
		const { userInfo, navigationActions, errorInfo } = props;
		const { isLoggedIn, roles } = userInfo;
		if (isLoggedIn) {
			let redirectTo = routesPath.attributes.route;
			for (let i = 0; i < EXTERNAL_ROLES.length; i++) {
				if (roles.includes(EXTERNAL_ROLES[i])) {
					redirectTo = routesPath.jobOpenings.route;
					break;
				}
			}
			navigationActions.redirect(redirectTo);
		}
		if (errorInfo.message !== "") {
			setIsLoading(false);
		}
	}, [props.userInfo.isLoggedIn, props.errorInfo.message]);

	const handleInputChange = ({ name, value }) => {
		if (name === "email") {
			setEmail(value);
		} else if (name === "password") {
			setPassword(value);
		}
	};

	const shouldSubmitDisable = () => {
		return _.isEmpty(email) || _.isEmpty(password);
	};

	const handleKeyPressed = ({ key }) => {
		if (key === "Enter" && !shouldSubmitDisable()) {
			handleSubmitClick();
		}
	};

	const validateEmail = email => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	};

	const handleSubmitClick = () => {
		const validEmail = validateEmail(email);
		if (validEmail) {
			const loginUser = props.userActions.loginUser;
			setIsLoading(true);
			loginUser({ email, password });
		} else {
			setError({ error: true, message: "Invalid email address" });
			setEmail("");
			setPassword("");
			setTimeout(() => {
				setError({ error: false, message: "" });
			}, 5000);
		}
	};

	const onErrClose = () => {
		const { errorActions } = props;
		setTimeout(() => {
			errorActions.clearError();
		}, 1000);
	};

	return (
		<Fragment>
			<div className={styles.wrapper}>
				<form className={styles.box} onSubmit={handleSubmitClick}>
					<div className={styles.header}>Login</div>
					<div className={styles.content}>
						<Input
							label="Email"
							name="email"
							placeholder="name@upgrad.com"
							className={styles.userType}
							value={email}
							onKeyDown={handleKeyPressed}
							onChange={handleInputChange}
						/>
						<Input
							type="password"
							label="Password"
							name="password"
							placeholder="your password"
							className={styles.userType}
							value={password}
							onKeyDown={handleKeyPressed}
							onChange={handleInputChange}
						/>
						{(error.error || props.errorInfo.message !== '') && (
							<Alert
								type="error"
								message={
									error.error
										? error.message
										: props.errorInfo.message
								}
								showIcon={true}
								onClose={onErrClose}
								closable
							/>
						)}
					</div>
					<div className={styles.footer}>
						<Button
							type="submit"
							label="Submit"
							disabled={shouldSubmitDisable()}
							onClick={handleSubmitClick}
							isLoading={isLoading}
						/>
					</div>
				</form>
			</div>
		</Fragment>
	);
};

LoginPage.propTypes = {
	userInfo: PropTypes.object.isRequired,
	userActions: PropTypes.object.isRequired,
	navigationActions: PropTypes.object.isRequired,
	errorActions: PropTypes.object.isRequired,
	errorInfo: PropTypes.object.isRequired
};
function mapStateToProps(state) {
	return {
		userInfo: state.userInfo,
		errorInfo: state.errorInfo
	};
}
function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(userActions, dispatch),
		navigationActions: bindActionCreators(navigationActions, dispatch),
		errorActions: bindActionCreators(errorActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginPage);
