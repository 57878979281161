import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import PropTypes from "prop-types";
import styles from "./index.scss";
import cx from "classnames";

const AutoComplete = props => {
	const [item, setItem] = useState("");
	const { name, placeholder, onChange, disabled } = props;

	const handleChange = e => {
		e.preventDefault();
		const userInput = e.target.value;
		setItem(userInput);
	};

	const handlePlaceSelected = place => {
		setItem("");
		onChange && onChange(name, place);
	};

	const _inputClassName = cx(styles.input, {
		[styles.hasError]: props.error
	});

	return (
		<Autocomplete
			name={name}
			value={item}
			placeholder={placeholder}
			className={_inputClassName}
			onPlaceSelected={handlePlaceSelected}
			onChange={handleChange}
			types={["(cities)"]}
			disabled={disabled}
		/>
	);
};

AutoComplete.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	error: PropTypes.bool
};

export default AutoComplete;
