import constants from "constants";
import createInstanceWithSessionDetails from "./helper";

const candidateServiceApiInstance = createInstanceWithSessionDetails(
	constants.candidateApiServerUrl
);

const URL = {
	candidates: "candidates",
	jobApplication: "candidates/:jobID",
	resumeLink: "candidates/resume/:userId?filePath=:s3ResumeLink",
	updateStatus: "candidates/jobs/:jobId/status",
	domain: "candidates/domains",
	extractApplications: "candidates/extract",
	downloadReport: "candidates/jobs/:jobId/report",
};

export const getAllCandidates = async function (data) {
	try {
		let url = URL.candidates;
		const response = await candidateServiceApiInstance.post(url, data, {
			headers: { "Content-Type": "application/json" },
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getCandidateApplications = async function (jobID, data) {
	try {
		let url = URL.jobApplication.replace(":jobID", jobID);
		const response = await candidateServiceApiInstance.post(url, data, {
			headers: { "Content-Type": "application/json" },
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getCandidateResumeLink = async function (userId, s3ResumeLink) {
	try {
		let url = URL.resumeLink.replace(":userId", userId);
		url = url.replace(":s3ResumeLink", s3ResumeLink);
		const response = await candidateServiceApiInstance.get(url, {
			responseType: "blob",
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const updateCandidateApplicationStatus = async function (jobId, data) {
	try {
		let url = URL.updateStatus.replace(":jobId", jobId);
		const response = await candidateServiceApiInstance.post(url, data, {
			headers: { "Content-Type": "application/json" },
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getAllDomains = async function () {
	try {
		let url = URL.domain;
		const response = await candidateServiceApiInstance.get(url);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const extractCandidateApplications = async function (data) {
	try {
		let url = URL.extractApplications;
		const response = await candidateServiceApiInstance.post(url, data, {
			responseType: "blob",
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getDownloadReport = async function (jobId) {
	try {
		let url = URL.downloadReport.replace(":jobId", jobId);
		const response = await candidateServiceApiInstance.get(url, {
			responseType: "blob",
		});
		return response;
	} catch (error) {
		return error.response;
	}
};
