import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as jobActions from "actions/job";
import * as navigationActions from "actions/navigation";
import * as errorActions from "actions/error";
import { routesPath } from "app/components/Router";
import PropTypes from "prop-types";
import { createJob, editJob } from "apis";
import styles from "../index.scss";
import Button from "widgets/Button";
import RadioGroup from "widgets/RadioGroup";
import Alert from "widgets/Alert";

const JobStatus = props => {
	const [radioValue, setRadioValue] = useState(
		props.jobInfo.basicInfo.isPublished === "True" ? 1 : 0
	);
	const [jobInfo, setJobInfo] = useState(props.jobInfo);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		let isMounted = true;
		let { basicInfo, visibilityInfo, editInfo } = jobInfo;

		if (editInfo.mode === "create") {
			// in create mode, format fields before API call is made

			if (isMounted) {
				if (props.userInfo.roles.includes("ROLE_RECRUITER")) {
					setJobInfo({
						basicInfo: {
							...basicInfo,
							cohorts: "",
							programs: props.selectedRelevantForOptions
						},
						visibilityInfo,
						editInfo
					});
				} else {
					setJobInfo({
						basicInfo: {
							...basicInfo,
							cohorts: props.selectedCohorts
						},
						visibilityInfo,
						editInfo
					});
				}
			}
		}
		return () => {
			isMounted = false;
		};
	}, []);

	const handleRadioChange = ({ value }) => {
		setRadioValue(parseInt(value));
	};

	const handleClick = async () => {
		setIsLoading(true);
		const is_published = parseInt(radioValue) ? "True" : "False";
		let { basicInfo, editInfo } = jobInfo;

		if (editInfo.mode === "edit") {
			const is_published_old = basicInfo.isPublished;

			if (is_published !== is_published_old) {
				let { jobID } = editInfo;
				let response = await editJob(
					{ isPublished: is_published },
					jobID
				);
				if (response) {
					props.navigationActions.redirect(routesPath.jobOpenings.route);
				} else {
					// write error toast code for no response from API
				}
			} else {
				props.navigationActions.redirect(routesPath.jobOpenings.route);
			}
		} else {
			let response = await createJob(is_published, jobInfo);
			if (response) {
				setIsLoading(false);
				if (response.status === 201 || response.status === 204) {
					props.navigationActions.redirect(routesPath.jobOpenings.route);
				} else if (response.status === 409) {
					props.errorActions.setError(response.data.message);
					setTimeout(() => {
						props.errorActions.clearError();
					}, 10000);
				} else {
					props.errorActions.setError(
						"Sorry some error has occured, please try again."
					);
					setTimeout(() => {
						props.errorActions.clearError();
					}, 10000);
				}
			} else {
				// write error toast code for no response from API
			}
		}
	};

	return (
		<div className={styles.pageContainer}>
			<div className={styles.pageLabel}>Job Opening Status</div>
			<div className={styles.row}>
				<div className={styles.col50}>
					<RadioGroup
						options={[
							{
								value: "0",
								label: "Draft"
							},
							{
								value: "1",
								label: "Publish"
							}
						]}
						name="visibility"
						value={"" + radioValue}
						customLabelClassName={styles.customLabel}
						labelClassName={styles.radioLabel}
						onChange={handleRadioChange}
					/>
				</div>
			</div>
			<div className={styles.colFlex}>
				{props.errorInfo.message && (
					<Alert
						type="error"
						message="Error!"
						description={props.errorInfo.message}
						showIcon={true}
						className={styles.errorBox}
					/>
				)}
				<div className={styles.rowFlexEnd}>
					<Button
						className={styles.saveButton}
						onClick={handleClick}
						disabled={isLoading}
						isLoading={isLoading}
					>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
};

JobStatus.propTypes = {
	jobInfo: PropTypes.object.isRequired,
	userInfo: PropTypes.object.isRequired,
	jobActions: PropTypes.object.isRequired,
	navigationActions: PropTypes.object.isRequired,
	errorActions: PropTypes.object.isRequired,
	index: PropTypes.string.isRequired,
	errorInfo: PropTypes.object.isRequired,
	selectedCohorts: PropTypes.string,
	selectedRelevantForOptions: PropTypes.string
};

function mapStateToProps(state) {
	return {
		jobInfo: state.jobInfo,
		errorInfo: state.errorInfo,
		userInfo: state.userInfo
	};
}

function mapDispatchToProps(dispatch) {
	return {
		jobActions: bindActionCreators(jobActions, dispatch),
		navigationActions: bindActionCreators(navigationActions, dispatch),
		errorActions: bindActionCreators(errorActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(JobStatus);
