import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { routesPath } from "app/components/Router";
import * as userActions from 'actions/user';
import * as navigationActions from "actions/navigation";

const LogoutRoute = (props) => {
	const { userActions, navigationActions, userInfo } = props;
	const logout = () => {
		document.getElementById("pageLoaderRoot").classList.remove("hide");
		userActions.logoutUser(userInfo.authToken);
		setTimeout(() => {
			document.getElementById("pageLoaderRoot").classList.add("hide");
			navigationActions.redirect(routesPath.login.route);
		}, 2000);
	}

	return (
		<>
			{logout()}
		</>
	);
}

LogoutRoute.propTypes = {
	userInfo: PropTypes.object,
	userActions: PropTypes.object,
	navigationActions: PropTypes.object,
};

function mapStateToProps(state) {
	return {
		userInfo: state.userInfo
	};
}

function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(userActions, dispatch),
		navigationActions: bindActionCreators(navigationActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutRoute);
