import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "widgets/Select";
import styles from "./index.scss";

const CohortSelector = props => {
	const [_value, setValue] = useState({});
	const { label, value, ..._props } = props;

	useEffect(() => {
		if (value !== undefined && value !== "") setValue({ value: value });
	}, []);

	const handleChange = ({ value }) => {
		setValue({ value: value });
		_props.onChange(value);
	};

	return (
		<Fragment>
			{label ? <label className={styles.label}>{label}</label> : null}
			<Select
				showSearch
				mode="default"
				name="CohortSelector"
				placeholder="Select Cohort"
				{..._value}
				onChange={handleChange}
				{..._props}
			/>
		</Fragment>
	);
};

CohortSelector.propTypes = {
	onChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	isLoading: PropTypes.bool,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.string
};

export default CohortSelector;
