import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "widgets/Modal";
import Alert from "widgets/Alert";
import { addNewCompany } from "apis";
import Input from "widgets/Input";

const AddNewCompany = ({
	visible,
	onOk,
	onCancel
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState({ error: false, message: "" });
	const [companyName, setCompanyName] = useState("");

	useEffect(() => {
		let timeOut;
		if (error.error) {
			timeOut = setTimeout(() => {
				if (error.error) setError({ error: false, message: "" });
			}, 10000);
		}
		return () => {
			clearTimeout(timeOut);
		};
	}, [error]);

	const handleInputChange = ({ value }) => {
		setCompanyName(value);
	};

	const handleOk = () => {
		setIsLoading(true);
		(async () => {
			let response = await addNewCompany({
				name: companyName,
				account_manager: "EXTERNAL"
			});
			if ([200, 201].includes(response.status)) {
				onOk(companyName);
				setCompanyName("");
			} else if (response.status === 409) {
				setError({
					error: true,
					message: response.data.message
				});
			} else {
				setError({
					error: true,
					message:
						"Sorry, some error has occured. Please try again or check with admin."
				});
			}
			setIsLoading(false);
		})();
	};

	const handleCancel = () => {
		onCancel();
		setCompanyName("");
	};

	return (
		<div>
			<Modal
				title={"Add New Company"}
				visible={visible}
				closable={false}
				okText="CONFIRM"
				onOk={handleOk}
				okButtonProps={{ loading: isLoading }}
				onCancel={handleCancel}
				destroyOnClose={true}
			>
				<Input
					name="companyName"
					placeholder="Enter a name"
					value={companyName}
					onChange={handleInputChange}
				/>
				{error.error && (
					<Alert
						type="error"
						message={error.message}
						showIcon={true}
					/>
				)}
			</Modal>
		</div>
	);
};

AddNewCompany.propTypes = {
	visible: PropTypes.bool.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};
export default AddNewCompany;
