import actionTypes from "actionTypes";

export function setError(message) {
	return dispatch => {
		dispatch({
			type: actionTypes.SET_ERROR,
			payload: {
				message
			}
		});
	};
}

export function clearError() {
	return dispatch => {
		dispatch({
			type: actionTypes.CLEAR_ERROR
		});
	};
}
