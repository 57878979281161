import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ServerError from "widgets/ServerError";
import styles from "./index.scss";
import CohortSelector from "shared/CohortSelector";
import CategorySelector from "shared/CategorySelector";
import SpinnerWithText from "shared/SpinnerWithText";
import AttributesTable from "./components/AttributesTable";
import CreateAttributeModal from "./components/CreateAttributeModal";
import { getAttributes, getAllCohorts } from "apis";
import { routesPath } from "app/components/Router";
import Button from "widgets/Button";
import Input from "widgets/Input";
import { ADMIN_ROLES } from "constants/roleMapping";
import { RBAC } from "hoc";

const AttributeListing = () => {
	const [cohortId, setCohortId] = useState(null);
	const [dataSource, setDataSource] = useState(null);
	const [filteredDataSource, setFilteredDataSource] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [isFetchingAttributes, setIsFetchingAttributes] = useState(false);
	const [cohortOptions, setCohortOptions] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			let response = await getAllCohorts();
			if (isMounted)
				if (response) {
					const options = response.data.cohorts.map(cohort => ({
						label: `${cohort.externalId} - ${cohort.name}`,
						value: cohort.externalId
					}));
					setCohortOptions(options);
					setIsLoading(false);
				} else {
					// write error toast code for no response from API
				}
		};

		fetchData();

		return () => {
			isMounted = false;
		};
	}, []);

	const fetchData = async type => {
		setIsFetchingAttributes(true);
		let response = await getAttributes({
			course_id: cohortId,
			type
		});
		setIsFetchingAttributes(false);
		if (response) {
			createDataSource(response.data.keys);
		} else {
			setHasError(true);
			// write error toast code for no response from API
		}
	};

	const createDataSource = attributes => {
		let dataSource = attributes.map((attribute, index) => ({
			key: index + 1,
			attributeName: attribute
		}));
		setDataSource(dataSource);
	};

	const handleInputChange = ({ value }) => {
		let tempDataSource = [];
		setSearchValue(value);
		tempDataSource = dataSource.filter(
			row =>
				row.attributeName.toLowerCase().indexOf(value.toLowerCase()) !==
				-1
		);
		setFilteredDataSource(tempDataSource);
	};

	const handleCohortSelectorChange = ({ value }) => {
		const temp = cohortOptions.filter(cohort => cohort.label === value);
		setDataSource(null);
		setCohortId(temp[0].value);
	};

	const handleCategorySelectorChange = category => {
		setDataSource(null);
		if (category) fetchData(category);
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const renderAttributes = () => {
		if (isFetchingAttributes) {
			return <SpinnerWithText text="Fetching attributes..." />;
		}
		if (hasError) {
			return <ServerError />;
		}
		if (dataSource) {
			return (
				<AttributesTable
					dataSource={
						filteredDataSource ? filteredDataSource : dataSource
					}
				/>
			);
		}
		return null;
	};

	return (
		<>
			<div className={styles.secondaryHeaderOuter}>
				<div className={styles.secondaryHeaderInner}>
					<div className={styles.navList}>
						<NavLink
							exact
							to={routesPath.attributes.route}
							className={styles.navItem}
							activeClassName={styles.active}
						>
							Reference
						</NavLink>
						<NavLink
							exact
							to={routesPath.uploadData.route}
							className={styles.navItem}
							activeClassName={styles.active}
						>
							Upload Data
						</NavLink>
						<RBAC allowedRoles={ADMIN_ROLES}>
							<Button
								className={styles.createAttributeButton}
								onClick={showModal}
							>
								Create attribute
							</Button>
							<CreateAttributeModal
								visible={isModalVisible}
								onOk={handleOk}
								onCancel={handleCancel}
							/>
						</RBAC>
					</div>
				</div>
			</div>
			<div className={styles.bodyOuter}>
				<div className={styles.bodyInner}>
					<div className={styles.optionsBar}>
						<div className={styles.selectorRow}>
							<div className={styles.selector}>
								<CohortSelector
									options={cohortOptions}
									loading={isLoading}
									onChange={handleCohortSelectorChange}
								/>
							</div>
							{cohortId && (
								<div className={styles.selector}>
									<CategorySelector
										cohortId={cohortId}
										onChange={handleCategorySelectorChange}
									/>
								</div>
							)}
						</div>
						<div className={styles.searchDiv}>
							{dataSource && dataSource.length !== 0 && (
								<Input
									type="text"
									name="searchText"
									placeholder="Search"
									inputClassName={styles.searchInput}
									value={searchValue}
									onChange={handleInputChange}
								/>
							)}
						</div>
					</div>
					<div>{renderAttributes()}</div>
				</div>
			</div>
		</>
	);
};

export default AttributeListing;
