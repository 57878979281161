import React from "react";
import PropTypes from "prop-types";
import Modal from "widgets/Modal";

const EmailExportModal = ({ title, visible, emails, onClose }) => {
	const handleOk = async () => {
		let emailsCSV = "emails\n" + emails.join("\n");
		let blob = await new Blob([emailsCSV], {
			type: "application/csv"
		});
		let objectUrl = window.URL.createObjectURL(blob);
		let link = document.createElement("a");
		document.getElementById("extract").appendChild(link);
		link.href = objectUrl;
		link.download = "emails.csv";
		link.click();
		document.getElementById("extract").removeChild(link);
		onClose();
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<Modal
			title={title}
			visible={visible}
			closable={false}
			okText="EXTRACT AND CLOSE"
			onOk={handleOk}
			onCancel={handleCancel}
			destroyOnClose={true}
		>
			<div style={{ height: "50vh", overflowX: "scroll" }}>
				{emails.map((email, index) => (
					<div key={index}>{email}</div>
				))}
			</div>
			<div id="extract" />
		</Modal>
	);
};

EmailExportModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	emails: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired
};
export default EmailExportModal;
