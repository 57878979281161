import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as jobActions from "actions/job";
import { getAllCohorts, getJobDetails, getRelevantForList } from "apis";
import styles from "./index.scss";
import TabComponent from "shared/TabComponent";
import SpinnerWithText from "shared/SpinnerWithText";
import BasicInfo from "./BasicInfo";
import VisibilityInfo from "./VisibilityInfo";
import JobStatus from "./JobStatus";
import { EXTERNAL_ROLES } from "constants/roleMapping";

const CreateJobOpening = props => {
	const [index, setIndex] = useState(0);
	const [allowedTabs, setAllowedTabs] = useState([0]);
	const [cohortOptions, setCohortOptions] = useState({
		all: {},
		selected: {}
	});
	const [relevantForOptions, setRelevantForOptions] = useState({
		all: [],
		selected: []
	});
	const [spinnerText, setSpinnerText] = useState("Preparing form...");
	const [isFetchingDetails, setIsFetchingDetails] = useState(true);
	const [mode, setMode] = useState(
		props.match.path === "/jobs/edit/:jobID"
		? "edit"
		: props.match.path === "/jobs/review/:jobID"
			? "review"
			: props.match.path === "/jobs/view/:jobID"
				? "view"
				: "create"
	)

	useEffect(() => {
		let isMounted = true;
		const { match } = props;
		let tempRelevantForOptions = [];

		const fetchRelevantForList = async () => {
			const response = await getRelevantForList();
			if(response.status === 200) {
				tempRelevantForOptions = response.data.data.map(obj => {
					return {
						label: obj.name,
						value: obj.id,
						key: obj.key
					}
				})
				setRelevantForOptions({ ...relevantForOptions, all: tempRelevantForOptions });
			}
		}
		fetchRelevantForList();


		const fetchCohorts = async () => {
			try {
				let response = await getAllCohorts();
				if (response) {
					const { cohorts } = response.data;
					const cohort_options = cohorts.map(cohort => ({
						label: cohort.name.trim(),
						value: cohort.externalId
					}));
					if (isMounted) {
						setCohortOptions({
							...cohortOptions,
							all: cohort_options
						});
						if (match.path === "/jobs/edit/:jobID" || ["view", "review"].includes(mode)) {
							setSpinnerText("Fetching job details...");
							const fetchData = async () => {
								let response = await getJobDetails({
									jobID: match.params.jobID
								});
								if (response) {
									let jobData = {};
									if(props.userInfo.roles.includes("ROLE_RECRUITER") || ["view", "review"].includes(mode)){
										let {
											location,
											relevantFor,
											dueDate,
											visibility,
											...other_attr
										} = response.data.attributes;

										location = location
											? location.split(",")
											: [];

										let relevantForAsArray = relevantFor.split(",");

										let programs = tempRelevantForOptions.filter(option => {
											return relevantForAsArray.includes(option.key)
										}).map(option => option.label)

										dueDate = dueDate
											? dueDate.split("T")[0]
											: "";

										jobData["basicInfo"] = {
											location,
											relevantFor,
											programs,
											dueDate,
											visibility,
											comments: response.data.comments,
											...other_attr
										};
									} else {
										let {
											location,
											relevantFor,
											dueDate,
											visibility,
											...other_attr
										} = response.data.attributes;

										let {
											cohorts,
											...v_attributes
										} = response.data.targetAttributes;

										location = location
											? location.split(",")
											: [];

										let relevantForAsArray = relevantFor
											? relevantFor.split(",")
											: [];

										relevantFor = tempRelevantForOptions.filter(option => {
											return relevantForAsArray.includes(option.key)
										}).map(option => option.label);

										dueDate = dueDate
											? dueDate.split("T")[0]
											: "";

										cohorts = cohorts
											? cohorts.map(cohort => cohort.cohortName.trim())
											: [];

										jobData["basicInfo"] = {
											location,
											relevantFor,
											dueDate,
											visibility,
											cohorts,
											...other_attr
										};

										if (visibility === "custom") {
											let {
												vLocation,
												...v_other_attr
											} = v_attributes;

											jobData["visibilityInfo"] = vLocation
												? {
													vLocation: vLocation.split(
														","
														),
														...v_other_attr
												}
												: v_other_attr;
										} else {
											jobData["visibilityInfo"] = {};
										}
									}

									jobData["editInfo"] = {
										mode,
										jobID: match.params.jobID
									};

									props.jobActions.setJobData(jobData);
									if (isMounted) setIsFetchingDetails(false);
								} else {
									// write error toast code for no response from API
								}
							};
							fetchData();
						} else {
							if (isMounted) setIsFetchingDetails(false);
							props.jobActions.setJobData({
								editInfo: { mode }
							});
						}
					}
				} else {
					// write error toast code for no response from API
				}
			} catch (e) {
				//error
			}
		};

		fetchCohorts();

		return () => {
			isMounted = false;
			props.jobActions.clearJobData();
		};
	}, []);

	const handleNextIndex = (obj) => {
		const indexTemp = obj.index;
		if (indexTemp == 1 && obj.isExternal) {
			const { programs } = obj;
			const selectedOptions = relevantForOptions.all
			.filter(option => programs.includes(option.label))
			.map(option => option.value)
			.join(",");
			setRelevantForOptions({
				...relevantForOptions,
				selected: selectedOptions
			});
		} else if (indexTemp == 1) {
			const { cohorts } = obj;
			const selectedCohorts = cohortOptions.all
			.filter(cohort => cohorts.includes(cohort.label))
			.map(cohort => cohort.value)
			.join(",");
			setCohortOptions({
				...cohortOptions,
				selected: selectedCohorts
			});
		}
		const temp = allowedTabs;
		temp.push(indexTemp);
		setAllowedTabs(temp);
		setIndex(indexTemp);
	};

	const changeTabIndex = index => {
		if (allowedTabs.includes(index)) {
			setAllowedTabs(allowedTabs.slice(0, index + 1));
			setIndex(index);
		}
	};

	const selectTabList = () => {
		for (let i = 0; i < EXTERNAL_ROLES.length; i++) {
			if (props.userInfo.roles.includes(EXTERNAL_ROLES[i])) {
				return TABS_EXTERNAL;
			}
		}
		if(["/jobs/review/:jobID", "/jobs/view/:jobID"].includes(props.match.path)) return TABS_EXTERNAL;
		return TABS_INTERNAL;
	}

	const TABS_INTERNAL = [
		{
			title: "Basic Information",
			Component: (
				<BasicInfo
					index="0"
					nextIndex={handleNextIndex}
					cohortOptions={cohortOptions.all}
					relevantForOptions={relevantForOptions.all}
				/>
			)
		},
		{
			title: "Visibility",
			Component: (
				<VisibilityInfo
					index="1"
					nextIndex={handleNextIndex}
					selectedCohorts={
						cohortOptions.selected
					}
				/>
			)
		},
		{
			title: "Job Status",
			Component: (
				<JobStatus
					index="2"
					selectedCohorts={
						cohortOptions.selected
					}
				/>
			)
		}
	]

	const TABS_EXTERNAL = [
		{
			title: "Basic Information",
			Component: (
				<BasicInfo
					index="0"
					nextIndex={handleNextIndex}
					relevantForOptions={relevantForOptions.all}
				/>
			)
		}
	]

	const renderPageTitle = () => {
		let title = "";
		switch(props.match.path) {
			case "/jobs/edit/:jobID":
				title = "Edit job opening";
				break;
			case "/jobs/review/:jobID":
				title = "Review job opening";
				break;
			case "/jobs/view/:jobID":
				title = "View job opening";
				break;
			default:
				title = "Create job opening";
		}
		return title;
	}

	return (
		<>
			<div className={styles.secondaryHeaderOuter}>
				<div className={styles.secondaryHeaderInner}>
					<div className={styles.title}>
						{renderPageTitle()}
					</div>
				</div>
			</div>

			{props.jobInfo.basicInfo && props.jobInfo.basicInfo.comments && props.jobInfo.basicInfo.comments.length && props.jobInfo.basicInfo.comments[0].comment &&
				<div className={styles.reviewCommentSection}>
				<h4>Comments</h4>
				<div className={styles.commentContent}>
					{props.jobInfo.basicInfo && props.jobInfo.basicInfo.comments && props.jobInfo.basicInfo.comments.length && props.jobInfo.basicInfo.comments[0].comment}
				</div>
			</div>
			}
			<div className={styles.bodyOuter}>
				<div className={styles.bodyInner}>
					{isFetchingDetails ? (
						<SpinnerWithText text={spinnerText} />
					) : (
						<TabComponent
							tabList={selectTabList()}
							selectedIdx={index}
							changeTab={changeTabIndex}
							className={styles.tabComponent}
						/>
					)}
				</div>
			</div>
		</>
	);
};

CreateJobOpening.propTypes = {
	jobActions: PropTypes.object.isRequired,
	jobInfo: PropTypes.object.isRequired,
	userInfo: PropTypes.object.isRequired,
	match: PropTypes.object
};

function mapStateToProps(state) {
	return {
		jobInfo: state.jobInfo,
		userInfo: state.userInfo
	};
}

function mapDispatchToProps(dispatch) {
	return {
		jobActions: bindActionCreators(jobActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobOpening);
