module.exports = {
	login: {
		route: "/login",
	},
	logout: {
		route: "/logout",
	},
	attributes: {
		route: "/attributes",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	uploadData: {
		route: "/attributes/upload",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	jobOpenings: {
		route: "/jobs",
		roles: [
			"ROLE_CAREER_ADMIN",
			"ROLE_ADMIN",
			"ROLE_CAREER_ASSOCIATE",
			"ROLE_RECRUITER",
		],
	},
	jobOpeningsReview: {
		route: "/jobs/review",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	companies: {
		route: "/companies",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	jobCreate: {
		route: "/jobs/create",
		roles: [
			"ROLE_CAREER_ADMIN",
			"ROLE_ADMIN",
			"ROLE_CAREER_ASSOCIATE",
			"ROLE_RECRUITER",
		],
	},
	jobEdit: {
		route: "/jobs/edit/:jobID",
		roles: [
			"ROLE_CAREER_ADMIN",
			"ROLE_ADMIN",
			"ROLE_CAREER_ASSOCIATE",
			"ROLE_RECRUITER",
		],
	},
	jobApplications: {
		route: "/jobs/applications/:jobID",
		roles: [
			"ROLE_CAREER_ADMIN",
			"ROLE_ADMIN",
			"ROLE_CAREER_ASSOCIATE",
			"ROLE_RECRUITER",
		],
	},
	jobReview: {
		route: "/jobs/review/:jobID",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	jobView: {
		route: "/jobs/view/:jobID",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	candidates: {
		route: "/candidates",
		roles: [
			"ROLE_CAREER_ADMIN",
			"ROLE_ADMIN",
			"ROLE_CAREER_ASSOCIATE",
			"ROLE_RECRUITER",
		],
	},
	events: {
		route: "/webinars",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	eventList: {
		route: "/webinars/list",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	eventCreate: {
		route: "/webinars/create",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	eventEdit: {
		route: "/webinars/edit/:eventId",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	eventCompletionEdit: {
		route: "/webinars/edit/completed/:eventId",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	eventRegistrations: {
		route: "/webinars/registrations/:eventId",
		roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_CAREER_ASSOCIATE"],
	},
	// learnerProfile: {
	// 	route: "/learner/profile/view",
	// 	roles: ["ROLE_CAREER_ADMIN", "ROLE_ADMIN", "ROLE_RECRUITER"]
	// }
};
