import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./index.scss";
export default class Badge extends Component {
	static propTypes = {
		badgeArr: PropTypes.array,
		onDismiss: PropTypes.func,
		showDismiss: PropTypes.bool,
		customClass:PropTypes.string
	};
	static defaultProps = {
		badgeArr: [],
		onDismiss: () => {
			return;
		},
		showDismiss: true
	};
	renderBadges = () => {
		const { badgeArr, onDismiss, showDismiss ,customClass} = this.props;
		const array = Array.from(new Set(badgeArr));
		return array.map((item, i) => (
			<div className={customClass ? customClass : styles.badge} key={i}>
				{item}
				{showDismiss && (
					<span onClick={() => onDismiss && onDismiss(i)}>
						&#215;
					</span>
				)}
			</div>
		));
	};

	render() {
		return <div>{this.renderBadges()}</div>;
	}
}
