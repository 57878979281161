import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Select as CSelect } from "antd";
const { Option } = CSelect;
import styles from "./index.scss";

const Select = props => {
	const { options, name, label, onChange, error, ..._props } = props;
	const children = options.map(option => (
		<Option
			key={option.value}
			value={option.label}
			className={styles.option}
		>
			{option.label}
		</Option>
	));

	const handleChange = value => {
		onChange && onChange({ name, value });
	};

	useEffect(() => {
		const temp = document.getElementById(name).firstChild.classList;
		if (error) temp.add(cx(styles.hasError));
		else temp.remove(cx(styles.hasError));
	});

	return (
		<>
			{label && <label className={styles.label1}>{label}</label>}
			<CSelect id={name} onChange={handleChange} {..._props}>
				{children}
			</CSelect>
		</>
	);
};

Select.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired
		})
	).isRequired,
	loading: PropTypes.bool,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	mode: PropTypes.oneOf(["default", "multiple", "tags"]).isRequired,
	onChange: PropTypes.func,
	allowClear: PropTypes.bool,
	label: PropTypes.string,
	className: PropTypes.string,
	labelClassName: PropTypes.string,
	defaultValue: PropTypes.any,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	error: PropTypes.bool,
	disabled: PropTypes.bool
};

export default Select;
