import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronRight
} from "@fortawesome/free-solid-svg-icons";

import SpinnerWithText from "shared/SpinnerWithText";
import styles from "./index.scss";
import parentStyles from "../index.scss";
import { editEvent, getEventById, uploadAttendeesCSV } from "apis";
import { routesPath } from "app/components/Router";

import Input from "widgets/Input";
import Button from "widgets/Button";
import Toast from "widgets/Toast";
import Alert from "widgets/Alert";
import uploadImage from "images/upgrad/upload.svg";
import regexStrings from "constants/regexStrings";

const toast = new Toast();
const label1 = styles.labelOnlyAdditionalProps;

const PostCompletionEdit = ({ match, history }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [eventData, setEventData] = useState({});
	const [eventCompletionData, setEventCompletionData] = useState({});
	const [messageDetails, setMessageDetails] = useState([]);
	const [loaderOnSaveButton, setLoaderOnSaveButton] = useState(false);
	const [CSVData, setCSVData] = useState({ file: null, hasError: false });
	const fileInputRef = useRef();

	useEffect(() => {
		fetchEventData();
	}, []);


	const fetchEventData = async () => {
		const { data } = await getEventById(match.params.eventId);
		setEventData(data);
		setEventCompletionData({
			recordingURL: {
				value: data.eventCompletionData.recordingURL,
				hasError: false
			},
			recordingDuration: {
				value: data.eventCompletionData.recordingDuration,
				hasError: false
			}
		})
		setIsLoading(false);
	}

	const handleInputChange = ({ name, value }) => {
		setEventCompletionData ({
			...eventCompletionData,
			[name]: {
				...eventCompletionData[name],
				value
			}
		})
	}

	const submitEventData = async () => {
		setLoaderOnSaveButton(true);
		let hasError = { recordingDuration: false, recordingURL: false, csv: false };
		if (!eventCompletionData.recordingDuration.value) {
			hasError.recordingDuration = true;
		}
		if (!eventCompletionData.recordingURL.value) {
			hasError.recordingURL = true;
		}
		if (hasError.recordingURL || hasError.recordingDuration) {
			setEventCompletionData({
				recordingURL: {
					value: "",
					hasError: hasError.recordingURL
				},
				recordingDuration: {
					value: 0,
					hasError: hasError.recordingDuration
				}
			})
		}
		if (!CSVData.file) {
			hasError.csv = true;
			setCSVData({ file: null, hasError: true });
		}
		if(hasError.recordingDuration || hasError.recordingURL || hasError.csv) {
			toast.error("Please fill all the fields!");
		} else {
			let tempEventData = {
				...eventData,
				eventCompletionData: {
					recordingDuration: Number(eventCompletionData.recordingDuration.value),
					recordingURL: eventCompletionData.recordingURL.value,
				}
			}
			let response = await editEvent(tempEventData, match.params.eventId);
			let responseCSV = await uploadAttendeesCSV(CSVData.file, match.params.eventId);
			if(response.status === 200 && responseCSV.status === 200) {
				history.push(routesPath.eventList.route);
				toast.success("Event completion data has been saved!");
			} else if (responseCSV.status === 400) {
				setMessageDetails({
					message: "Something went wrong!",
					description: responseCSV.data.message
				});
			} else {
				toast.error("Something went wrong! Please try again.");
			}
		}
		setLoaderOnSaveButton(false);
	}

	const onChangeHandler = ({ files }) => {
		const fileType = files[0].type;
		if (["text/csv", "application/octet-stream", "application/vnd.ms-excel"].includes(fileType)) {
			setCSVData({ file: files[0], hasError: false });
		} else {
			setCSVData({ file: null, hasError: true });
			setMessageDetails({
				message: "Wrong file type",
				description: "Please select a CSV file only"
			});
		}
	};

	const handleUploadClick = () => {
		setMessageDetails({});
		fileInputRef.current.click();
	};

	return (
		<>
			<div className={parentStyles.secondaryHeaderOuter}>
				<div className={parentStyles.secondaryHeaderInner}>
					<div className={parentStyles.headerLeft}>
						<span className={parentStyles.title}>Webinars&emsp;</span>
						{!isLoading && (
							<>
								<FontAwesomeIcon icon={faChevronRight} size="xs" />
								<span className={parentStyles.title}>
									<span className={parentStyles.title}>
										&emsp;Post completion edit&emsp;
									</span>
									<FontAwesomeIcon
										icon={faChevronRight}
										size="xs"
									/>
									<span className={parentStyles.title}>
										&emsp;Live Session&emsp;
									</span>
									<FontAwesomeIcon
										icon={faChevronRight}
										size="xs"
									/>
									<span className={parentStyles.title}>
										&emsp;{ eventData.title.length > 65
										? `${eventData.title.substring(0, 65)}...`
										: eventData.title }
									</span>
								</span>
							</>
						)}
					</div>
					{ !isLoading &&
						<div className={parentStyles.headerRight}>
							<Button
								className={parentStyles.blueButton}
								onClick={submitEventData}
								isLoading={loaderOnSaveButton}
								disabled={loaderOnSaveButton}
							>
								Save
							</Button>
						</div>
					}
				</div>
			</div>
			<div className={parentStyles.bodyOuter}>
				<div className={parentStyles.bodyInner}>
					{ isLoading ? <SpinnerWithText text={"Fetching event details..."} /> :
						<>
							<div className={styles.form}>
								<div className={styles.row}>
									<div className={styles.col50}>
										<Input
											label="recording url"
											name="recordingURL"
											value={eventCompletionData.recordingURL.value || ""}
											labelClassName={label1}
											onChange={handleInputChange}
											error={eventCompletionData.recordingURL.hasError || false}
										/>
									</div>
									<div className={styles.col50}>
										<Input
											label="recording duration (in minutes)"
											name="recordingDuration"
											value={eventCompletionData.recordingDuration.value || ""}
											labelClassName={label1}
											onChange={handleInputChange}
											error={eventCompletionData.recordingDuration.hasError || false}
											regex={regexStrings.THREE_DIGITS}
										/>
									</div>
								</div>
								<div className={ cx({ [styles.csvHasError]: CSVData.hasError }, styles.uploadWrapper)}>
									<div>
										<img src={uploadImage} />
									</div>
									<div className={styles.textDiv}>
										<div className={styles.header}>
											<span>Attendees CSV file</span>
										</div>
										<div className={styles.descripton}>
											<span>Support for single CSV upload</span>
										</div>
										{ CSVData.file && (
											<div className={styles.csvName}>
												<span>File selected: {CSVData.file.name}</span>
											</div>
										)}
									</div>
									<Input
										ref={fileInputRef}
										type="file"
										className={styles.uploadInput}
										onChange={onChangeHandler}
										accept="text/csv"
									/>
									<Button
										className={styles.uploadButton}
										onClick={handleUploadClick}
									>
										SELECT CSV FOR UPLOAD
									</Button>
									{ messageDetails.message && (
										<div
											className={styles.messageField}
										>
											<Alert
												type={"error"}
												message={messageDetails.message}
												description={
													messageDetails.description
												}
												showIcon={true}
											/>
										</div>
									)}
								</div>
							</div>
						</>
					}
				</div>
			</div>
		</>
	);
};


PostCompletionEdit.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

export default PostCompletionEdit;
