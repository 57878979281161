/* eslint-disable valid-typeof */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import Tooltip from "widgets/Tooltip";

const withToolTip = (string) => {
	if (string && string.length > 16) {
		return (
			<Tooltip placement="topLeft" title={string}>
				{string}
			</Tooltip>
		);
	}
	return string;
};

/*
 * create default Api response
 */
const populateDefaultDataForFetchCandidatesAPI = (pageNo, sorter, pageSize) => {
	let data = {
		query: "",
		sortBy: sorter.field ? sorter.field : "",
		ascSort: sorter.order
			? sorter.order === "ascend"
				? "true"
				: "false"
			: "true",
		pageSize: pageSize,
		pageNumber: pageNo,
		filters: {},
	};
	return data;
};

/*
 * create filter Api response
 */
const populateFilterDataForCandidatesAPI = (filters, domainOptions) => {
	let data = {};
	if (Object.keys(filters).length !== 0) {
		let domainKey;
		if (filters.domain) {
			domainKey = domainOptions
				.filter((domain) => filters.domain.includes(domain.label))
				.map((domain) => domain.value)
				.join(",");
		}
		data = {
			location: filters.location ? filters.location : [],
			noticePeriod: filters.noticePeriod
				? parseInt(filters.noticePeriod)
				: 0,
			domain: filters.domain ? domainKey : "",
			salary:
				filters.minSalary || filters.maxSalary
					? {
						min: filters.minSalary
							? parseInt(filters.minSalary)
							: 0,
						max: filters.maxSalary
							? parseInt(filters.maxSalary)
							: 0,
					}
					: { min: 0, max: 0 },
			totalExp:
				filters.minTotalExperience || filters.maxTotalExperience
					? {
						min: filters.minTotalExperience
							? parseInt(filters.minTotalExperience)
							: 0,
						max: filters.maxTotalExperience
							? parseInt(filters.maxTotalExperience)
							: 0,
					}
					: { min: 0, max: 0 },
			relevantExp:
				filters.minRelevantExperience || filters.minRelevantExperience
					? {
						min: filters.minRelevantExperience
							? parseInt(filters.minRelevantExperience)
							: 0,
						max: filters.maxRelevantExperience
							? parseInt(filters.maxRelevantExperience)
							: 0,
					}
					: { min: 0, max: 0 },
		};
	} else {
		data = {};
	}
	return data;
};

/*
 * To download pdf link
 */
const downloadUrl = async (name, responseData) => {
	let blob = await new Blob([responseData], {
		type: "application/octet-stream",
	});
	let objectUrl = window.URL.createObjectURL(blob);
	let link = document.createElement("a");
	link.href = objectUrl;
	link.download = name ? name + " resume.pdf" : "resume.pdf";
	link.click();
};

const capitalizeFirstLetter = (words) => {
	if (words !== null) {
		var separateWord = words.toLowerCase().split(" ");
		for (var i = 0; i < separateWord.length; i++) {
			separateWord[i] =
				separateWord[i].charAt(0).toUpperCase() +
				separateWord[i].substring(1);
		}
		return separateWord.join(" ");
	}
};

const capitalizeFirstLetterOfLocation = (words) => {
	if (words !== null) {
		var separateWord = words.toLowerCase().split(",");
		for (var i = 0; i < separateWord.length; i++) {
			separateWord[i] =
				separateWord[i].charAt(0).toUpperCase() +
				separateWord[i].substring(1);
		}
		return separateWord.join(", ");
	}
};

const isEmpty = (data) => {
	if (typeof data === "object") {
		if (JSON.stringify(data) === "{}" || JSON.stringify(data) === "[]") {
			return true;
		} else if (!data) {
			return true;
		}
		return false;
	} else if (typeof data === "string") {
		if (!data.trim()) {
			return true;
		}
		return false;
	} else if (typeof data === "undefined") {
		return true;
	} else if (typeof data === "") {
		return true;
	} else {
		return false;
	}
};

export {
	withToolTip,
	populateDefaultDataForFetchCandidatesAPI,
	populateFilterDataForCandidatesAPI,
	downloadUrl,
	capitalizeFirstLetter,
	capitalizeFirstLetterOfLocation,
	isEmpty,
};
