import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "widgets/Modal";
import Alert from "widgets/Alert";
import { reviewJob } from "apis";
import Input from "widgets/Input";

import styles from "./index.scss";

const RejectionReasonModal = props => {
	const [isOkDisabled, setIsOkDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState({ error: false, message: "" });
	const [rejectionReason, setRejectionReason] = useState(props.currentComment);

	useEffect(() => {
		if(rejectionReason.length) {
			setIsOkDisabled(false)
		}
	}, []);


	useEffect(() => {
		let timeOut;
		if (error.error) {
			timeOut = setTimeout(() => {
				if (error.error) setError({ error: false, message: "" });
			}, 10000);
		}
		return () => {
			clearTimeout(timeOut);
		};
	}, [error]);

	const cleanUp = () => {
		setRejectionReason(props.currentComment);
		setIsOkDisabled(false);
		setIsLoading(false);
	};

	const handleOk = () => {
		setIsLoading(true);
		const fetchData = async () => {
			let response = await reviewJob({
				state: "REJECTED",
				comment: rejectionReason,
				id: props.id
			});
			if (response.status === 201) {
				cleanUp();
				props.onOk();
			} else if (response.status === 409) {
				setError({
					error: true,
					message: response.data.message
				});
			} else {
				setError({
					error: true,
					message:
						"Sorry, some error has occured. Please try again or check with admin."
				});
			}
			setIsLoading(false);
		};
		fetchData();
	};

	const handleCancel = () => {
		cleanUp();
		setError({ error: false, message: "" });
		props.onCancel();
	};

	const handleReasonChange = e => {
		setRejectionReason(e.value);
		if(e.value.length > 0) {
			setIsOkDisabled(false);
		} else {
			setIsOkDisabled(true);
		}
	};

	return (
		<div className={styles.modal}>
			<Modal
				title="Reasons for rejection"
				visible={props.visible}
				closable={false}
				okText="CONFIRM"
				onOk={handleOk}
				okButtonProps={{ disabled: isOkDisabled, loading: isLoading }}
				onCancel={handleCancel}
				destroyOnClose={true}
			>
				<Input
					multi={true}
					type="text"
					name="rejectionReason"
					placeholder="Enter the reasons for rejection"
					labelClassName={styles.label}
					onChange={handleReasonChange}
					value={rejectionReason}
				/>
				{error.error && (
					<Alert
						type="error"
						message={error.message}
						showIcon={true}
					/>
				)}
			</Modal>
		</div>
	);
};

RejectionReasonModal.propTypes = {
	id: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired,
	currentComment: PropTypes.string.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};
export default RejectionReasonModal;
