import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cx from "classnames";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import styles from "../../index.scss";
import Input from "widgets/Input";
import * as event_actions from "actions/event";

const Resources = ({ event, eventActions }) => {
	const label1 = styles.labelOnlyAdditionalProps;
	const { resourceInfo } = event;

	const handleInputChange = ({ name, value, index }) => {
		let temp = [...resourceInfo];
		temp[index][name]["value"] = value;
		temp[index][name]["hasError"] = false;
		eventActions.setEventData(temp, "ResourceInfo");
	};

	const addAnotherResource = () => {
		eventActions.setEventData([
			...resourceInfo,
			{
				title: {},
				url: {},
				description: {}
			}
		], "ResourceInfo");
	}

	const deleteResource = (index) => {
		let temp = [...resourceInfo]
		temp.splice(index, 1);
		eventActions.setEventData(temp, "ResourceInfo");
	}

	return (
		<div className={styles.resourcesContainer}>
			{ resourceInfo.map((resource, index) => (
				<div key={index} className={styles.resource}>
					<div className={styles.left}>
						<span className={styles.rowNumber}>{index + 1}.</span>
						{ resourceInfo.length > 1 && (
							<div
								className={cx(styles.labelBasicProps, styles.labelOnlyAdditionalProps, styles.deleteButton)}
								onClick={() => deleteResource(index)}
							>
								<FontAwesomeIcon icon={faMinusCircle} />
								<span>Delete resource</span>
							</div>
						)}

					</div>
					<div className={styles.right}>
						<div className={styles.row}>
							<div className={styles.col50}>
								<Input
									label="title"
									name="title"
									value={resource.title.value || ""}
									labelClassName={label1}
									onChange={({ name, value }) => handleInputChange({ name, value, index })}
									error={resource.title.hasError || false}
								/>
							</div>
							<div className={styles.col50}>
								<Input
									label="url"
									name="url"
									value={resource.url.value || ""}
									labelClassName={label1}
									onChange={({ name, value }) => handleInputChange({ name, value, index })}
									error={resource.url.hasError || false}
								/>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.col100}>
								<Input
									label="description"
									name="description"
									value={resource.description.value || ""}
									labelClassName={label1}
									onChange={({ name, value }) => handleInputChange({ name, value, index })}
									error={resource.description.hasError || false}
								/>
							</div>
						</div>
					</div>
				</div>
			))}
				<div className={styles.addButtonContainer}>
					<div
						className={cx(styles.labelBasicProps, styles.labelOnlyAdditionalProps, styles.addButton)}
						onClick={addAnotherResource}
					>
						<FontAwesomeIcon icon={faPlusCircle} />
						<span>
							Add another resource
						</span>
					</div>
				</div>
			</div>
	)
}


Resources.propTypes = {
	event: PropTypes.object.isRequired,
	eventActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		event: state.event,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		eventActions: bindActionCreators(event_actions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Resources);

