import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Result as CResult, Button } from "antd";

export default class Result extends Component {
	static propTypes = {
		status: PropTypes.String,
		title: PropTypes.String,
		subtitle: PropTypes.String,
		extra: PropTypes.object,
		route: PropTypes.String
	};
	static defaultProps = {
		subTitle: "OOPS! something went Wrong!",
		route: "/",
		extra: (
			<Button type='primary'>
				<Link to='/'>Retry</Link>{" "}
			</Button>
		)
	};

	render() {
		return <CResult {...this.props} />;
	}
}
