import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as event_actions from "actions/event";
import cx from "classnames";
import Upload from "widgets/Upload";
import Icon from "widgets/Icon";
import Input from "widgets/Input";
import Select from "widgets/Select";
import RangePicker from "widgets/RangePicker";
import Modal from "widgets/Modal";
import styles from "../../index.scss";
import { EVENT_CATEGORIES } from "./constants";

const BasicInfo = ({ eventActions, event }) => {
	const [uploadingImage, setUploadingImage] = useState({
		bannerImage: false,
		thumbnailImage: false
	});
	const [imagePreviewModal, setImagePreviewModal] = useState({
		isVisible: false,
		imageURL: "",
		imageText: ""
	})

	const { basicInfo } = event;

	const label1 = styles.labelOnlyAdditionalProps;
	const label2 = cx(label1, styles.labelBasicProps, styles.labelMB);

	const handleInputChange = ({ name, value }) => {
		if (name === "title") {
			if (value.length > 80) {
				eventActions.setEventData({
					title: {
						value: basicInfo.title.value, hasError: true
					}
				}, "BasicInfo");
			} else {
				eventActions.setEventData({ title: { value, hasError: false }}, "BasicInfo");
			}
		} else {
			eventActions.setEventData({ [name]: { value, hasError: false }}, "BasicInfo");
		}
	};

	const handleRangePickerChange = (value) => {
		eventActions.setEventData({
			startDate: {
				value: value[0],
				hasError: false
			},
			endDate: {
				value: value[1],
				hasError: false
			}
		}, "BasicInfo");
	}

	const closeImagePreviewModal = () => {
		setImagePreviewModal({
			isVisible: false,
			imageURL: "",
			imageText: ""
		})
	}

	const openImagePreviewModal = (url, text = "Banner") => {
		setImagePreviewModal({
			isVisible: true,
			imageURL: url,
			imageText: text
		})
	}

	return (
		<div className={styles.basicInfo}>
			<div className={styles.row}>
				<Modal
					title={`${imagePreviewModal.imageText} image preview`}
					visible={imagePreviewModal.isVisible}
					footer={null}
					closable={true}
					onCancel={closeImagePreviewModal}
				>
					<img src={imagePreviewModal.imageURL}/>
				</Modal>
				<div className={styles.col50}>
					<label className={label2}>Upload Event Banner</label>
					<div className={styles.imageUpload}>
						<Upload
							onUpload={(url) =>
								handleInputChange({ name: "bannerImage", value: url })
							}
							setLoading={(value) =>
								setUploadingImage({...uploadingImage, bannerImage: value})}
							options={{
								accept: ".jpg,.jpeg,.png,.svg,.gif",
								showUploadList: false,
								listType: "picture-card",
							}}
						>
							{basicInfo.bannerImage.value ? (
								<img
									src={basicInfo.bannerImage.value}
									alt="logo"
									style={{ width: "100%" }}
								/>
							) : (
								<div>
									<Icon type={uploadingImage.bannerImage ? "loading" : "plus"} />
								</div>
							)}
						</Upload>
						<span className={styles.recommendedSize}>Required: 1440px X 480px</span>
						{ basicInfo.bannerImage.value && !uploadingImage.bannerImage && (
							<div
								className={styles.previewButton}
								onClick={() => openImagePreviewModal(basicInfo.bannerImage.value)}
							>
								<h4><Icon type={"arrows-alt"} /> Preview</h4>
							</div>
						)}
						{ basicInfo.bannerImage.hasError && (
							<div
								className={cx(styles.hasError, styles['ml-12'])}
							>
								<span>Please upload image</span>
							</div>
						)}
					</div>
				</div>
				<div className={styles.col50}>
					<label className={label2}>Upload Event Thumbnail</label>
					<div className={styles.imageUpload}>
						<Upload
							onUpload={(url) =>
								handleInputChange({ name: "thumbnailImage", value: url })
							}
							setLoading={(value) =>
								setUploadingImage({...uploadingImage, thumbnailImage: value})}
							options={{
								accept: ".jpg,.jpeg,.png,.svg,.gif",
								showUploadList: false,
								listType: "picture-card",
							}}
						>
							{basicInfo.thumbnailImage.value ? (
								<img
									src={basicInfo.thumbnailImage.value}
									alt="logo"
									style={{ width: "100%" }}
								/>
							) : (
								<div>
									<Icon type={uploadingImage.thumbnailImage ? "loading" : "plus"} />
								</div>
							)}
						</Upload>
						<span className={styles.recommendedSize}>Required: 320px X 170px</span>
						{ basicInfo.thumbnailImage.value && !uploadingImage.thumbnailImage && (
							<div
								className={styles.previewButton}
								onClick={() => openImagePreviewModal(basicInfo.thumbnailImage.value, "Thumbnail")}
							>
								<h4><Icon type={"arrows-alt"} /> Preview</h4>
							</div>
						)}
						{ basicInfo.thumbnailImage.hasError && (
							<div
								className={cx(styles.hasError, styles['ml-12'])}
							>
								<span>Please upload image</span>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.col100}>
					<Input
						label="event title"
						name="title"
						value={basicInfo.title.value || ""}
						labelClassName={label1}
						inputClassName={styles["mb-0"]}
						onChange={handleInputChange}
						error={basicInfo.title.hasError || false}
					/>
					<label
						className={cx(styles.charCount, {
							[styles.hasError]: basicInfo.title.hasError,
						})}
					>
						Character count: {basicInfo.title.value ? basicInfo.title.value.length : 0}/80
					</label>
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.col50}>
					<label className={label2}>Start and End time</label>
					<div className={styles["mb-20"]}>
						<RangePicker
							showTime={{ format: 'HH:mm' }}
							format="DD-MMM-YYYY HH:mm"
							className = { cx(styles.datePicker, { [styles.datePickerError]: basicInfo.startDate.hasError }) }
							value={[ basicInfo.startDate.value, basicInfo.endDate.value ] || [null, null]}
							placeholder={["Start", "End"]}
							onChange={handleRangePickerChange}
						/>
					</div>
				</div>
				<div className={styles.col50}>
					<Select
						mode="default"
						showSearch={true}
						label="Type"
						name="category"
						placeholder="Select a type"
						onChange={handleInputChange}
						error={basicInfo.category.hasError || false}
						options={EVENT_CATEGORIES}
						value={basicInfo.category.value}
					/>
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.col50}>
					<Input
						label="live session url"
						name="joinURL"
						value={basicInfo.joinURL.value || ""}
						labelClassName={label1}
						onChange={handleInputChange}
						error={basicInfo.joinURL.hasError || false}
					/>
				</div>
			</div>
			<hr />
			<div className={styles.row}>
				<div className={styles.col50}>
					<Input
						label="registration typeform id (Optional)"
						name="registrationTypeformID"
						value={basicInfo.registrationTypeformID.value || ""}
						labelClassName={label1}
						onChange={handleInputChange}
						error={basicInfo.registrationTypeformID.hasError || false}
					/>
				</div>
				<div className={styles.col50}>
					<Input
						label="feedback typeform id (Optional)"
						name="feedbackTypeformID"
						value={basicInfo.feedbackTypeformID.value || ""}
						labelClassName={label1}
						onChange={handleInputChange}
						error={basicInfo.feedbackTypeformID.hasError || false}
					/>
				</div>
			</div>
		</div>
	);
};

BasicInfo.propTypes = {
	event: PropTypes.object.isRequired,
	eventActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		event: state.event,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		eventActions: bindActionCreators(event_actions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
