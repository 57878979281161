import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "widgets/Tooltip";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import Tag from "shared/Tag";
import Table from "widgets/Table";
import Select from "widgets/Select";
import Input from "widgets/Input";
import styles from "./index.scss";
import parentStyles from "../index.scss"
import { getAllEvents } from "apis";
import { routesPath } from "app/components/Router";
import Button from "widgets/Button";

const PUBLISHED_STATUS_COLORS = {
	DRAFT: "yellow",
	PUBLISHED: "green",
};

const TIMING_STATUS_COLORS = {
	PAST: "yellow",
	ONGOING: "green",
	UPCOMING: "blue",
	NONE: "black"
};

const PAGE_SIZE = 10;
const DATE_TOOLTIP_FORMAT = "dddd, MMMM Do YYYY, h:mm:ss a";

const withToolTip = (string, threshold) => {
	if (string.length > threshold) {
		return (
			<Tooltip placement="topLeft" title={string}>
				{string}
			</Tooltip>
		);
	}
	return string;
};

const PUBLISHED_STATUS = [
	{
		label: "All",
		value: "All",
	},
	{
		label: "Draft",
		value: "Draft",
	},
	{
		label: "Published",
		value: "Published",
	},
];

const TIMING_STATUS = [
	{
		label: "All",
		value: "all",
	},
	{
		label: "Upcoming",
		value: "upcoming",
	},
	{
		label: "Ongoing",
		value: "ongoing",
	},
	{
		label: "Past",
		value: "past",
	},
];

const ListEvents = ({ history }) => {
	const [eventDataSource, setEventDataSource] = useState();

	const [filters, setFilters] = useState({
		publishedStatus: "All",
		timingStatus: "All",
		searchText: "",
	});

	const [tableProps, setTableProps] = useState({
		pagination: { total: 0, current: 1, pageSize: PAGE_SIZE },
		loading: true,
	});

	useEffect(() => {
		fetchEvents({ pageNo: tableProps.pagination.current });
	}, []);

	const columns = [
		{
			title: "Sr.No.",
			dataIndex: "sno",
			className: cx(styles["w-85"], styles.column),
			// eslint-disable-next-line
			render: (sno) => <span>{sno}</span>,
		},
		{
			title: "Webinar Name",
			dataIndex: "title",
			className: cx(styles["w-288"], styles.column),
			// eslint-disable-next-line
			render: (title) => <span>{withToolTip(title, 30)}</span>,
		},
		{
			title: "Type",
			dataIndex: "type",
			className: cx(styles["w-160"], styles.column),
			// eslint-disable-next-line
			render: (type) => <span>{type}</span>,
		},
		{
			title: "Category",
			dataIndex: "category",
			className: cx(styles["w-160"], styles.column),
			// eslint-disable-next-line
			render: (category) => <span>{category}</span>,
		},
		{
			title: "Reg. Count",
			dataIndex: "registrationCount",
			className: cx(styles["w-115"], styles.column),
			// eslint-disable-next-line
			render: (registrationCount) => <span>{registrationCount}</span>,
		},
		{
			title: "Published status",
			dataIndex: "status",
			className: cx(styles["w-140"], styles.column),
			// eslint-disable-next-line
			render: (status) => (
				<Tag color={PUBLISHED_STATUS_COLORS[status]}>{status.toUpperCase()}</Tag>
			),
		},
		{
			title: "Status",
			className: [cx(styles["w-140"], styles.column)],
			// eslint-disable-next-line
			render: (record) =>  {
				const start = moment(record.date.start).format(DATE_TOOLTIP_FORMAT);
				const end = moment(record.date.end).format(DATE_TOOLTIP_FORMAT);
				const title = `${start} - ${end}`
				return (
					<Tag color={TIMING_STATUS_COLORS[record.timingStatus]}>
						<Tooltip placement="top" title={title}>
							{record.timingStatus}
						</Tooltip>
					</Tag>
				)
			}
		},
		{
			title: "Action",
			className: cx(styles["w-130"], styles.column),
			// eslint-disable-next-line
			render: record => {
				return (
					<span>
						<button
							onClick={()=>handleEditClick(record.key, record.status, record.date.end)}
							className={styles.actionBtn}
						>
							<div className="btnText" />
						</button>
						<Link to={`/webinars/registrations/${record.key}`}>
							<button className={styles.actionBtnSecond}>
								<div className="btnText" />
							</button>
						</Link>
					</span>
				)
			}
		},
	];

	const fetchEvents = async ({ pageNo }) => {
		if (document.getElementById("top")) {
			document
				.getElementById("top")
				.scrollIntoView({ behavior: "smooth" });
		}
		const params = {
			pageNo,
			pageSize: tableProps.pagination.pageSize,
			searchText: filters.searchText,
			timingStatus: filters.timingStatus,
			publishedStatus: filters.publishedStatus
		};

		try {
			let response = await getAllEvents(params);
			if (response.status === 200) {
				let events = response.data;
				events = events.map((elem, i) => {
					elem.sno = i + 1 + (pageNo - 1) * PAGE_SIZE
					elem.key = elem.id;
					if(elem.date.start && elem.date.end) {
						if(moment().isBefore(elem.date.start)){
							elem.timingStatus = "UPCOMING";
						} else if (moment().isBetween(elem.date.start, elem.date.end)){
							elem.timingStatus = "ONGOING";
						} else {
							elem.timingStatus = "PAST";
						}
					} else {
						elem.timingStatus = "NONE";
					}
					return elem;
				});

				setEventDataSource(events);
				let pagination = { ...tableProps.pagination };
				pagination.total = parseInt(response.headers.count);
				pagination.current = pageNo;
				setTableProps({
					pagination,
					loading: false,
				});
			}
		} catch (e) {
			// error
		}
	};

	const handleTableChange = (pagination) => {
		const pager = { ...tableProps.pagination };
		pager.current = pagination.current;
		setTableProps({
			pagination: pager,
			loading: true,
		});
		fetchEvents({ pageNo: pagination.current });
	};

	const handleChange = ({ name, value }) => {
		setFilters({ ...filters, [name]: value });
	};

	const handleApply = () => {
		setTableProps({
			...tableProps,
			loading: true,
		});
		fetchEvents({ pageNo: 1 });
	};

	const handleEditClick = (eventId, status, endDate) => {
		if (status === "PUBLISHED" && moment().diff(moment(endDate)) >= 0) {
			history.push(routesPath.eventCompletionEdit.route.replace(":eventId", eventId));
		} else {
			history.push(routesPath.eventEdit.route.replace(":eventId", eventId));
		}
	}

	return (
		<>
			<div className={parentStyles.secondaryHeaderOuter}>
				<div className={parentStyles.secondaryHeaderInner}>
					<div className={parentStyles.title}>
						Webinars
					</div>
					<Link to={routesPath.eventCreate.route} className={parentStyles.createEventButton}>
						<Button
							className={parentStyles.blueButton}
						>
							Create Webinar
						</Button>
					</Link>
				</div>
			</div>
			<div className={parentStyles.bodyOuter}>
				<div className={parentStyles.bodyInner}>
					<div className={styles.filtersRow}>
						<div className={styles.selector}>
							<Select
								mode="default"
								name="publishedStatus"
								value={filters.publishedStatus}
								onChange={handleChange}
								defaultValue={PUBLISHED_STATUS[1].value}
								options={PUBLISHED_STATUS}
							/>
						</div>
						<div className={styles.selector}>
							<Select
								mode="default"
								name="timingStatus"
								value={filters.timingStatus}
								onChange={handleChange}
								defaultValue={TIMING_STATUS[1].label}
								options={TIMING_STATUS}
							/>
						</div>
						<div className={styles.searchInputDiv}>
							<Input
								type="text"
								name="searchText"
								placeholder="Search keyword in webinar name, description or speaker name"
								inputClassName={styles.searchInput}
								value={filters.searchText}
								onChange={handleChange}
							/>
						</div>
						<div className={styles.applyButtonDiv}>
							<Button
								className={styles.whiteButton}
								onClick={handleApply}
							>
								Apply filters
							</Button>
						</div>
					</div>
					<div className={parentStyles.tableContainer}>
						<Table
							columns={columns}
							dataSource={eventDataSource}
							pagination={tableProps.pagination}
							loading={tableProps.loading}
							onChange={handleTableChange}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

ListEvents.propTypes = {
	history: PropTypes.object.isRequired
}

export default ListEvents;
