import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { routesPath } from "app/components/Router";
import styles from "./index.scss";
import { ALL_INTERNAL_ROLES } from "constants/roleMapping";
import { RBAC } from "hoc";

const PrimaryNav = function () {
	return (
		<div className={styles.root}>
			<div className={styles.navList}>
				<RBAC allowedRoles={ALL_INTERNAL_ROLES}>
					<NavLink
						to={routesPath.attributes.route}
						className={styles.navItem}
						activeClassName={styles.active}
					>
						Attributes
					</NavLink>
					<NavLink
						to={routesPath.companies.route}
						className={styles.navItem}
						activeClassName={styles.active}
					>
						Companies
					</NavLink>
					<NavLink
						to={routesPath.events.route}
						className={styles.navItem}
						activeClassName={styles.active}
					>
						Webinars
					</NavLink>
				</RBAC>
				<NavLink
					to={routesPath.candidates.route}
					className={styles.navItem}
					activeClassName={styles.active}
				>
					Candidates
				</NavLink>
			</div>
		</div>
	);
};

PrimaryNav.propTypes = {
	sticky: PropTypes.bool,
};

export default PrimaryNav;
