import actionTypes from "actionTypes";

export function setJobData(data) {
	return dispatch => {
		dispatch({
			type: actionTypes.SET_JOB_DATA,
			payload: data
		});
	};
}

export function clearJobData() {
	return dispatch => {
		dispatch({
			type: actionTypes.CLEAR_JOB_DATA
		});
	};
}
