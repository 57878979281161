import actionTypes from "actionTypes";
import * as apis from "apis";
import { setError, clearError } from "./error";

export function loginUser({ email, password }) {
	return dispatch => {
		return apis
			.loginUser({ email, password })
			.then(response => {
				let data;
				if (response.status === 200) {
					data = response.data;
					const { allowedRole, isAdmin } = checkRole(
						data.rolesStringSet
					);
					if (allowedRole) {
						dispatch({
							type: actionTypes.LOGIN_USER,
							payload: {
								authToken: response.headers["auth-token"],
								email: data.email,
								isAdmin: isAdmin,
								name: data.name,
								id: data.id,
								roles: data.rolesStringSet
							}
						});
					} else {
						sendErrorMessage(
							dispatch,
							"Sorry you don't have access to this portal."
						);
					}
				} else if (response.status === 401) {
					data = response.data.errorList[0];
					sendErrorMessage(dispatch, data.errorMessage);
				}
			})
			.catch(() => {
				sendErrorMessage(
					dispatch,
					"Sorry some error has occured, please try again."
				);
			});
	};
}

export function logoutUser(authToken) {
	return async dispatch => {
		try {
			if (authToken) {
				await apis.logoutUser();
				document.getElementById("pageLoaderRoot").classList.add("hide");
				dispatch({
					type: actionTypes.LOGOUT_USER
				});
			}
		} catch (error) {
			document.getElementById("pageLoaderRoot").classList.add("hide");
			sendErrorMessage(
				dispatch,
				"Oops something went wrong, please try again later."
			);
		}
	};
}

const checkRole = rolesStringSet => {
	const ADMIN_ROLES = ["ROLE_CAREER_ADMIN", "ROLE_ADMIN"];
	const NON_ADMIN_ROLES = ["ROLE_CAREER_ASSOCIATE", "ROLE_RECRUITER"];
	for (let i = 0; i < ADMIN_ROLES.length; i++) {
		if (rolesStringSet.includes(ADMIN_ROLES[i])) {
			return { allowedRole: true, isAdmin: true };
		}
	}
	for (let i = 0; i < NON_ADMIN_ROLES.length; i++) {
		if (rolesStringSet.includes(NON_ADMIN_ROLES[i])) {
			return { allowedRole: true, isAdmin: false };
		}
	}
	return { allowedRole: false, isAdmin: false };
};

const sendErrorMessage = (dispatch, message) => {
	dispatch(setError(message));
	setTimeout(() => {
		dispatch(clearError());
	}, 5000);
};
