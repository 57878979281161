import React from "react";
import { Tabs } from 'antd';
import PropTypes from "prop-types";

const TabPane = Tabs.TabPane;


// eslint-disable-next-line react/prop-types
const CustomTabs = ({ props, ...restProps }) => {
	const onTabChange = (key) => {
		restProps.onChange(key)
	}

	return (
		<Tabs defaultActiveKey={restProps.defaultActiveKey} onChange={onTabChange} >
			{restProps.children.length > 1?
			restProps.children.map((t) => {
				return (
					<TabPane tab={t.props.tab} key={t.key} {...props}>
						{t.props.contentData}
					</TabPane>
				);
			}) : ''}
		</Tabs>
	)
}
CustomTabs.propTypes = {
	defaultActiveKey: PropTypes.string,
	onChange: PropTypes.func,
};
export default CustomTabs;
