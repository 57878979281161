import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "widgets/Modal";
import CohortSelector from "shared/CohortSelector";
import CategorySelector from "shared/CategorySelector";
import Alert from "widgets/Alert";
import { addNewAttribute, getAllCohorts } from "apis";
import Input from "widgets/Input";
import styles from "./index.scss";

const CreateAttributeModal = props => {
	const [cohortOptions, setCohortOptions] = useState([]);
	const [isCohoSeleLoading, setIsCohoSeleLoading] = useState(true);
	const [cohortId, setCohortId] = useState();
	const [category, setCategory] = useState("");
	const [attributeName, setAttributeName] = useState("");
	const [attributeDesc, setAttributeDesc] = useState("");
	const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
	const [newCategoryName, setNewCategoryName] = useState("");
	const [isOkDisabled, setIsOkDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState({ error: false, message: "" });

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			let response = await getAllCohorts({ dataUpload: true });
			if (isMounted)
				if (response) {
					const options = response.data.cohorts.map(cohort => ({
						label: `${cohort.externalId} - ${cohort.name}`,
						value: cohort.externalId
					}));

					setCohortOptions(options);
					setIsCohoSeleLoading(false);
				} else {
					// write error toast code for no response from API
				}
		};
		fetchData();
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		const temp = attributeName && attributeDesc && cohortId && category;
		setIsOkDisabled(!temp);
	}, [cohortId, category, attributeName, attributeDesc]);

	useEffect(() => {
		let timeOut;
		if (error.error) {
			timeOut = setTimeout(() => {
				if (error.error) setError({ error: false, message: "" });
			}, 10000);
		}
		return () => {
			clearTimeout(timeOut);
		};
	}, [error]);

	const handleInputChange = e => {
		if (e.name === "attributeName") {
			setAttributeName(e.value);
		} else if (e.name === "attributeDesc") {
			setAttributeDesc(e.value);
		} else {
			setNewCategoryName(e.value);
			if (e.value !== "") {
				setCategory(e.value);
			} else {
				setCategory();
			}
		}
	};

	const handleCohortSelectorChange = ({ value }) => {
		const temp = cohortOptions.filter(
			cohort => cohort.label === value
		);
		setCohortId(temp[0].value);
		if (showNewCategoryInput) {
			setShowNewCategoryInput(false);
			setNewCategoryName("");
		}
	};

	const handleCategorySelectorChange = category => {
		if (category === "Add a new category") {
			setShowNewCategoryInput(true);
			setCategory();
		} else if (showNewCategoryInput) {
			setShowNewCategoryInput(false);
			setCategory(category);
		} else {
			setCategory(category);
		}
	};

	const cleanUp = () => {
		setAttributeName("");
		setAttributeDesc("");
		setCohortId();
		setCategory();
		setShowNewCategoryInput(false);
	};

	const handleOk = () => {
		setIsLoading(true);
		const fetchData = async () => {
			let response = await addNewAttribute({
				course_id: cohortId,
				attrKey: attributeName,
				displayType: category,
				attrDesc: attributeDesc
			});
			if (response.status === 201) {
				cleanUp();
				props.onOk();
			} else if (response.status === 409) {
				setError({
					error: true,
					message: response.data.message
				});
			} else {
				setError({
					error: true,
					message:
						"Sorry, some error has occured. Please try again or check with admin."
				});
			}
			setIsLoading(false);
		};

		let isAttrLong = false;
		let isNewCategLong = false;
		let message;

		if (attributeName.length > 127) {
			isAttrLong = true;
			message =
				"Maximum length for attribute name is 127 characters. Please enter again.";
			setAttributeName("");
		}
		if (showNewCategoryInput && newCategoryName.length > 127) {
			isNewCategLong = true;
			message =
				"Maximum length for category name is 127 characters. Please enter again.";
			setNewCategoryName("");
		}

		if (isAttrLong && isNewCategLong)
			message =
				"Maximum length for names is 127 characters. Please enter again.";

		if (isAttrLong || isNewCategLong)
			setError({
				error: true,
				message
			});
		else {
			fetchData();
		}
	};

	const handleCancel = () => {
		cleanUp();
		setError({ error: false, message: "" });
		props.onCancel();
	};

	return (
		<div className={styles.modal}>
			<Modal
				title="Create New Attribute"
				visible={props.visible}
				closable={false}
				okText="CONFIRM"
				onOk={handleOk}
				okButtonProps={{ disabled: isOkDisabled, loading: isLoading }}
				onCancel={handleCancel}
				destroyOnClose={true}
			>
				<Input
					label="Attribute name"
					name="attributeName"
					placeholder="Enter a name"
					labelClassName={styles.label}
					value={attributeName}
					onChange={handleInputChange}
				/>
				<Input
					label="Attribute description"
					name="attributeDesc"
					placeholder="Enter a description"
					labelClassName={styles.label}
					value={attributeDesc}
					onChange={handleInputChange}
				/>
				<CohortSelector
					options={cohortOptions}
					loading={isCohoSeleLoading}
					onChange={handleCohortSelectorChange}
					label="Cohort"
				/>
				<CategorySelector
					addNew={true}
					cohortId={cohortId}
					onChange={handleCategorySelectorChange}
					label="Category"
				/>
				{showNewCategoryInput && (
					<Input
						label="NEW CATEGORY NAME"
						name="newCategoryName"
						placeholder="Enter a name for new category"
						value={newCategoryName}
						onChange={handleInputChange}
					/>
				)}
				{error.error && (
					<Alert
						type="error"
						message={error.message}
						showIcon={true}
					/>
				)}
			</Modal>
		</div>
	);
};

CreateAttributeModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};
export default CreateAttributeModal;
