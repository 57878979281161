import {
	MANDATORY_FIELDS_INTERNAL,
	MANDATORY_FIELDS_EXTERNAL,
	OPTIONAL_FIELDS,
	MINMAXERROR
} from "./constants";

const validateInputs = (
	role,
	charCount,
	tempFormDetails,
	tempError,
	tempHelperText
) => {
	let toBeDeletedOptionalFields = [];

	if (charCount < 300) {
		tempError["charCount"] = true;
	}

	if(role === "external") {
		MANDATORY_FIELDS_EXTERNAL.forEach(key => {
			if (
				tempFormDetails[key] === undefined ||
				tempFormDetails[key].length === 0
			) {
				tempError[key] = true;
			}
		});
	} else {
		MANDATORY_FIELDS_INTERNAL.forEach(key => {
			if (
				tempFormDetails[key] === undefined ||
				tempFormDetails[key].length === 0
			) {
				tempError[key] = true;
			}
		});
	}



	OPTIONAL_FIELDS.forEach(key => {
		// check for empty string / array
		if (
			tempFormDetails[key] !== undefined &&
			tempFormDetails[key].length === 0
		) {
			toBeDeletedOptionalFields.push(key);
		}
	});

	checkMinMaxWrapper(tempFormDetails, tempError, tempHelperText);
	return toBeDeletedOptionalFields;
};

const checkMinMaxWrapper = (tempFormDetails, tempError, tempHelperText) => {
	checkMinMax(
		"totalExpMin",
		"totalExpMax",
		"totalExp",
		tempFormDetails,
		tempError,
		tempHelperText
	);
	checkMinMax(
		"relevantExpMin",
		"relevantExpMax",
		"relevantExp",
		tempFormDetails,
		tempError,
		tempHelperText
	);
	checkMinMax(
		"salaryFrom",
		"salaryTo",
		"salary",
		tempFormDetails,
		tempError,
		tempHelperText
	);
};

const checkMinMax = (
	minName,
	maxName,
	name,
	tempFormDetails,
	tempError,
	tempHelperText
) => {
	let min = tempFormDetails[minName];
	let max = tempFormDetails[maxName];
	if (min && max && parseInt(min) > parseInt(max)) {
		tempError[minName] = true;
		tempError[maxName] = true;
		tempHelperText[name] = MINMAXERROR;
	}
};

export default validateInputs;
