/*
TODO:  Remove the antd tooltip and build the internal tooltip implace of it.
*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip as CTooltip } from "antd";

export default class Tooltip extends Component {
	static propTypes = {
		title: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.node,
			PropTypes.func
		]),
		placement: PropTypes.string,
		arrowPointAtCenter: PropTypes.bool,
		defaultVisible: PropTypes.bool,
		mouseEnterDelay: PropTypes.number,
		mouseLeaveDelay: PropTypes.number,
		overlayClassName: PropTypes.string,
		trigger: PropTypes.string,
		children: PropTypes.node
	};

	static defaultProps = {
		title: "",
		placement: "topLeft",
		overlayClassName: "tooltipCard"
	};

	render() {
		return <CTooltip {...this.props} />;
	}
}
