import actionTypes from "actionTypes";

export function setEventData(data, specificModule = "none") {
	switch (specificModule) {
		case "BasicInfo":
			return dispatch => {
				dispatch({
					type: actionTypes.SET_EVENT_BASIC_INFO,
					payload: data
				});
			};
		case "EventInfo":
			return dispatch => {
				dispatch({
					type: actionTypes.SET_EVENT_EVENT_INFO,
					payload: data
				});
			};
		case "SpeakerInfo":
			return dispatch => {
				dispatch({
					type: actionTypes.SET_EVENT_SPEAKER_INFO,
					payload: data
				});
			};
		case "ResourceInfo":
			return dispatch => {
				dispatch({
					type: actionTypes.SET_EVENT_RESOURCE_INFO,
					payload: data
				});
			};
		default:
			return dispatch => {
				dispatch({
					type: actionTypes.SET_EVENT_DATA,
					payload: data
				});
			};
	}
}

export function clearEventData() {
	return dispatch => {
		dispatch({
			type: actionTypes.CLEAR_EVENT_DATA
		});
	};
}
