import constants from "constants";
import createInstanceWithSessionDetails from "./helper";

const careersApiInstance = createInstanceWithSessionDetails(
	constants.careersApiServerUrl
);

const URL = {
	cohort: "cohort",
	attributes: "attributes",
	profile: "profile",
	job: "job",
	jobByID: "job/:jobID",
	jobsForReview: "job/review",
	jobForReviewByID: "job/review/:jobID",
	exportResource: "export",
	company: "company",
	jobApplication: "job/application",
	userVisibility: "user/visibility",
	relevantFor: "relevant-for"
};

export const getAllCohorts = async function ({ dataUpload = false } = {}) {
	try {
		let url = URL.cohort;
		if (dataUpload) {
			url += "?is_data_upload=true";
		}
		const response = await careersApiInstance.get(url);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getAttributes = async function ({ course_id, type, dataUpload }) {
	try {
		let url = URL.attributes + `?display_type=${type}`;
		if (dataUpload) {
			url += "&is_data_upload=true";
		}
		const response = await careersApiInstance.get(url, {
			headers: { course_id },
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const addNewAttribute = async function ({
	course_id,
	attrKey,
	displayType,
	attrDesc,
}) {
	try {
		const url = URL.attributes;
		const response = await careersApiInstance.post(
			url,
			{ attrKey, displayType, attrDesc },
			{ headers: { course_id } }
		);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const addNewCompany = async function ({ name, account_manager }) {
	try {
		const url = URL.company;
		const response = await careersApiInstance.post(url, {
			name,
			account_manager,
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

//for future use
// export const deleteCompany = async function({
// 	company_id
// }) {
// 	try {
// 		const url = URL.company + '/'+ company_id;
// 		const response = await careersApiInstance.delete(url);
// 		return response;
// 	} catch (error) {
// 		return error.response;
// 	}
// };

export const updateCompanyManager = async function ({
	name,
	account_manager,
	company_id,
}) {
	try {
		const url = URL.company + "/" + company_id;
		const response = await careersApiInstance.put(url, {
			name,
			account_manager,
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const uploadData = async function ({
	course_id,
	display_type,
	user_data,
}) {
	try {
		let bodyFormData = new FormData();
		bodyFormData.set("display_type", display_type);
		bodyFormData.append("user_data", user_data);
		const url = URL.profile;
		const response = await careersApiInstance.post(url, bodyFormData, {
			headers: { course_id, "Content-Type": "multipart/form-data" },
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const createJob = async function (jobState, jobInfo) {
	try {
		const { basicInfo, visibilityInfo } = jobInfo;

		let bodyFormData = new FormData();

		for (let key in basicInfo) {
			if (basicInfo.hasOwnProperty(key)) {
				bodyFormData.append([key], basicInfo[key]);
			}
		}

		if (basicInfo.visibility === "custom") {
			for (let key in visibilityInfo) {
				if (
					visibilityInfo.hasOwnProperty(key) &&
					key !== "previewCount"
				) {
					bodyFormData.append([key], visibilityInfo[key]);
				}
			}
		}
		if(["True", "False"].includes(jobState))
			bodyFormData.append("isPublished", jobState);
		else
			bodyFormData.append("job_state", jobState);

		const url = URL.job;

		let response = await careersApiInstance.post(url, bodyFormData, {
			headers: { "Content-Type": "multipart/form-data" },
		});

		return response;
	} catch (error) {
		return error.response;
	}
};

export const editJob = async function (jobInfo, jobID, jobState) {
	try {
		let bodyFormData = new FormData();

		for (let key in jobInfo) {
			if (jobInfo.hasOwnProperty(key)) {
				bodyFormData.append([key], jobInfo[key]);
			}
		}
		if(jobState) {
			bodyFormData.append('job_state', jobState.toUpperCase())
		}

		let response = await careersApiInstance.put(
			URL.jobByID.replace(":jobID", jobID),
			bodyFormData,
			{ headers: { "Content-Type": "multipart/form-data" } }
		);

		return response;
	} catch (error) {
		return error.response;
	}
};

export const getJobDetails = async function ({ jobID }) {
	try {
		const response = await careersApiInstance.get(
			URL.jobByID.replace(":jobID", jobID)
		);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getProfiles = async function ({ course_id, data }) {
	try {
		const url = URL.profile;
		const response = await careersApiInstance.get(url, {
			params: { ...data, concat: "OR" },
			headers: { course_id },
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const exportResource = async function ({ resource }) {
	try {
		const url = URL.exportResource;
		const params = { resource };
		const response = await careersApiInstance.get(url, {
			params,
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getJobs = async function ({
	pageNo,
	pageSize,
	searchText,
	jobStatus,
	cohortId,
	strictMatch,
	createdBy
}) {
	try {
		const url = URL.job;
		const params = jobStatus != "all" ? { status: jobStatus } : {};
		let headers = {
			page_no: pageNo,
			page_size: pageSize,
		};

		if (cohortId.length) params["cohort_id"] = cohortId;
		if (searchText.length) params["search_text"] = searchText;
		if (strictMatch) params["strict_match"] = true;
		if(createdBy !== "all") params["created_by"] = createdBy

		const response = await careersApiInstance.get(url, {
			params,
			headers,
		});

		return response;
	} catch (error) {
		return error.response;
	}
};

export const getJobsForReview = async function ({
	pageNo,
	pageSize,
	jobStatus,
	cohortId,
	searchText
}) {
	try {
		const url = URL.jobsForReview;
		const params = jobStatus != "all" ? { status: jobStatus } : {};
		let headers = {
			page_no: pageNo,
			page_size: pageSize,
		};

		if (cohortId.length) params["cohort_id"] = cohortId;
		if (searchText.length) params["search_text"] = searchText;

		const response = await careersApiInstance.get(url, {
			params,
			headers,
		});

		return response;
	} catch (error) {
		return error.response;
	}
};

export const getCompanies = async function () {
	try {
		const url = URL.company;
		const response = await careersApiInstance.get(url);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getJobApplications = async function ({ jobID, filters }) {
	try {
		const url = URL.jobApplication + `/${jobID}`;

		const response = await careersApiInstance.get(url, {
			params: { ...filters },
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const updateApplicationStatus = async function ({
	applications,
	status,
}) {
	try {
		const url = URL.jobApplication;
		let bodyFormData = new FormData();
		bodyFormData.append("applications", applications);
		bodyFormData.append("status", status);

		const response = await careersApiInstance.put(url, bodyFormData);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const extractApplications = async function (jobID, applications) {
	try {
		const url = URL.jobApplication + `/${jobID}`;

		const response = await careersApiInstance.get(url, {
			params: {
				resume: true,
				applications: applications.join(),
			},
			responseType: "blob",
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getVisibility = async function (filters) {
	try {
		const url = URL.userVisibility;
		const response = await careersApiInstance.get(url, {
			params: { ...filters },
		});
		return response;
	} catch (error) {
		return error.response;
	}
};

export const deleteJobAttributes = async function (jobID, keys) {
	try {
		const response = await careersApiInstance.delete(
			URL.jobByID.replace(":jobID", jobID),
			{
				params: { keys }
			}
		);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getRelevantForList = async function () {
	try {
		const url = URL.relevantFor;
		const response = await careersApiInstance.get(url);
		return response;
	} catch (error) {
		return error.response;
	}
}


export const reviewJob = async function ({
	state,
	comment,
	id
}) {
	try {
		const url = URL.jobForReviewByID.replace(":jobID", id);
		const response = await careersApiInstance.put(
			url,
			{
				state,
				comment
			},
		);
		return response;
	} catch (error) {
		return error.response;
	}
};
