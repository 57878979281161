import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { routesPath } from "app/components/Router";
import styles from "./index.scss";
import { uploadData, getAllCohorts } from "apis";
import Button from "widgets/Button";
import Input from "widgets/Input";
import CohortSelector from "shared/CohortSelector";
import CategorySelector from "shared/CategorySelector";
import Alert from "widgets/Alert";
import uploadImage from "images/upgrad/upload.svg";

const UploadData = () => {
	const [cohortOptions, setCohortOptions] = useState([]);
	const [cohortId, setCohortId] = useState(null);
	const [displayType, setDisplayType] = useState(null);
	const [isCohoSeleLoading, setIsCohoSeleLoading] = useState(true);
	const [userData, setUserData] = useState(null);
	const [messageDetails, setMessageDetails] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const fileInputRef = useRef();

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			let response = await getAllCohorts({ dataUpload: true });
			if (isMounted)
				if (response) {
					const options = response.data.cohorts.map(cohort => ({
						label: `${cohort.externalId} - ${cohort.name}`,
						value: cohort.externalId
					}));

					setCohortOptions(options);
					setIsCohoSeleLoading(false);
				} else {
					// write error toast code for no response from API
				}
		};
		fetchData();
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		if (userData) callUploadData();
	}, [userData]);

	const handleCohortSelectorChange = ({ value }) => {
		const temp = cohortOptions.filter(cohort => cohort.label === value);
		setCohortId(temp[0].value);
		setDisplayType(null);
	};

	const handleCategorySelectorChange = category => {
		setDisplayType(category);
	};

	const callUploadData = async () => {
		setIsLoading(true);
		const response = await uploadData({
			course_id: cohortId,
			display_type: displayType,
			user_data: userData
		});
		if (response) {
			let msgDetails = [];
			let error = [];
			if (response.data.message) {
				msgDetails.push({
					message: "Invalid attribute",
					description: response.data.message,
					type: "error"
				});
			} else {
				error = response.data.filter(res => res.code !== 200);
				if (error.length) {
					msgDetails.push({
						message: "File uploaded successfully",
						description:
							"Following errors occurred while file upload",
						type: "warning"
					});
					let temp = error
						.map(err => err.email + ": " + err.message)
						.join("\n");
					msgDetails.push({
						message: "Invalid Email ID",
						description: temp,
						type: "error"
					});
				} else {
					msgDetails.push({
						message: "File uploaded successfully",
						description: " ",
						type: "success"
					});
				}
			}
			cleanupAfterUploadCall(msgDetails);
		} else {
			cleanupAfterUploadCall({
				message: "Sorry, some error has occured",
				description: "Please try again or check with admin",
				type: "error"
			});
			// write error toast code for no response from API
		}
	};

	const cleanupAfterUploadCall = msgDetails => {
		setIsLoading(false);
		fileInputRef.current.value = "";
		setUserData(null);
		setMessageDetails(msgDetails);
	};

	const onChangeHandler = ({ files }) => {
		const fileType = files[0].type;
		if (["text/csv", "application/octet-stream", "application/vnd.ms-excel"].includes(fileType)) {
			setUserData(files[0]);
		} else {
			setUserData(null);
			setMessageDetails([
				{
					type: "error",
					message: "Wrong file type",
					description: "Please select a CSV file only"
				}
			]);
		}
	};

	const handleUploadClick = () => {
		setMessageDetails([]);
		fileInputRef.current.click();
	};

	const handleAlertClose = message => {
		let temp = messageDetails.filter(msg => msg.message !== message);
		setMessageDetails(temp);
	};

	return (
		<>
			<div className={styles.secondaryHeaderOuter}>
				<div className={styles.secondaryHeaderInner}>
					<div className={styles.navList}>
						<NavLink
							exact
							to={routesPath.attributes.route}
							className={styles.navItem}
							activeClassName={styles.active}
						>
							Reference
						</NavLink>
						<NavLink
							exact
							to={routesPath.uploadData.route}
							className={styles.navItem}
							activeClassName={styles.active}
						>
							Upload Data
						</NavLink>
					</div>
				</div>
			</div>
			<div className={styles.bodyOuter}>
				<div className={styles.bodyInner}>
					<div className={styles.selectorRow}>
						<div className={styles.selector}>
							<CohortSelector
								options={cohortOptions}
								loading={isCohoSeleLoading}
								onChange={handleCohortSelectorChange}
							/>
						</div>
						{cohortId && (
							<div className={styles.selector}>
								<CategorySelector
									cohortId={cohortId}
									onChange={handleCategorySelectorChange}
									dataUpload={true}
								/>
							</div>
						)}
					</div>
					{cohortId && displayType && (
						<>
							<div className={styles.content}>
								<div>
									<img src={uploadImage} />
								</div>
								<div className={styles.contentDiv}>
									<p className={styles.contentHeader}>
										Select a file to upload
									</p>
									<p className={styles.contentDescripton}>
										Support for single csv upload
									</p>
								</div>
								<Input
									ref={fileInputRef}
									type="file"
									className={styles.uploadInput}
									onChange={onChangeHandler}
									accept="text/csv"
								/>
								<Button
									className={styles.uploadButton}
									onClick={handleUploadClick}
									isLoading={isLoading}
								>
									UPLOAD
								</Button>
								{messageDetails.length !== 0 &&
									messageDetails.map(msg => {
										return (
											<div
												key={Math.floor(
													Math.random() * 100
												)}
												className={styles.messageField}
											>
												<Alert
													type={msg.type}
													message={msg.message}
													description={
														msg.description
													}
													showIcon={true}
													closable={true}
													onClose={() =>
														handleAlertClose(
															msg.message
														)
													}
												/>
											</div>
										);
									})}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default UploadData;
