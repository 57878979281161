import React from "react";
import PropTypes from "prop-types";
import { Alert as AAlert } from "antd";

const Alert = props => {
	return <AAlert {...props} />;
};

Alert.propTypes = {
	message: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node
	]).isRequired,
	type: PropTypes.string.isRequired,
	description: PropTypes.string,
	showIcon: PropTypes.bool,
	closable: PropTypes.bool
};
export default Alert;
