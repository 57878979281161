import actionTypes from "actionTypes";
const initialState = {
	email: "",
	authToken: "",
	name: "",
	roles: [],
	isAdmin: false,
	isLoggedIn: false,
	id: "",
	sessionId: Math.floor(100000 + Math.random() * 900000)
};

export default function user(state, action) {
	switch (action.type) {
		case actionTypes.LOGIN_USER:
			return {
				...state,
				...action.payload,
				isLoggedIn: true
			};
		case actionTypes.LOGOUT_USER:
			return initialState;
	}
	return state ? state : initialState;
}
