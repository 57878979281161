import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import logo from "images/upgrad/logo.svg";
import styles from "./index.scss";
import { routesPath } from "app/components/Router";

const PrimaryLogo = function ({ className }) {
	return (
		<NavLink
			to={routesPath.attributes.route}
			className={cx(styles.root, className)}
		>
			<img className={styles.logo} src={logo} alt="Home" />
			<span className={styles.text}>Ava</span>
		</NavLink>
	);
};

PrimaryLogo.propTypes = {
	className: PropTypes.string,
};

export default PrimaryLogo;
