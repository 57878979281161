const CKEDITOR_CONFIG = {
	toolbarGroups: [
		{ name: "styles", groups: ["styles"] },
		{ name: "colors", groups: ["colors"] },
		{
			name: "basicStyles",
			groups: ["basicstyles", "cleanup"],
		},
		"/",
		{
			name: "paragraph",
			groups: ["align", "list"],
		},
		{ name: "links", groups: ["links"] },
		{ name: "insert", groups: ["insert"] },
		{ name: "tools", groups: ["tools"] },
		{ name: "others", groups: ["others"] },
		{
			name: "clipBoard",
			groups: ["undo"],
		},
	],
	removeButtons:
		"CreateDiv,Anchor,Image,Flash,PageBreak,Iframe,ShowBlocks,Styles",
	extraPlugins: [
		"copyformatting",
		"font",
		"tableresize",
		"colorbutton",
		"font",
		"justify",
	],
	removePlugins: "elementspath",
	fillEmptyBlocks: false,
	tabSpaces: 4,
};

export { CKEDITOR_CONFIG };
