import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table as CTable } from "antd";
import styles from "./index.scss";

export default class Table extends Component {
	static propTypes = {
		dataSource: PropTypes.array,
		columns: PropTypes.array,
		headerNode: PropTypes.node
	};

	static defaultProps = {
		dataSource: []
	};
	

	render() {
		return(
			<>
				{
					this.props.headerNode &&
					<div className={styles.header}>{this.props.headerNode}</div>
				}
				<CTable {...this.props} />
			</>
		)
	}
}
