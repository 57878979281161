import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import cx from "classnames";
import {
	getEventById,
	getEventRegistrations,
	extractRegistrations
} from "apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronRight
} from "@fortawesome/free-solid-svg-icons";

import Button from "widgets/Button";
import Table from "widgets/Table";
import SpinnerWithText from "shared/SpinnerWithText";
import styles from "./index.scss";
import parentStyles from "../index.scss";

const DATE_TOOLTIP_FORMAT = "dddd, MMMM Do YYYY, h:mm a";
const columns = [
	{
		title: "Email",
		dataIndex: "email",
		className: cx(styles["w-460"], styles.column),
	},
	{
		title: "Cohort",
		dataIndex: "cohort",
		className: cx(styles["w-656"], styles.column),
	}
];

const EventRegistration = ({ match }) => {
	const [eventDetails, setEventDetails] = useState({});
	const [dataSource, setDataSource] = useState([]);
	const [tableProps, setTableProps] = useState({
		pagination: { total: 0, current: 1, pageSize: 10 },
		loading: true
	});
	const [extractButtonProps, setExtractButtonProps] = useState({
		isDisabled: false,
		isLoading: false
	});

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		let isMounted = true;
		const fetchEventDetails = async () => {
			let response = await getEventById(match.params.eventId);
			if (isMounted)
				if (response.status === 200) {
					const {
						type,
						title,
						category,
						date
					} = response.data;
					if (isMounted)
						setEventDetails({
							type,
							title,
							category,
							startDate: moment(date.start).format(DATE_TOOLTIP_FORMAT),
							endDate: moment(date.end).format(DATE_TOOLTIP_FORMAT)
						});
				} else {
					// write error toast code for no response from API
				}
		};

		const fetchAll = async () => {
			await fetchEventDetails();
			await fetchRegistrations(1);
			if (isMounted) {
				setIsLoading(false);
			}
		};

		fetchAll();
		return () => {
			isMounted = false;
		};
	}, [fetchRegistrations]);

	const fetchRegistrations = async (pageNo) => {
		const paginationData = {
			pageNo,
			pageSize: tableProps.pagination.pageSize
		};
		let response = await getEventRegistrations(paginationData, match.params.eventId);
		if (response.status === 200) {
			let temp = response.data.learnersList.map((learner,index) => {
				let cohortString = ""
				const { cohorts } = learner;
				if (!cohorts.length) {
					cohortString = "-"
				} else if (cohorts.length === 1) {
					cohortString = `${cohorts[0].cohortId} - ${cohorts[0].cohortName}`
				} else {
					let cohortId = cohorts.map(cohort => cohort.cohortId);
					cohortString = cohortId.join(", ");
				}
				return {
					key: index,
					email: learner.email,
					cohort: cohortString
				}
			});
			setDataSource(temp);
			let pagination = { ...tableProps.pagination };
			pagination.total = parseInt(response.headers.count);
			pagination.current = pageNo;
			setTableProps({
				pagination,
				loading: false,
			});
		} else {
			// write error toast code for no response from API
		}
	};

	const handleTableChange = pagination => {
		const pager = { ...tableProps.pagination };
		pager.current = pagination.current;
		setTableProps({
			pagination: pager,
			loading: true
		});
		fetchRegistrations(pagination.current);
	};


	const handleExtractClick = async () => {
		setExtractButtonProps({
			isDisabled: true,
			isLoading: true
		})
		let response = await extractRegistrations(match.params.eventId);
		if (response.status === 200) {
			let blob = await new Blob([response.data], {
				type: "text/csv"
			});
			let objectUrl = window.URL.createObjectURL(blob);
			let link = document.createElement("a");
			document.getElementById("export").appendChild(link);
			link.href = objectUrl;
			link.download = "registered_user.csv";
			link.click();
			document.getElementById("export").removeChild(link);
		} else {
			// write error toast code for no response from API
		}
		setExtractButtonProps({
			isDisabled: false,
			isLoading: false
		})
	};

	const getEventType = () => {
		return eventDetails.type.split("_").join(" ").toLowerCase()
	}

	return (
		<>
			<div className={parentStyles.secondaryHeaderOuter}>
				<div className={parentStyles.secondaryHeaderInner}>
					<div className={parentStyles.headerLeft}>
						<span className={parentStyles.title}>Webinars&emsp;</span>
						{!isLoading && (
							<>
								<FontAwesomeIcon icon={faChevronRight} />
								<span className={parentStyles.breadCrumb}>
									<span className={parentStyles.eventType}>
										&emsp;{getEventType()}&emsp;
									</span>
									<FontAwesomeIcon
										icon={faChevronRight}
										size="xs"
									/>
									<span className={parentStyles.eventTitle}>
										&emsp;{eventDetails.title}
									</span>
								</span>
							</>
						)}
					</div>
					<div className={parentStyles.headerRight}>
						<Button
							onClick={handleExtractClick}
							isLoading={extractButtonProps.isLoading}
							disabled={extractButtonProps.isDisabled || !dataSource.length}
						>
							Export data
						</Button>
						<div id="export" />
					</div>
				</div>
			</div>
			<div className={parentStyles.bodyOuter}>
				<div className={parentStyles.bodyInner}>
					{isLoading ? (
						<SpinnerWithText text="Fetching list of registrations..." />
					) : (
						<div className={styles.bodyContainer}>
							<div className={styles.top}>
								<div className={styles.pageName}>
									Learner Registrations
								</div>
								<div className={styles.eventDescCardRow}>
									<div className={styles.eventInfoCard}>
										<div className={styles.name}>
											Start date: {eventDetails.startDate}
										</div>
										<div className={styles.name}>
											End date: {eventDetails.endDate}
										</div>
										<div className={styles.details}>
											<div>Category: {eventDetails.category}</div>
										</div>
									</div>
									<div className={styles.registrationsCountCard}>
										<div className={styles.title}>
											No. of Registrations
										</div>
										<div className={styles.number}>
											{tableProps.pagination.total}
										</div>
									</div>
								</div>
							</div>
							<hr />
							<div className={styles.tableWrapper}>
								<Table
									dataSource={dataSource}
									columns={columns}
									pagination={tableProps.pagination}
									loading={tableProps.loading}
									onChange={handleTableChange}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

EventRegistration.propTypes = {
	match: PropTypes.object
};

export default EventRegistration;
