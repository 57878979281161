import { connect } from "react-redux";
import { checkAccess } from 'utils';
import PropTypes from 'prop-types';

const RBAC = ({ allowedRoles, children, userInfo }) => {
	let access = checkAccess(userInfo.roles, allowedRoles);
	return access && children;
};

RBAC.propTypes = {
	userInfo: PropTypes.object,
	allowedRoles: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

function mapStateToProps(state) {
	return {
		userInfo: state.userInfo
	};
}

export default connect(
	mapStateToProps,
	null
)(RBAC);
