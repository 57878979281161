import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from 'actions/user';
import cx from 'classnames';
import styles from './MenuPreview.scss';
import { Link } from 'react-router-dom';
import { routesPath } from "app/components/Router";

class MenuPreview extends Component {
	static propTypes = {
		onClose: PropTypes.func,
		userActions: PropTypes.object.isRequired,
		userInfo: PropTypes.object.isRequired
	};

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
	}

	handleClickOutside = event => {
		if (event.target !== event.currentTarget) {
			this.props.onClose();
		}
	};

	render() {
		return (
			<div tabIndex='2' className={styles.profileMenuBox}>
				<div className={styles.content}>
					<div className={styles.menu}>
						<div className={styles.itemWrapper}>
							<Link to={routesPath.logout.route}>
								<span className={cx(styles.item, styles.danger)}>
									Logout
								</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		userInfo: state.userInfo
	};
}

function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(userActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MenuPreview);
