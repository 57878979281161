import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as event_actions from "actions/event";
import cx from "classnames";
import CKEditor from "ckeditor4-react";
import styles from "../../index.scss";
import { CKEDITOR_CONFIG } from "./constants";

const EventInfo = ({ event, eventActions }) => {
	const label1 = styles.labelOnlyAdditionalProps;
	const label2 = cx(label1, styles.labelBasicProps, styles.labelMB);
	const { eventInfo } = event;

	const handleEditorChange = (ck) => {
		eventActions.setEventData({
			description: {
				value: ck.editor.getData(),
				charCount: ck.editor.document.getBody().getText().length ,
				hasError: false
			}
		}, "EventInfo");
	};

	return (
		<div className={styles.eventInfo}>
			<label className={label2}>Webinar Description</label>
			<div>
				<CKEditor
					data={eventInfo.description.value || ""}
					config={CKEDITOR_CONFIG}
					onChange={handleEditorChange}
					onBeforeLoad={(CKEDITOR) =>
						(CKEDITOR.disableAutoInline = true)
					}
				/>
			</div>
			<label
				className={cx(styles.charCount, {
					[styles.hasError]: eventInfo.description.hasError,
				})}
			>
				Character count: { eventInfo.description.charCount || 0 }/300 ( Minimum )
			</label>
		</div>
	);
};

EventInfo.propTypes = {
	event: PropTypes.object.isRequired,
	eventActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		event: state.event,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		eventActions: bindActionCreators(event_actions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventInfo);

