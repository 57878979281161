import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Menu } from "antd";

const CustomDropdown = ({ ...restProps }) => {
	const handleMenuClick = (e) => {
		restProps.handleMenuClick(e.key);
	};

	const menu = (
		<Menu onClick={handleMenuClick} className={restProps.menuListClass}>
			{restProps.menuItems.map((menu) => {
				return (
					<Menu.Item
						key={menu.key}
						className={restProps.submenuItemClass}
					>
						<img
							src={menu.icon}
							className={restProps.downloadReportIconClass}
						/>
						<span className={restProps.subMenuItemText}>
							{menu.text}
						</span>
					</Menu.Item>
				);
			})}
		</Menu>
	);

	return (
		<Dropdown
			overlay={menu}
			placement="bottomRight"
			disabled={restProps.disabledButton ? true : false}
		>
			{restProps.children}
		</Dropdown>
	);
};
CustomDropdown.propTypes = {
	downloadIcon: PropTypes.any,
	handleMenuClick: PropTypes.func,
	subMenuItemName: PropTypes.string,
	subMenuItemText: PropTypes.string,
	disabledButton: PropTypes.bool,
	menuListClass: PropTypes.string,
	submenuItemClass: PropTypes.string,
	downloadReportIconClass: PropTypes.string,
};
export default CustomDropdown;
