import moment from "moment";
export const validateLiveSessionData = (eventData, saveType) => {
	let { basicInfo, eventInfo, speakerInfo, resourceInfo } = eventData;
	if (saveType === "PUBLISHED") {
		let hasError = false;
		let errorObj = {};

		errorObj = checkBasicInfoForPublish(basicInfo);
		eventData.basicInfo = errorObj.basicInfo;
		hasError = hasError || errorObj.hasError;

		errorObj = checkEventInfoForPublish(eventInfo);
		eventData.eventInfo = errorObj.eventInfo;
		hasError = hasError || errorObj.hasError;

		errorObj = checkSpeakerInfoForPublish(speakerInfo);
		eventData.speakerInfo = errorObj.speakerInfo;
		hasError = hasError || errorObj.hasError;

		errorObj = checkResourceInfoForPublish(resourceInfo);
		eventData.resourceInfo = errorObj.resourceInfo;
		hasError = hasError || errorObj.hasError;

		return { eventData, hasError }
	} else {
		let errorObjBasicInfoDraft = checkBasicInfoForDraft(basicInfo);
		eventData.basicInfo = errorObjBasicInfoDraft.basicInfo;
		return { eventData, hasError: errorObjBasicInfoDraft.hasError }
	}
}

const checkBasicInfoForDraft = (basicInfo) => {
	let MANDATORY_FIELDS = ["title", "category"];
	let hasError = false;
	for (let key in basicInfo) {
		if (MANDATORY_FIELDS.includes(key) && !basicInfo[key]["value"]){
			basicInfo[key]["hasError"] = true;
			hasError = true;
		}
	}
	return { basicInfo, hasError }
}

const checkBasicInfoForPublish = (basicInfo) => {
	let MANDATORY_FIELDS = [
		"bannerImage",
		"thumbnailImage",
		"title",
		"startDate",
		"endDate",
		"category",
		"joinURL"
	];
	let hasError = false;
	for (let key in basicInfo) {
		if (MANDATORY_FIELDS.includes(key) && !basicInfo[key]["value"]){
			basicInfo[key]["hasError"] = true;
			hasError = true;
		}
	}
	if(moment(basicInfo.endDate.value).isBefore()) {
		basicInfo.startDate.hasError = true;
		basicInfo.endDate.hasError = true;
		hasError = true;
	}
	return { basicInfo, hasError }
}

const checkEventInfoForPublish = (eventInfo) => {
	let MANDATORY_FIELDS = [
		"description"
	];
	let hasError = false;
	for (let key in eventInfo) {
		if (MANDATORY_FIELDS.includes(key) && !eventInfo[key]["value"]){
			eventInfo[key]["hasError"] = true;
			hasError = true;
		}
	}
	return { eventInfo, hasError }
}

const checkSpeakerInfoForPublish = (speakerInfo) => {
	let hasError = false;
	for (let [index, speaker] of speakerInfo.entries()) {
		let keysWithValue = [];
		let keysWithoutValue = [];

		for (let key in speaker) {
			if (speaker[key]["value"]) {
				keysWithValue.push(key);
			} else {
				keysWithoutValue.push(key);
			}
		}

		if(keysWithoutValue.length && (index === 0 || keysWithValue.length)) {
			hasError = true;
			keysWithoutValue.forEach( key => {
				speaker[key]["hasError"] = true;
			})
		} else if (keysWithoutValue.length && !keysWithValue.length) {
			keysWithoutValue.forEach( key => {
				speaker[key]["hasError"] = false;
			})
		}
		speakerInfo[index] = speaker;
	}
	return { speakerInfo, hasError }
}


const checkResourceInfoForPublish = (resourceInfo) => {
	let hasError = false;
	for (let [index, resource] of resourceInfo.entries()) {
		let keysWithValue = [];
		let keysWithoutValue = [];

		for (let key in resource) {
			if (resource[key]["value"]) {
				keysWithValue.push(key);
			} else {
				keysWithoutValue.push(key);
			}
		}

		if(keysWithoutValue.length && keysWithValue.length) {
			hasError = true;
			keysWithoutValue.forEach( key => {
				resource[key]["hasError"] = true;
			})
		} else if (keysWithoutValue.length && !keysWithValue.length) {
			keysWithoutValue.forEach( key => {
				resource[key]["hasError"] = false;
			})
		}
		resourceInfo[index] = resource;
	}
	return { resourceInfo, hasError }
}


