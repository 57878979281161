import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import CircularSpinner from "shared/CircularSpinner";
import styles from "./index.scss";

const SpinnerWithText = (props) => {
	const { isInline, className } = props;
	const _className = cx(className, styles.spinnerContainer, {
		[styles.spinnerInlineContainer]: isInline,
	});
	return (
		<div className={_className}>
			<div className={isInline ? styles.spinnerInline : styles.spinner}>
				<CircularSpinner />
			</div>
			<span>{props.text}</span>
		</div>
	);
};

SpinnerWithText.propTypes = {
	text: PropTypes.string,
	isInline: PropTypes.bool,
};

SpinnerWithText.defaultProps = {
	text: "Fetching...",
};

export default SpinnerWithText;
