import React from "react";
import PropTypes from "prop-types";
import styles from "./index.scss";

const SecondaryHeader = props => {
	const { active = true } = props;

	return (
		active && <div className={styles.root}>{this.props.children}</div>
	);
};

SecondaryHeader.propTypes = {
	active: PropTypes.bool,
	children: PropTypes.element
};
export default SecondaryHeader;
