/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import FileViewer from "react-file-viewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	getAllCandidates,
	getCandidateResumeLink,
	getAllDomains,
	extractCandidateApplications,
} from "apis";
import { SearchIcon } from "icons";
import styles from "./index.scss";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import LocationSelect from "shared/LocationSelect";
import Table from "widgets/Table";
import Button from "widgets/Button";
import Input from "widgets/Input";
import CustomInput from "widgets/CustomInput";
import Tooltip from "widgets/Tooltip";
import Modal from "widgets/Modal";
import Select from "widgets/Select";
import cx from "classnames";
import { PAGE_SIZE } from "modules/constants.js";
import {
	withToolTip,
	populateDefaultDataForFetchCandidatesAPI,
	populateFilterDataForCandidatesAPI,
	downloadUrl,
	capitalizeFirstLetter,
	capitalizeFirstLetterOfLocation,
	isEmpty,
} from "modules/helper.js";
import customDownload from "images/upgrad/customDownload.svg";
import quickView from "images/upgrad/quickView.svg";
import pdfDownload from "images/upgrad/pdfDownload.svg";
import warning from "images/upgrad/warning.svg";

const CandidateListing = () => {
	const [dataSource, setDataSource] = useState([]);
	const [isFilterVisible, setIsFilterVisible] = useState(false);
	const [tableProps, setTableProps] = useState({
		pagination: {
			total: 0,
			current: 0,
			pageSize: PAGE_SIZE,
			totalPages: 0,
		},
		loading: true,
	});
	const [showFilters, setShowFilters] = useState({
		location: false,
		totalExp: false,
		relevantExp: false,
		domain: false,
		salary: false,
		noticePeriod: false,
	});
	const [filtersData, setFiltersData] = useState({});
	const [keywordSearch, setKeywordSearch] = useState("");
	const [sorter, setSorter] = useState({});
	const [quickViewLink, setQuickViewLink] = useState();
	const [selectedName, setSelectedName] = useState();
	const [isQuickViewModalVisible, setIsQuickViewModalVisible] = useState(
		false
	);
	const [domainOptions, setDomainOptions] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [downloadLink, setDownloadLink] = useState();
	const [userId, setUserId] = useState({});
	const [isFilterApplied, setIsFilterApplied] = useState(false);
	const [appliedFilters, setAppliedFilters] = useState({});

	useEffect(() => {
		let tempDomainData = [];
		const fetchDomains = async () => {
			let response = await getAllDomains();
			if (response.status === 200) {
				const options = response.data.map((domain) => ({
					label: domain.name.trim(),
					value: domain.key,
				}));
				tempDomainData.push(options);
				setDomainOptions(options);
			} else {
				setDomainOptions([]);
			}
		};

		const fetchAll = async () => {
			await fetchDomains();
			await fetchCandidates({
				pageNo: tableProps.pagination.current,
				data: filtersData,
				tempDomainData: tempDomainData,
			});
		};
		fetchAll();
	}, [fetchCandidates]);

	/**
	 * Use to handle pagination and sorting
	 */
	const handleTableChange = (pagination, filters, tempSorter) => {
		const pager = { ...tableProps.pagination };
		//to apply scroll only on pagination not on sorter
		if (["name", "domain", "location"].includes(tempSorter.field)) {
			tempSorter["field"] = tempSorter.field + ".keyword";
		}
		setSorter(tempSorter);
		pager.current = pagination.current;
		setTableProps({
			pagination: pager,
			loading: true,
		});
		let data = {};
		data = populateDefaultDataForFetchCandidatesAPI(
			pagination.current - 1,
			tempSorter,
			PAGE_SIZE
		);
		data["query"] = keywordSearch.query ? keywordSearch.query : "";
		if (Object.keys(filtersData).length !== 0) {
			data["filters"] = populateFilterDataForCandidatesAPI(
				filtersData,
				domainOptions
			);
		}
		fetchCandidates({ pageNo: pagination.current, data: data });
	};

	/**
	 * Use to handle show filter event
	 */
	const handleShowFilters = () => {
		setIsFilterVisible(true);
	};

	/**
	 * Use to hide and clear filters on cancel
	 */
	const handleCancelFilters = () => {
		setFiltersData(appliedFilters);
		setIsFilterVisible(false);
	};

	/**
	 * Use to clear selected filters
	 */
	const handleFilterCollapsibleClick = (name) => {
		setShowFilters({ [name]: !showFilters[name] });
	};

	/**
	 * Use to handle filter change value
	 */
	const handleFilterChange = ({ name, value }) => {
		setFiltersData({ ...filtersData, [name]: value });
	};

	/**
	 * Use to handle custom input change value
	 */
	const handleCustomInput = (e) => {
		let { value } = e.target;
		const reg = /^-?[0-9]*(\.[0-9]*)?$/;
		if (
			(!isNaN(value) && reg.test(value)) ||
			value === "" ||
			value === "-"
		) {
			setFiltersData({
				...filtersData,
				[e.target.name]: e.target.value.replace(/\+|-/gi, ""),
			});
		}
	};

	/*
	 * search keyword data onclick search icon
	 */
	const handleQuerySearch = () => {
		let data = {};
		setTableProps({
			...tableProps,
			loading: true,
		});
		data = populateDefaultDataForFetchCandidatesAPI(0, sorter, PAGE_SIZE);
		data["query"] = keywordSearch.query ? keywordSearch.query : "";
		if (Object.keys(filtersData).length !== 0) {
			data["filters"] = populateFilterDataForCandidatesAPI(
				filtersData,
				domainOptions
			);
		}
		fetchCandidates({ pageNo: 0, data: JSON.stringify(data) });
	};

	/*
	 * handle keyword search and return list on clear text field
	 */
	const handleKeywordSearch = ({ name, value }) => {
		if (value !== "" && value.length >= 1) {
			setKeywordSearch({ ...keywordSearch, [name]: value });
		} else {
			setKeywordSearch({ [name]: "" });
			let data = {};
			setTableProps({
				...tableProps,
				loading: true,
			});
			data = populateDefaultDataForFetchCandidatesAPI(
				0,
				sorter,
				PAGE_SIZE
			);
			if (Object.keys(filtersData).length !== 0) {
				(data["query"] = keywordSearch.query
					? keywordSearch.query
					: ""),
					(data["filters"] = populateFilterDataForCandidatesAPI(
						filtersData,
						domainOptions
					));
			}
			fetchCandidates({ pageNo: 0, data: JSON.stringify(data) });
		}
	};

	/*
	 * filter data on press enter inside keyword search
	 */
	const handleKeywordSearchOnEnter = (e) => {
		if (e.keyCode === 13) {
			let data = {};
			setTableProps({
				...tableProps,
				loading: true,
			});
			data = populateDefaultDataForFetchCandidatesAPI(
				0,
				sorter,
				PAGE_SIZE
			);
			data["query"] = keywordSearch.query ? keywordSearch.query : "";
			if (Object.keys(filtersData).length !== 0) {
				data["filters"] = populateFilterDataForCandidatesAPI(
					filtersData,
					domainOptions
				);
			}
			fetchCandidates({ pageNo: 0, data: JSON.stringify(data) });
		}
	};

	/**
	 * To handle filters and fetch the match data from API
	 */
	const handleApplyFilters = () => {
		setIsFilterApplied(true);
		let objectValues = Object.values(filtersData);
		let checkIsEmpty = false;
		objectValues.map((item) => {
			checkIsEmpty = isEmpty(item);
			if (checkIsEmpty) {
				return true;
			}
			return false;
		});
		if (Object.keys(filtersData).length !== 0 && !checkIsEmpty) {
			setAppliedFilters(filtersData);
			setIsFilterVisible(false);
			setTableProps({
				...tableProps,
				loading: true,
			});

			let data = populateDefaultDataForFetchCandidatesAPI(
				0,
				sorter,
				PAGE_SIZE
			);
			(data["query"] = keywordSearch.query ? keywordSearch.query : ""),
				(data["filters"] = populateFilterDataForCandidatesAPI(
					filtersData,
					domainOptions
				));
			fetchCandidates({
				pageNo: 0,
				data: JSON.stringify(data),
			});
		}
	};
	/**
	 * Use to reset selected filters
	 */
	const handleSelectedFilterReset = (name) => {
		// eslint-disable-next-line no-undef
		let tempFilterData = _.cloneDeep(filtersData);
		for (let key in filtersData) {
			if (filtersData.hasOwnProperty(key) && filtersData[key].length) {
				if (["location", "domain", "noticePeriod"].includes(name)) {
					delete tempFilterData[name];
				} else if (name === "totalExp") {
					delete tempFilterData.minTotalExperience,
						delete tempFilterData.maxTotalExperience;
				} else if (name === "relevantExp") {
					delete tempFilterData.minRelevantExperience,
						delete tempFilterData.maxRelevantExperience;
				} else if (name === "salary") {
					delete tempFilterData.minSalary,
						delete tempFilterData.maxSalary;
				} else {
					tempFilterData;
				}
			}
		}
		setFiltersData({ ...tempFilterData });
		setAppliedFilters({ ...tempFilterData });
		if (
			Object.keys(filtersData).length !== 0 ||
			Object.keys(filtersData).length === 0
		) {
			setTableProps({
				...tableProps,
				loading: true,
			});
			let data = populateDefaultDataForFetchCandidatesAPI(
				0,
				sorter,
				PAGE_SIZE
			);
			(data["query"] = keywordSearch.query ? keywordSearch.query : ""),
				(data["filters"] = populateFilterDataForCandidatesAPI(
					tempFilterData,
					domainOptions
				));
			fetchCandidates({
				pageNo: 0,
				data: JSON.stringify(data),
			});
		}
	};

	/**
	 * Use to clear selected filters
	 */
	const handleClearFilters = () => {
		setFiltersData({});
		setAppliedFilters({});
		setIsFilterVisible(false);
		setIsFilterApplied(false);
		let data = populateDefaultDataForFetchCandidatesAPI(
			0,
			sorter,
			PAGE_SIZE
		);
		(data["query"] = keywordSearch.query ? keywordSearch.query : ""),
			(data["filters"] = {});
		setTableProps({
			...tableProps,
			loading: true,
		});
		fetchCandidates({
			pageNo: 0,
			data: JSON.stringify(data),
		});
	};
	/**
	 * To fetch candidates
	 * @param : current page no , payload object
	 */
	const fetchCandidates = async ({ pageNo, data, tempDomainData = [] }) => {
		try {
			if (Object.keys(data).length === 0) {
				let tempData = populateDefaultDataForFetchCandidatesAPI(
					pageNo,
					sorter,
					PAGE_SIZE
				);
				data = JSON.stringify(tempData);
			}
			let response = await getAllCandidates(data);
			if (response.status == 200) {
				let candidates = response.data.content;
				candidates = candidates.map((elem) => {
					tempDomainData.length === 0
						? tempDomainData.push(domainOptions)
						: tempDomainData;
					tempDomainData.map((item) => {
						item.map((domainOption) => {
							if (domainOption.value === elem.domain) {
								elem.domain = domainOption.label;
							}
						});
					});
					const uniqueKey = {
						key: elem.id,
					};
					return { ...uniqueKey, ...elem };
				});
				setDataSource(candidates);
				let pagination = { ...tableProps.pagination };
				pagination.total = parseInt(response.data.totalElements);
				pagination.current = pageNo;
				pagination.totalPages = response.data.totalPages;

				setTableProps({
					pagination,
					loading: false,
				});
			} else {
				setDataSource([]);
				setTableProps({ loading: false });
			}
		} catch (e) {
			setTableProps({ loading: false });
		}
	};

	/**
	 * To handle quick view
	 */
	const handleQuickView = async (name, url, responseData) => {
		setSelectedName(name);
		setQuickViewLink(url);
		setDownloadLink(responseData);
	};

	/**
	 * To handle cancel event of quick view
	 */
	const handleCancelQuickView = () => {
		setIsQuickViewModalVisible(false);
		setSelectedName("");
		setQuickViewLink("");
	};

	/**
	 * To handle on error method of file viewer
	 */
	const handleFileViewerError = () => {
		//error
	};

	/*
	 * To fetch candidate resumeUrl using userId and s3ResumeLink
	 */
	const fetchResumeUrl = async (
		action,
		candidateId,
		userId,
		s3ResumeLink,
		name
	) => {
		try {
			let data = { id: candidateId, name: name };
			setUserId(data);
			if (action === "quickView") {
				setIsQuickViewModalVisible(true);
			}
			let response = await getCandidateResumeLink(userId, s3ResumeLink);
			if (response.status === 200) {
				let responseData = response.data;
				if (action === "download") {
					downloadUrl(name, responseData);
				} else if (action === "quickView") {
					let blob = await new Blob([responseData], {
						type: "application/octet-stream",
					});
					let objectUrl = window.URL.createObjectURL(blob);
					handleQuickView(name, objectUrl, responseData);
				}
			}
		} catch (e) {
			//error
		}
	};

	/**
	 * To handle multiple row selection
	 */
	const handleRowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys) => {
			setSelectedRowKeys(selectedRowKeys);
		},
		hideDefaultSelections: true,
		// selections: [
		// 	{
		// 		key: "all-data",
		// 		text: "Select All Data",
		// 		onSelect: () => {
		// 			setSelectedRowKeys(
		// 				dataSource.map((application) => application.key)
		// 			);
		// 		},
		// 	},
		// 	{
		// 		key: "no-data",
		// 		text: "DeSelect All Data",
		// 		onSelect: () => {
		// 			setSelectedRowKeys([]);
		// 		},
		// 	},
		// ],
	};

	/**
	 * To handle candidate email
	 */
	const handleCandidateEmail = () => {
		if (Array.isArray(selectedRowKeys)) {
			if (selectedRowKeys.length > 0) {
				let tempSelectedKeys = [];
				dataSource.map((application) => {
					if (selectedRowKeys.includes(application.id)) {
						tempSelectedKeys.push(application.emailId);
					}
				});
				window.open(`mailto:?bcc=${tempSelectedKeys}`);
			}
		}
	};

	/**
	 * To download selected candidates
	 */
	const handleDownloadSelectedCandidate = async () => {
		if (Array.isArray(selectedRowKeys)) {
			if (selectedRowKeys.length > 0) {
				setTableProps({
					...tableProps,
					loading: true,
				});
				let data = {
					candidateIds: selectedRowKeys,
				};
				let response = await extractCandidateApplications(data);
				if (response.status === 200) {
					let blob = await new Blob([response.data]);
					let objectUrl = window.URL.createObjectURL(blob);
					let link = document.createElement("a");
					link.href = objectUrl;
					link.download = "resume.zip";
					link.click();
				} else {
					// write error toast code for no response from API
				}
				setTableProps({
					...tableProps,
					loading: false,
				});
				setSelectedRowKeys([]);
			}
		}
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			sorter: true,
			className: cx(styles["w-160"]),
			// eslint-disable-next-line
			render: (name) => (
				<span>{withToolTip(capitalizeFirstLetter(name))}</span>
			),
		},
		{
			title: "Domain",
			dataIndex: "domain",
			sorter: true,
			// eslint-disable-next-line
			render: (domain) => (
				<span>{domain === null ? "No Data" : domain}</span>
			),
		},
		{
			title: "Location",
			dataIndex: "location",
			sorter: true,
			// eslint-disable-next-line
			render: (location) => (
				<Tooltip
					placement="top"
					title={capitalizeFirstLetterOfLocation(location)}
				>
					{location && location !== null
						? location.split(",").length > 2
							? capitalizeFirstLetterOfLocation(location)
									.split(",")
									.slice(0, 2)
									.join(", ") + "..."
							: capitalizeFirstLetterOfLocation(location)
						: "No Data"}
				</Tooltip>
			),
		},
		{
			title: "Total Exp.",
			dataIndex: "totalExp",
			sorter: true,
			// eslint-disable-next-line
			render: (totalExp) => (
				<span>
					{totalExp === null ? "No Data" : totalExp + " years"}
				</span>
			),
		},
		{
			title: "Relv. Exp.",
			dataIndex: "relevantExp",
			sorter: true,
			// eslint-disable-next-line
			render: (relevantExp) => (
				<span>
					{relevantExp === null ? "No Data" : relevantExp + " years"}
				</span>
			),
		},
		{
			title: "Salary",
			dataIndex: "salary",
			sorter: true,
			// eslint-disable-next-line
			render: (salary) => (
				<span>{salary === null ? "No Data" : salary + " LPA "}</span>
			),
		},
		{
			title: "Notice Period",
			dataIndex: "noticePeriod",
			sorter: true,
			// eslint-disable-next-line
			render: (noticePeriod) => (
				<span>
					{noticePeriod === null ? "No Data" : noticePeriod + " days"}
				</span>
			),
		},
		{
			title: "Mobile No",
			dataIndex: "contactNumber",
			// eslint-disable-next-line
			render: (contactNumber) => (
				<span>{contactNumber ? contactNumber : ""}</span>
			),
		},
		{
			title: "Action",
			fixed: "right",
			key: "action",
			// eslint-disable-next-line
			render: (record) => {
				return (
					<div className={styles.actionsWrapper}>
						<Tooltip
							placement="top"
							title={
								record.s3ResumeLink &&
								record.s3ResumeLink !== null
									? "Preview CV"
									: "CV not uploaded by student"
							}
						>
							<div className={styles.quickView}>
								<div
									onClick={() =>
										record.s3ResumeLink &&
										record.s3ResumeLink !== null
											? fetchResumeUrl(
													"quickView",
													record.id,
													record.userIdFromCareersDB,
													record.s3ResumeLink,
													record.name
											  )
											: ""
									}
								>
									<img
										src={quickView}
										className={cx(
											styles.quickviewIcon,
											styles.imageClass,
											record.s3ResumeLink === null
												? styles.customDisableIcon
												: ""
										)}
									/>
									{record.s3ResumeLink === null && (
										<img
											src={warning}
											className={cx(
												styles.quickviewIcon,
												styles.imageClass,
												styles.warning
											)}
										/>
									)}
								</div>
							</div>
						</Tooltip>
						<Tooltip
							placement="top"
							title={
								record.s3ResumeLink &&
								record.s3ResumeLink !== null
									? "Download CV"
									: "CV not uploaded by student"
							}
						>
							<div className={styles.quickView}>
								<div
									onClick={() =>
										record.s3ResumeLink &&
										record.s3ResumeLink !== null
											? fetchResumeUrl(
													"download",
													record.id,
													record.userIdFromCareersDB,
													record.s3ResumeLink,
													record.name
											  )
											: ""
									}
									className={
										record.s3ResumeLink === null
											? styles.customDisableIcon
											: ""
									}
								>
									<img
										src={customDownload}
										className={cx(
											styles.downloadIcon,
											styles.imageClass
										)}
									/>
								</div>
							</div>
						</Tooltip>
						<div>
							<Button
								className={styles.mailIcon}
								onClick={() =>
									window.open(`mailto:?bcc=${record.emailId}`)
								}
								disabled={!!selectedRowKeys.length}
							>
								MAIL
							</Button>
						</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			<div id={"top"} className={styles.secondaryHeaderOuter}>
				<div className={styles.secondaryHeaderInner}>
					<div className={styles.title}>
						Candidates &nbsp;({tableProps.pagination.total})
					</div>
					<div className={styles.searchBar}>
						<Input
							className={styles.search}
							placeholder="Keyword Search...."
							name="query"
							onChange={handleKeywordSearch}
							onKeyDown={(e) => handleKeywordSearchOnEnter(e)}
						></Input>
						<SearchIcon
							className={styles.searchIcon}
							onClick={handleQuerySearch}
						/>
					</div>
				</div>
			</div>
			<div className={styles.bodyOuter}>
				<div className={styles.bodyInner}>
					<div className={styles.filtersDiv}>
						<div className={styles.row}>
							<div className={styles.buttonDiv1}>
								<Button
									className={styles.filterButton}
									onClick={handleShowFilters}
								>
									<FontAwesomeIcon icon={faFilter} />
									<span className={styles.filtername}>
										Filters
									</span>
								</Button>
							</div>
							{isFilterVisible ? (
								<div className={styles.selectedFilters}></div>
							) : (
								""
							)}
							{isFilterVisible === false ? (
								<div className={styles.selectedFilters}>
									{filtersData.location &&
									filtersData.location.length &&
									isFilterApplied ? (
										<div
											className={
												styles.showSelectedFilters
											}
										>
											<div className={styles.labelStyle}>
												{" "}
												Location :{" "}
												{filtersData.location.join(
													", "
												)}{" "}
											</div>
											<div className={styles.spanStyle}>
												{" "}
												<span
													className={
														styles.closeFilter
													}
													onClick={() =>
														handleSelectedFilterReset(
															"location"
														)
													}
												>
													<FontAwesomeIcon
														icon={faTimes}
													/>
												</span>
											</div>
										</div>
									) : (
										""
									)}
									{(filtersData.minTotalExperience ||
										filtersData.maxTotalExperience) &&
									isFilterApplied ? (
										<div
											className={
												styles.showSelectedFilters
											}
										>
											<div className={styles.labelStyle}>
												{" "}
												Total Experience :{" "}
												{filtersData.minTotalExperience +
													(filtersData.maxTotalExperience
														? " To " +
														  filtersData.maxTotalExperience
														: "") +
													" Y"}{" "}
											</div>
											<div className={styles.spanStyle}>
												{" "}
												<span
													className={
														styles.closeFilter
													}
													onClick={() =>
														handleSelectedFilterReset(
															"totalExp"
														)
													}
												>
													<FontAwesomeIcon
														icon={faTimes}
													/>
												</span>
											</div>
										</div>
									) : (
										""
									)}
									{(filtersData.minRelevantExperience ||
										filtersData.maxRelevantExperience) &&
									isFilterApplied ? (
										<div
											className={
												styles.showSelectedFilters
											}
										>
											<div className={styles.labelStyle}>
												{" "}
												Relevant Experience :{" "}
												{filtersData.minRelevantExperience +
													(filtersData.maxRelevantExperience
														? " To " +
														  filtersData.maxRelevantExperience
														: "") +
													" Y"}{" "}
											</div>
											<div className={styles.spanStyle}>
												{" "}
												<span
													className={
														styles.closeFilter
													}
													onClick={() =>
														handleSelectedFilterReset(
															"relevantExp"
														)
													}
												>
													<FontAwesomeIcon
														icon={faTimes}
													/>
												</span>
											</div>
										</div>
									) : (
										""
									)}

									{filtersData.domain && isFilterApplied ? (
										<div
											className={
												styles.showSelectedFilters
											}
										>
											<div className={styles.labelStyle}>
												{" "}
												Domain : {
													filtersData.domain
												}{" "}
											</div>
											<div className={styles.spanStyle}>
												{" "}
												<span
													className={
														styles.closeFilter
													}
													onClick={() =>
														handleSelectedFilterReset(
															"domain"
														)
													}
												>
													<FontAwesomeIcon
														icon={faTimes}
													/>
												</span>
											</div>
										</div>
									) : (
										""
									)}

									{(filtersData.minSalary ||
										filtersData.maxSalary) &&
									isFilterApplied ? (
										<div
											className={
												styles.showSelectedFilters
											}
										>
											<div className={styles.labelStyle}>
												{" "}
												Salary :{" "}
												{filtersData.minSalary +
													(filtersData.maxSalary
														? " To " +
														  filtersData.maxSalary
														: "") +
													" LPA"}{" "}
											</div>
											<div className={styles.spanStyle}>
												<span
													className={
														styles.closeFilter
													}
													onClick={() =>
														handleSelectedFilterReset(
															"salary"
														)
													}
												>
													<FontAwesomeIcon
														icon={faTimes}
													/>
												</span>
											</div>
										</div>
									) : (
										""
									)}

									{filtersData.noticePeriod &&
									isFilterApplied ? (
										<div
											className={
												styles.showSelectedFilters
											}
										>
											<div className={styles.labelStyle}>
												{" "}
												Notice Period :{" "}
												{filtersData.noticePeriod +
													" Days"}{" "}
											</div>
											<div className={styles.spanStyle}>
												{" "}
												<span
													className={
														styles.closeFilter
													}
													onClick={() =>
														handleSelectedFilterReset(
															"noticePeriod"
														)
													}
												>
													<FontAwesomeIcon
														icon={faTimes}
													/>
												</span>
											</div>
										</div>
									) : (
										""
									)}
								</div>
							) : (
								""
							)}

							<div className={styles.clearFilters}>
								<Button
									className={
										Object.keys(filtersData).length !== 0
											? styles.clearFilterButtonActive
											: styles.clearFilterButtonInactive
									}
									onClick={handleClearFilters}
									disabled={
										Object.keys(appliedFilters).length ===
											0 && isEmpty(appliedFilters)
									}
								>
									Clear Filters
								</Button>
							</div>
						</div>
						{isFilterVisible ? (
							<div className={styles.container}>
								<div className={styles.innerBody}>
									<div className={styles.leftSide}>
										<div
											className={cx(
												styles.filters,
												showFilters.location
													? styles.filterSelected
													: styles.filterNotSelected
											)}
										>
											<div
												className={
													showFilters.location
														? styles.selectedNotch
														: styles.filterLabel
												}
												onClick={() =>
													handleFilterCollapsibleClick(
														"location"
													)
												}
											>
												<div
													className={
														styles.labelStyle
													}
												>
													Location
												</div>
											</div>
											<div
												className={styles.locationDiv}
												style={{
													display: showFilters.location
														? "block"
														: "none",
													left: 230,
													top: 30,
												}}
											>
												<LocationSelect
													name="location"
													mode="places"
													badgeItems={
														filtersData.location ||
														[]
													}
													onChange={
														handleFilterChange
													}
													badgeClass={
														styles.badgeCustom
													}
												/>
											</div>
										</div>
										<div
											className={cx(
												styles.filters,
												showFilters.totalExp
													? styles.filterSelected
													: styles.filterNotSelected
											)}
										>
											<div
												className={
													showFilters.totalExp
														? styles.selectedNotch
														: styles.filterLabel
												}
												onClick={() =>
													handleFilterCollapsibleClick(
														"totalExp"
													)
												}
											>
												<div
													className={
														styles.labelStyle
													}
												>
													Total Experience
												</div>
											</div>
											<div
												className={styles.locationDiv}
												style={{
													display: showFilters.totalExp
														? "block"
														: "none",
													left: 230,
													top: 30,
												}}
											>
												<span className={styles.label1}>
													Minimum Experience
												</span>
												<CustomInput
													className={
														styles.inputStyle
													}
													placeholder="00"
													name="minTotalExperience"
													value={
														filtersData.minTotalExperience ||
														""
													}
													maxLength={2}
													onChange={handleCustomInput}
													addonAfter="Years"
												/>
												<span className={styles.label1}>
													Maximum Experience
												</span>
												<CustomInput
													className={
														styles.inputStyle
													}
													placeholder="00"
													name="maxTotalExperience"
													value={
														filtersData.maxTotalExperience ||
														""
													}
													maxLength={2}
													onChange={handleCustomInput}
													addonAfter="Years"
												/>
											</div>
										</div>
										<div
											className={cx(
												styles.filters,
												showFilters.relevantExp
													? styles.filterSelected
													: styles.filterNotSelected
											)}
										>
											<div
												className={
													showFilters.relevantExp
														? styles.selectedNotch
														: styles.filterLabel
												}
												onClick={() =>
													handleFilterCollapsibleClick(
														"relevantExp"
													)
												}
											>
												<div
													className={
														styles.labelStyle
													}
												>
													Relevant Experience
												</div>
											</div>
											<div
												className={styles.locationDiv}
												style={{
													display: showFilters.relevantExp
														? "block"
														: "none",
													left: 230,
													top: 30,
												}}
											>
												<span className={styles.label1}>
													Minimum Experience
												</span>
												<CustomInput
													className={
														styles.inputStyle
													}
													placeholder="00"
													name="minRelevantExperience"
													value={
														filtersData.minRelevantExperience ||
														""
													}
													maxLength={2}
													onChange={handleCustomInput}
													addonAfter="Years"
												/>
												<span className={styles.label1}>
													Maximum Experience
												</span>
												<CustomInput
													className={
														styles.inputStyle
													}
													placeholder="00"
													name="maxRelevantExperience"
													value={
														filtersData.maxRelevantExperience ||
														""
													}
													maxLength={2}
													onChange={handleCustomInput}
													addonAfter="Years"
												/>
											</div>
										</div>

										<div
											className={cx(
												styles.filters,
												showFilters.domain
													? styles.filterSelected
													: styles.filterNotSelected
											)}
										>
											<div
												className={
													showFilters.domain
														? styles.selectedNotch
														: styles.filterLabel
												}
												onClick={() =>
													handleFilterCollapsibleClick(
														"domain"
													)
												}
											>
												<div
													className={
														styles.labelStyle
													}
												>
													Domain
												</div>
											</div>
											<div
												className={styles.locationDiv}
												style={{
													display: showFilters.domain
														? "block"
														: "none",
													left: 230,
													top: 30,
												}}
											>
												<Select
													allowClear={true}
													mode="default"
													name="domain"
													placeholder="Select Domain"
													options={
														domainOptions || {}
													}
													onChange={
														handleFilterChange
													}
												/>
											</div>
										</div>
										<div
											className={cx(
												styles.filters,
												showFilters.salary
													? styles.filterSelected
													: styles.filterNotSelected
											)}
										>
											<div
												className={
													showFilters.salary
														? styles.selectedNotch
														: styles.filterLabel
												}
												onClick={() =>
													handleFilterCollapsibleClick(
														"salary"
													)
												}
											>
												<div
													className={
														styles.labelStyle
													}
												>
													Salary
												</div>
											</div>
											<div
												className={styles.locationDiv}
												style={{
													display: showFilters.salary
														? "block"
														: "none",
													left: 230,
													top: 30,
												}}
											>
												<span
													className={
														styles.salaryInputStyle
													}
												>
													Minimum
												</span>
												<CustomInput
													style={{
														marginBottom: "20px",
													}}
													prefix="₹"
													placeholder="0"
													name="minSalary"
													value={
														filtersData.minSalary ||
														""
													}
													maxLength={2}
													onChange={handleCustomInput}
													addonAfter="Lakhs PA"
												/>
												<span
													className={
														styles.salaryInputStyle
													}
												>
													Maximum
												</span>
												<CustomInput
													style={{
														marginBottom: "20px",
													}}
													prefix="₹"
													placeholder="0"
													name="maxSalary"
													value={
														filtersData.maxSalary ||
														""
													}
													maxLength={2}
													onChange={handleCustomInput}
													addonAfter="Lakhs PA"
												/>
											</div>
										</div>
										<div
											className={cx(
												styles.filters,
												showFilters.noticePeriod
													? styles.filterSelected
													: styles.filterNotSelected
											)}
										>
											<div
												className={
													showFilters.noticePeriod
														? styles.selectedNotch
														: styles.filterLabel
												}
												onClick={() =>
													handleFilterCollapsibleClick(
														"noticePeriod"
													)
												}
											>
												<div
													className={
														styles.labelStyle
													}
												>
													Notice Period
												</div>
											</div>
											<div
												className={styles.locationDiv}
												style={{
													display: showFilters.noticePeriod
														? "block"
														: "none",
													left: 230,
													top: 30,
												}}
											>
												<span className={styles.label1}>
													Max Notice Period{" "}
												</span>
												<CustomInput
													className={
														styles.inputStyle
													}
													placeholder="0"
													name="noticePeriod"
													value={
														filtersData.noticePeriod ||
														""
													}
													maxLength={2}
													onChange={handleCustomInput}
													addonAfter="Days"
												/>
												{filtersData.noticePeriod ? (
													<div
														className={
															styles.noticeBadge
														}
													>
														{filtersData.noticePeriod
															? filtersData.noticePeriod +
															  " Days"
															: ""}
													</div>
												) : (
													""
												)}
											</div>
										</div>
									</div>
								</div>
								<div className={styles.header}>
									<div className={styles.row}>
										<Button
											className={styles.clearText}
											onClick={handleCancelFilters}
										>
											Cancel
										</Button>
										<Button
											onClick={handleApplyFilters}
											className={
												Object.keys(filtersData)
													.length !== 0
													? styles.applyText
													: styles.applyTextInactive
											}
											disabled={
												Object.keys(filtersData)
													.length === 0 &&
												isEmpty(appliedFilters)
											}
										>
											Apply
										</Button>
									</div>
								</div>
							</div>
						) : (
							""
						)}
						<hr className={styles.horizontalStyles}></hr>
						<div className={styles.bulkActionRow}>
							<p className={styles.bulkActions}>Bulk Actions:</p>
							<p
								className={styles.downloadAction}
								onClick={handleDownloadSelectedCandidate}
							>
								Download Selected Candidates
							</p>
							<p
								className={styles.mailAction}
								onClick={handleCandidateEmail}
							>
								Mail Selected Candidates
							</p>
						</div>
					</div>

					<Modal
						width="60%"
						title={
							selectedName && selectedName !== ""
								? capitalizeFirstLetter(selectedName) +
								  " CV Preview"
								: "CV Preview"
						}
						visible={isQuickViewModalVisible}
						footer={null}
						onCancel={handleCancelQuickView}
						okButtonProps={{ style: { display: "none" } }}
						key={userId.id}
					>
						<div className={styles.modalCards}>
							<Button
								className={styles.customHeaderDownload}
								onClick={() =>
									downloadUrl(selectedName, downloadLink)
								}
							>
								<img
									src={pdfDownload}
									className={cx(
										styles.downloadPdfIcon,
										styles.imageClass,
										"mr-8"
									)}
								/>{" "}
								<span> Download</span>
							</Button>
							{!quickViewLink ? (
								<div className={styles.loader}>Loading...</div>
							) : (
								<FileViewer
									fileType="pdf"
									filePath={quickViewLink}
									onError={handleFileViewerError}
									key={`${userId.id}-${userId.name}`}
								/>
							)}
						</div>
					</Modal>

					<div className={styles.tableContainer}>
						<Table
							rowSelection={handleRowSelection}
							className={styles.candidateTable}
							columns={columns}
							dataSource={dataSource}
							pagination={tableProps.pagination}
							loading={tableProps.loading}
							onChange={handleTableChange}
							scroll={{ x: "max-content" }}
						/>
						{tableProps.loading === false ? (
							<span className={styles.paginationStyle}>
								{"Page " +
									(tableProps.pagination.current === 0
										? "1"
										: tableProps.pagination.current) +
									" of " +
									tableProps.pagination.totalPages}
							</span>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default CandidateListing;
